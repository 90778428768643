#grid-frame {
	height: 100vh;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-flow: column nowrap;
}

header.app {
	flex: 0 0 auto;
	position: relative;
	z-index: 4;
}

#main-container {
	display: flex;
	//position: relative
	flex: 1;
	height: auto;
	overflow: hidden;
	flex-flow: row nowrap;

	@media #{$large-up} {
		flex-flow: column nowrap;
	}
}

aside.first-sidebar,
aside.second-sidebar {
	width: $off-canvas-width;
	position: absolute;
	top: 0;
	height: 100%;
	overflow: auto;

	@media #{$large-up} {
		top: initial;
		position: relative;
		height: auto;
	}

	menu {
		margin: 0;
		padding: 0;
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		@media #{$large-up} {
			width: 100%;
		}

		.logo-image {
			@media #{$large-up} {
				margin: 0 auto ($column-gutter * 2);
			}
		}
	}
}

aside.first-sidebar {
	flex: 0 0 auto;
	flex-basis: auto;

	@media #{$large-up} {
		width: 100%;
		background-image: linear-gradient(#8DB8E2, #185A9D);
	}
}

aside.second-sidebar {
	order: 1;
	right: 0;
	transition: opacity 0 $transition-ease $transition-speed;

	@media #{$large-up} {
		order: -1;
	}

	.first-sidebar-active & {
		opacity: 0;
		z-index: -1;

		@media #{$large-up} {
			z-index: 1;
			opacity: 1;
		}
	}
}

#content {
	display: flex;
	flex: 1 1 auto;
	flex-flow: row nowrap;
	overflow: hidden;
}

main {
	flex: 1;
	overflow: auto;
	position: relative;
	z-index: 2;
	opacity: 1;
	overflow-scrolling: touch;
	-webkit-overflow-scrolling: touch;
}

footer.main {
	flex: 0 0 auto;
	display: none;

	@media #{$large-up} {
		display: block;
	}
}

//offcanvas
#grid-frame {
	.overlay {
		content: ' ';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		background: rgba($neutral-lowest, 0.8);
		box-shadow: 0 0 10px black;
		z-index: -1000;
		opacity: 0;
		cursor: pointer;
		transform: translateX(0);
		transition: transform $transition-speed $transition-ease, opacity $transition-speed $transition-ease;

		@media #{$large-up} {
			z-index: (-1) !important;
			transition: none;
		}
	}

	&.first-sidebar-active .overlay {
		transform: translateX($off-canvas-width);
		z-index: 10;
		opacity: 1;

		@media #{$large-up} {
			opacity: 0;
			transform: translateX(0);
		}
	}

	&.second-sidebar-active .overlay {
		transform: translateX(-$off-canvas-width);
		z-index: 10;
		opacity: 1;

		@media #{$large-up} {
			opacity: 0;
			transform: translateX(0);
		}
	}
}

header.app,
main {
	transition: transform $transition-speed $transition-ease;

	.first-sidebar-active & {
		transform: translateX($off-canvas-width);
	}

	.second-sidebar-active & {
		transform: translateX(-$off-canvas-width);
	}

	.first-sidebar-active &,
	.second-sidebar-active & {
		@media #{$large-up} {
			transition: none;
			transform: none;
		}
	}
}
