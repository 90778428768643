$loading-spinner-size: rem-calc(50);

.panel {
	@include panel;

	&.with-shadow {
		@include panel-shadow;
	}

	&.green {
		@include panel-color($green-dark);
	}

	&.gray-light {
		@include panel-color($neutral-medium-low);
	}
}

.panel-head {
	background: none;
	border-bottom: none;
	color: $neutral-higher;
	line-height: 1.4;
	letter-spacing: 0.8px;
	font-weight: 500;
	padding: $column-gutter $column-gutter 0;
	font-size: rem-calc(15);
	// text-transform: uppercase;
	
	@media #{$medium-up} {
		font-size: rem-calc(16);
		padding: ($gutter * 3) ($gutter * 1.5) ($gutter * 1.5);

		.row {
			margin: 0;
		}

		.column {
			padding: 0;
		}
	}
}

.panel-body {
	padding: $column-gutter/2;

	@media #{$medium-up} {
		padding: 0;
	}
}

.widget-container {
	flex: 1 0 auto;
	display: flex;

	.widget {
		flex: 1 0 auto;
		margin-bottom: $column-gutter;
	}
}

.widget {
	background-color: $neutral-lowest;
	border-radius: $radius;
	box-shadow: 0 1px 3px rgba($black, 0.2);
	display: block;
	padding: 0;
	position: relative;
	// border-radius: $global-radius;
	// margin-bottom: $column-gutter;

	.waiting {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba($neutral-lowest, 0.85);
		z-index: 100;
		display: flex;
		align-items: center;
		justify-content: center;

		&:before {
			@include rounded($loading-spinner-size);
			animation: rotate 0.6s linear infinite;
			border-top-color: tint($primary-color, 30%);
			border: 4px solid tint($primary-color, 70%);
			content: '';
			display: block;

			.style_Z & {
				border: 4px solid tint($style-z, 70%);
				border-top-color: tint($style-z, 30%);
			}

			.style_A & {
				border: 4px solid tint($style-a, 70%);
				border-top-color: tint($style-a, 30%);
			}

			.style_Y & {
				border: 4px solid tint($style-y, 70%);
				border-top-color: tint($style-y, 30%);
			}
		}
	}
}

.widget-placeholder {
	flex: 1 0 auto;
	margin-bottom: $column-gutter;
	width: 100%;

	.widget.status-on-add + & {
		display: none;
	}

	.widget-status {
		background-color: $neutral-lowest;
		padding: rem-calc(30 0);

		@media #{$medium-landscape-up} {
			padding: rem-calc(50 0);
		}

		&.loading {
			padding: 0;
			height: 250px;
			display: flex;
			align-items: center;
			justify-content: center;

			&:before {
				display: block;
				content: '';
				$loading-spinner-size: rem-calc(50);
				@include rounded($loading-spinner-size);
				border: 4px solid tint($primary-color, 80%);
				border-top-color: tint($primary-color, 30%);
				animation: rotate 0.6s linear infinite;

				.style_Z & {
					border: 4px solid tint($style-z, 70%);
					border-top-color: tint($style-z, 30%);
				}

				.style_A & {
					border: 4px solid tint($style-a, 70%);
					border-top-color: tint($style-a, 30%);
				}

				.style_Y & {
					border: 4px solid tint($style-y, 70%);
					border-top-color: tint($style-y, 30%);
				}
			}
		}

		.status-message {
			text-align: center;

			.icon {
				font-size: rem-calc(75);
				color: neutral-low;
				margin-bottom: rem-calc(30);

				@media #{$medium-landscape-up} {
					font-size: rem-calc(100);
				}
			}

			.message {
				font-size: rem-calc(16);
				font-weight: 300;
				color: $neutral-medium-high;
				margin: 0;

				@media #{$medium-landscape-up} {
					font-size: rem-calc(24);
				}
			}
		}

		&.error {
			.status-message .icon {
				color: $red;
			}
		}

		&.locked {
			.status-message .icon {
				color: $neutral-high;
			}
		}
	}
}

.widget.status-on + div > div.widget-placeholder {
	display: none;
}

.filter-bar {
	background: none;
	display: flex;

	> div,
	ul.first {
		flex-grow: 1;
	}

	@media #{$small-up} {
		flex-wrap: nowrap;

		ul {
			flex-wrap: wrap;
		}

		.last {
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-end;
		}
	}

	ul.first,
	ul.last {
		@include no-bullet;
		margin: 0;
		position: relative;

		li {
			display: inline-block;

			&.separator {
				position: absolute;
				width: 1px;
				height: 100%;
				background: $neutral-lower;
			}

			input {
				border: 0;
				box-shadow: none;
				margin: rem-calc(0 0);
			}

			@media #{$small-up} {
				padding-bottom: $gutter * 1.5;
			}
		}
	}

	a {
		color: $primary-color;
		display: block;
		font-size: rem-calc(16);
		font-weight: 500;
		padding: rem-calc(10 15 0 15);
		position: relative;
		text-transform: uppercase;

		span {
			opacity: 0.7;
		}
		
		&:hover,
		&.active {
			border-bottom: 2px solid;
			
			span {
				opacity: 1;
			}
		}

		.style_Z & {
			color: $style-z;
			border-bottom: $style-z;
		}

		.style_A & {
			color: $style-a;
			border-color: $style-a;
		}

		.style_Y & {
			color: $style-y;
			border-bottom: $style-y;
		}

		@media #{$medium-up} {
			font-size: rem-calc(14);
			height: 48px;
			min-width: 120px;
			padding: ($gutter * 1.5) ($gutter * 2);
			text-align: center;
		}

		&:after {
			content: ' ';
			display: block;
			height: rem-calc(3);
			width: 0;
			margin: rem-calc(10) auto 0 auto;
			transition: width $transition-ease $transition-speed;
		}

		&:hover {
			.style_Z & {
				background-color: rgba($style-z, 0.1);
			}

			.style_A & {
				background-color: rgba($style-a, 0.1);
			}

			.style_Y & {
				background-color: rgba($style-z, 0.1);
			}
		}

		.icon {
			font-size: rem-calc(20);
		}
	}

	ul.last {
		padding-right: rem-calc(15);

		a {
			padding: rem-calc(10 5 0 10);

			@media #{$medium-up} {
				border: none;
				color: $neutral-higher;
				padding: $gutter * 1.5;

				&:hover,
				&.active {
					background-color: transparent;
				}
			}
		}
	}

	select {
		margin: 0;
		width: 60%;
		margin: 0 $column-gutter / 2;

		option:empty {
			display: none;
		}
	}
}
