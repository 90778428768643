.widget-portfolio-ownership-view {
	background: none;
	box-shadow: none;
	margin: 0;
	padding: 0;

	.panel-head {
		display: none;
	}

	.panel-body {
		bottom: 0;
		margin: auto 0 auto 0;
		padding: 0;
	}

	ul.ViewTabsContainer {
		display: flex;
		height: rem-calc(35);
		text-align: center;
		color: $primary-color;

		.style_Z & {
			color: $style-z;
		}

		.style_A & {
			color: $style-a;
		}

		.style_Y & {
			color: $style-y;
		}

		li.ViewTab {
			display: flex;
			flex: 0 0 auto;
			margin: 0 rem-calc(30) 0 0;
			cursor: pointer;
			transition: border-bottom $transition-speed;
			letter-spacing: 1.4px;
		}

		@media #{$medium-up} {
			font-size: rem-calc(14);
			text-transform: uppercase;

			li.ViewTab {
				margin: 0;
				min-height: 50px;
				padding: $gutter * 1.5 $gutter * 2;
			}
		}

		li:not(.is-disabled):hover {
			.style_Z & {
				background: rgba($style-z, 0.1);
			}

			.style_A & {
				background: rgba($style-a, 0.1);
			}

			.style_Y & {
				background: rgba($style-y, 0.1);
			}
		}

		li:not(.is-disabled):hover,
		.is-active {
			border-bottom: 2px solid $primary-color;
			color: $primary-color;

			.style_Z & {
				border-color: $style-z;
			}

			.style_A & {
				border-color: $style-a;
			}

			.style_Y & {
				border-color: $style-y;
			}
		}

		.is-disabled {
			border-bottom: 3px solid transparent !important;
			color: $neutral-medium-high !important;
			cursor: default !important;
		}
	}
}
