.widget-portfolio-holdings {
	h5 {
		color: $brown;
		font-weight: 300;
	}

	.actions a {
		font-size: rem-calc(16);
		text-transform: uppercase;
		display: inline-block;
		margin-right: $column-gutter / 2;

		&.buy {
			color: $green;

			&:hover {
				color: darken($green, 10%);
			}
		}

		&.sell {
			color: $red;

			&:hover {
				color: darken($red, 10%);
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}

	.ct-nowrap.ct-small-visible {
		white-space: normal;

		@media #{$medium-up} {
			white-space: nowrap;
		}
	}

	.ct-row.ct-small-expanded-clone {
		.ct-cell-content {
			display: block;
		}
	}

	.table-title {
		font-weight: 300;
		margin-bottom: rem-calc(15);

		.label {
			margin: 0;
			font-size: 0.6em;
			font-weight: 300;
			vertical-align: middle;
		}
	}

	.ipoclub {
		margin-right: 25px;
		font-style: italic;
	}

	.ipoclub-container {
		display: inline;
	}
}

// hide in Azimut
.ct-row.ct-small-expanded-clone {
	.ct-cell.ct-small-visible {
		display: none !important;
	}
}


.PortfolioHoldings{
	&__disclaimer {
		font-size: rem-calc(14);
	}
}

.RealEconomy {

	display: flex;
	justify-content: space-between;
	align-items: center;

	&__commitment {
		font-size: rem-calc(14);
		font-weight: 600;
	}

	&__detailsLink {
		@extend %Button;
		@extend %ButtonGhost;
	}
}
