@include keyframes(mobileCellFadeInDown) {
  0% {
    opacity: 0;
    max-height: 0;
  }

  99% {
    opacity: 1;
    max-height: em-calc(100);
  }

  100% {
    opacity: 1;
    max-height: auto;
  }
}

@include keyframes(nestedSwitchFadeInDown) {
  0% {
    opacity: 0;
    max-height: 0;
  }

  99% {
    opacity: 1;
    max-height: 100vh;
  }

  100% {
    opacity: 1;
    max-height: auto;
  }
}

// Button reject and accept in my documents, override layout-base.sass style
.style_A {
  .FlexTable__content,
  .FlexTable__values--dispositionAction {
    // .button {}
    .FlexTable__buttonReject,
    .FlexTable__buttonAccept {
      // background-color: green
      // &:hover

      margin-left: $gutter;

      &.is-disabled {
        cursor: default;
        background-color: $neutral-medium-high;
      }
    }
  }

  .FlexTable__values {
    // .button {}
    .FlexTable__buttonAccept.is-disabled {
      cursor: default;
      background-color: $neutral-medium-high;
    }
  }

  .FlexTable__cell--acknowledgment .FlexTable__values--maxActions {
    align-self: flex-start;
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-start;
    margin-left: 0;

    > a {
      margin: 0 10px 0 0;

      &.is-disabled {
        cursor: default;
        background-color: $neutral-medium-high;
      }
    }

    @media #{$large-up} {
      align-self: center;

      a {
        align-self: center;
        white-space: nowrap;
        word-break: initial;
      }
    }
  }

  .FlexTable__disclaimer {
    display: flex;
    justify-content: space-between;
  }
}

.FlexTable {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 10px;
  margin-bottom: $column-gutter;
  word-wrap: break-word;

  @media #{$medium-up} {
    max-width: none;
  }

  .FlexTable__values {
    // .button {}

    .FlexTable__buttonAccept.is-disabled {
      cursor: default;
      background-color: $neutral-medium-high;
    }
  }

  // Checkbox in table in my documents, override user-data-edit.scss style
  .CheckBox {
    &__label {
      &.is-disabled {
        cursor: default;
      }

      // Prima vista della max
      &--acknowledgment {
        width: 75%;
      }
    }

    &__container {
      margin-right: 12px;
    }

    &__input:not(.is-touch) {
      border-radius: 0;
      cursor: pointer;
      display: block;
      height: em-calc(20);
      left: 0;
      margin-bottom: 0;
      margin-right: em-calc(16);
      opacity: 0;
      padding: 0;
      position: absolute;
      top: 0;
      transform: none;
      width: em-calc(20);
      z-index: 1;
    }

    &__input[disabled] {
      cursor: default;
    }
  }

  // &__content {}

  .FlexTable {
    animation: nestedSwitchFadeInDown $transition-speed $transition-ease;

    @include mediaeq('medium') {
      max-width: none;
      padding: $gutter * 1.5;
      transform: translate(0);
      width: 100%;

      .FlexTable__row--head .FlexTable__cell {
        background: $neutral-lower;
      }
    }
  }

  &__title {
    margin-bottom: em-calc(10);
    font-size: em-calc(18);
    color: $neutral-higher;

    @media #{$medium-up} {
      font-size: em-calc(16);
      margin-bottom: 0;
      padding: ($gutter * 2) ($gutter * 1.5);
    }
  }

  .is-hidden {
    display: none;
  }

  &__row {
    display: flex;
    flex-flow: column nowrap;
    transition: background-color $transition-speed $transition-ease;
    width: 100%;

    .no-touch &:hover {
      background-color: $neutral-lower;
    }

    &.FlexTable__row--alsoSmall {
      flex-flow: row nowrap;
    }

    @include mediaeq('medium') {
      flex-flow: row nowrap;
    }

    &--kiid {
      flex-direction: column;
    }

    //TABLE HEAD
    &--head {
      display: none;

      &.FlexTable__row--alsoSmall {
        display: flex;
      }

      @include mediaeq('medium') {
        display: flex;
      }

      .FlexTable__cell {
        background: $neutral-lower;
        color: $neutral-medium-high;
        user-select: none;
        font-size: 0.9em;

        @media #{$medium-up} {
          font-size: em-calc(13);
          padding: ($gutter * 2) ($gutter * 1.5);
        }

        &--sortable {
          cursor: pointer;
          user-select: none;
          padding-right: $column-gutter / 2;
          position: relative;

          &:before {
            top: 25%;
          }

          &.is-ordAsc::before,
          &.is-ordDesc::after {
            border-color: $accent-color;
          }

          @media #{$medium-up} {
            padding-right: $gutter * 2;
          }
        }
      }
    }

    //MOBILE
    &:not(.FlexTable__row--alsoSmall) {
      @include mediaeq('small', only) {
        .FlexTable__cell:not(.FlexTable__cell--withColumns):not(.FlexTable__cell--rto) {
          display: flex;
          // padding-bottom: em-calc(10);
        }

        &.is-opened {
          background: $neutral-lowest;
          // padding-bottom: em-calc(5);

          .FlexTable__cell:not(.FlexTable__cell--withColumns) {
            display: flex;
            animation: mobileCellFadeInDown $transition-speed $transition-ease;
          }
        }
      }
    }

    &:not(.FlexTable__row--head) {
      @include mediaeq('small', only) {
        // border-bottom: 3px solid $neutral-low;
      }
    }
  }

  &__cell {
    flex-grow: 1;
    display: flex;
    padding: $column-gutter / 4;
    align-items: center;
    //width: 100%

    &:not(.FlexTable__cell--sortable):not(.FlexTable__cell--callToAction):not(.FlexTable__cell--groupedBy):before {
      content: attr(data-head-title);
      padding-right: $column-gutter / 2;
      font-size: 1.1em;
      min-width: em-calc(80);
      color: $neutral-medium-high;
      font-weight: 500;

      @include mediaeq('medium') {
        content: '';
        display: none;
      }
    }

    &:not(.FlexTable__cell--sortable):not(.FlexTable__cell--rto):first-child:before {
      display: none;
    }

    &--rto:before {
      width: 50%;
    }

    &--rto {
      padding: $column-gutter / 12;
      border-bottom: 1px solid $neutral-low;
      //@include mediaeq("medium")
      //  border-bottom: none
    }

    &--large:not(.FlexTable__cell--rto) {
      flex: 3 0 auto;
      min-width: em-calc(155);
      word-break: break-word;
      // max-width: em-calc(185);
    }

    &--dispositionItem {
      flex-direction: column;
      align-items: flex-start;
    }

    // @include mediaeq('medium') {
    @media #{$medium-up} {
      border-bottom: 1px solid $neutral-low;
      width: 1px;
      display: flex;
      align-items: center;
      padding: ($gutter * 2) ($gutter * 1.5);
      // flex-grow: 1;
      // min-width: em-calc(80);

      &--simulaKiidContainer {
        flex-direction: column;
        align-items: flex-start;
      }

      &--medium {
        flex: 2 0 auto;
        min-width: em-calc(160);
      }

      &--large {
        flex: 3 0 auto;
        min-width: em-calc(240);
        max-width: none;
      }

      &--dispositionItem {
        align-items: flex-start;
      }

      // &--max {}

      &--onboarding {
        padding-left: $column-gutter;
        flex-direction: column;
        align-items: flex-start;

        .FlexTable__subvalues {
          text-transform: uppercase;
          color: $neutral-medium-high;
          font-size: 0.9em;
        }
      }

      .FlexTable__values--dispositionItem {
        margin-left: $column-gutter * 2;
      }
    }

    &--acknowledgment {
      padding-left: $column-gutter;
      flex-direction: column;
      width: 90%;

      @include mediaeq('large') {
        flex-direction: initial;
        text-align: left;
      }

      .CheckBox {
        margin-left: $column-gutter / 2;
        margin-right: $column-gutter / 2;
      }

      .FlexTable__values--maxActions {
        margin-left: auto;
        flex: 1;
        display: flex;
        justify-content: flex-end;

        > a {
          margin-left: $gutter;

          &.is-disabled {
            cursor: default;
            background-color: $neutral-medium-high;
          }
        }
      }
    }

    &--withColumns {
      .FlexTable__content {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        @include mediaeq('medium') {
          flex-flow: row;
          justify-content: flex-start;
        }

        .FlexTable__values {
          flex-flow: column;
        }

        .FlexTable__subvalues {
          text-transform: uppercase;
          color: $neutral-medium-high;
          font-size: 0.9em;
        }
      }
    }

    &--alignCenter {
      @include mediaeq('large') {
        justify-content: center;
      }
    }

    .FlexTable__infoWrapper {
      align-items: center;
      display: flex;
    }

    .FlexTable__info,
    .FlexTable__action,
    .FlexTable__mobileSwitch,
    .FlexTable__nestedSwitch {
      display: flex;
      align-items: center;
      padding: em-calc(5);
      font-size: 1.5em;
      margin-right: em-calc(10);
      flex-shrink: 0;
      color: $neutral-higher;

      .icon {
        color: $neutral-medium-high;
      }

      @media #{$medium-up} {
        padding: 0;
        margin: 0;
        font-size: em-calc(24);
      }

      .icon {
        margin-right: $column-gutter / 2;
      }
    }

    .FlexTable__content--expiring {
      font-style: em-calc(10);
      font-weight: bold;
      padding-left: em-calc(5);
      flex-shrink: 0;
    }

    .FlexTable__mobileSwitch {
      @include mediaeq('medium') {
        display: none;
      }
    }

    .FlexTable__values {
      flex-grow: 1;
    }

    .FlexTable__values--main,
    .FlexTable__values--titleLink {
      font-size: 0.9em;

      @include mediaeq('medium') {
        font-size: rem-calc(14);
      }

      &.is-bold {
        font-weight: 500;
      }
    }

    .FlexTable__values--important {
      color: $neutral-high;

      @include mediaeq('medium') {
        font-size: em-calc(14);
      }
    }

    .FlexTable__values--number {
      @include mediaeq('medium') {
        text-align: right;
      }
    }

    .FlexTable__values--currency,
    .FlexTable__values--expiring {
      display: block;
      color: $neutral-medium-high;
      font-size: 0.8em;
      line-height: 0.8em;
    }

    .FlexTable__values--expiring {
      color: $red;
      text-transform: uppercase;
    }

    // Used in Max feq second step
    .FlexTable__values--disposition,
    .FlexTable__values--recommendation {
      font-size: 1.2em;
      //margin-left: $column-gutter
    }

    .FlexTable__values--acknowledgment {
      cursor: pointer;
      flex-grow: 1;
      font-size: 1.2em;
      margin-bottom: 0px;

      &.is-disabled {
        cursor: default;
      }
    }

    .FlexTable__values--recommendation {
      flex-grow: 1;
    }

    .FlexTable__values--recommendation,
    .FlexTable__values--titleLink {
      text-decoration: underline;
    }

    .FlexTable__values--acknowledgment {
      font-style: oblique;

      &.no-oblique {
        font-size: 1.2em;
        font-style: unset;
      }

      &.no-margin-left {
        margin-left: 0;
      }

      &.bold {
        font-weight: bold;
      }
    }

    .FlexTable__values--dispositionItem {
      > a:not(:last-child) {
        margin-bottom: 10px;
      }

      display: flex;
      flex-direction: column;
      font-size: 1.2em;

      .FlexTable__infoWrapper {
        .FlexTable__dispositionValue {
          text-decoration: underline;
        }
      }

      > .FlexTable__dispositionValue {
        display: flex;
        align-items: center;
      }

      &:not(:last-child) {
        margin-bottom: $column-gutter;
      }

      @media #{$medium-up} {

        margin-left: $column-gutter * 2;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;

      }

      .FlexTable__orderAppropriateWrapper {
        margin: 0 ($column-gutter * 7) 0 $column-gutter;
        display: flex;
        align-items: center;

        .CheckBox__label {
          margin-right: $column-gutter;
        }
      }
    }

    .FlexTable__values--processType {
      flex-grow: 20;
      margin-left: 20px;
    }

    .FlexTable__info {
      margin-right: 0;
    }

    .FlexTable__info .icon {
      top: 0;
    }
  }

  &__buttonAccept {
    background-color: $green;
  }
  &__buttonReject {
    margin-left: $gutter;
  }

  .FlexTable__badge {
    display: inline-block;
    color: $neutral-lowest;

    @include mediaeq('medium') {
      background: $primary-color;
      font-size: 0.8em;
      font-weight: 600;
      padding: em-calc(5);
      text-transform: uppercase;
      vertical-align: bottom;

      .style_Z & {
        background: $style-z;
      }

      .style_A & {
        background: $style-a;
      }

      .style_Y & {
        background: $style-y;
      }

      .icon {
        color: $neutral-lowest;
      }
    }

    span {
      display: none;

      @include mediaeq('medium') {
        display: inline-block;
      }
    }

    &--expiring {
      @include mediaeq('medium') {
        background: $red;
      }

      .icon {
        color: $red;

        @include mediaeq('medium') {
          color: inherit;
        }
      }
    }
  }

  .FlexTable__nestedPagination {
    background-color: $neutral-lower;
    margin-top: -$column-gutter;
    padding-bottom: $column-gutter;
    padding-top: em-calc(10);
  }
}

.FlexTable__row.ng-leave {
  display: none;
}


.checkboxCell{
  max-width:40px;
}
