//@import ../node_modules/compass-mixins/lib/_compass.scss
//@import ../node_modules/compass-mixins/lib/animation/_core.scss
//@import helpers/functions
//@import helpers/variables
//@import partials/foundation-settings
//@import helpers/mixins

// Animation: rotate
/*@include keyframes(rotate) {
	0% {
		@include rotate(-360deg);
	}

	100% {
		@include rotate(0deg);
	}
}*/

// TEMP: loading spinner

$bubble-size: $gutter * 7.5;

.app-loader-spinner {
  /*$loading-spinner-size: rem-calc(100);
	$loading-spinner-color: $black;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $neutral-higher;
	opacity: 1;
	transition: opacity 300ms ease-out;

	> div {
		@include rounded($loading-spinner-size / 1.5);
		border: 3px solid tint($loading-spinner-color, 20%);
		border-top-color: $neutral-lowest;
		animation: rotate 0.6s linear infinite;

		@media #{$medium-up} {
			@include rounded($loading-spinner-size);
			border-width: 5px;
		}
	}*/

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    //background-color: rgba($neutral-lowest, 0.2);
    background-color: rgba($neutral-highest, 0.2);
    z-index: z(overthestars);

    .app-loader-spinner--relative & {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform:translate(-($bubble-size / 2), -($bubble-size / 2));
    z-index: z(overthestars);

    .app-loader-spinner--relative & {
      position: absolute;
    }
  }

  &__bubble {
    width: $bubble-size;
    height: $bubble-size;
    border-radius: 50%;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;

    animation: bounce 2.0s infinite ease-in-out;

    &--first {
      background-color: $action-default;
    }

    &--second {
      background-color: $primary-base;
      animation-delay: -1.0s;
    }
  }

  &__circle {
    display: flex;
    margin: 0px auto;
    width: $bubble-size;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;

    &:before {
      content:'';
      display: block;
      padding-top: 100%;
    }

    svg {
      animation: rotate 2s linear infinite;
      height: 100%;
      transform-origin: center center;
      width: 100%;

      circle {
        animation:
          dash 1.5s ease-in-out infinite,
          color 6s ease-in-out infinite;
        stroke-linecap: round;
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
      }
    }
  }

  &__value {
    @extend %H2;
    color: $neutral-higher ;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}


// ANIMATION
// --------------------

//CIRCLE

@keyframes rotate{
  100%{
    transform: rotate(360deg);
  }
}

@keyframes dash{
  0%{
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50%{
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35px;
  }
  100%{
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color{
  100%, 0%{
    stroke: $primary-base;
  }
  80%, 90%{
    stroke: $action-default;
  }
}

//BUBLE

@keyframes bounce {
  0%, 100% {
    transform: scale(0.0);
  }
  50% {
    transform: scale(1.0);
  }
}


#grid-frame {
	transition: all 300ms ease 1s;
}

body.loaded {
	background: rgba($neutral-highest, 0.2);

	#grid-frame {
		opacity: 1 !important;
	}

	.app-loader-spinner {
		opacity: 0;
		z-index: -1;
	}
}

