.widget-dossier-selector {
	background: none;
	box-shadow: none;

	.button {
		box-sizing: border-box;
		height: 36px;
		font-family: Roboto, sans-serif;
		letter-spacing: 1.4px;
		background: transparent;
		border: 1px solid $primary;
		color: $primary;
		padding: $gutter ($gutter * 2);

		@include media-query(large) {
			float: right;
		}
	}

	.panel-head {
		display: none;
	}

	.panel-body {
		padding: 0;
	}

	.button {
		margin-bottom: 0px;


		&:hover {
			background-color: $primary;
		}
	}
}
