@function grid-calc($colNumber, $totalColumns) {
	@return percentage(($colNumber / $totalColumns));
}

@mixin dwx-grid-utility($query: false, $order: true, $align: true) {
	@if $query {
		@if $query == small {
			$query-name: '';
		} @else {
			$query-name: #{$query};

			@if $order {
				.#{$query-name}first {
					order: -1;
					box-ordinal-group: 0;
				}

				.#{$query-name}last {
					order: 1;
					box-ordinal-group: 2;
				}
			}

			@for $i from 0 through $total-columns {
				.#{$query-name}order-#{$i} {
					order: $i;
				}
			}

			@if $align {
				// column modificator
				.column {
					&.#{$query-name}top {
						align-self: flex-start;
					}

					&.#{$query-name}middle {
						align-self: center;
					}

					&.#{$query-name}bottom {
						align-self: flex-end;
					}

					&.#{$query-name}baseline {
						align-self: baseline;
					}

					&.#{$query-name}stretch {
						align-self: stretch;
					}
				}

				//row modificator
				.row {
					&.#{$query-name}top {
						align-items: flex-start;
					}

					&.#{$query-name}middle {
						align-items: center;
					}

					&.#{$query-name}bottom {
						align-items: flex-end;
					}

					&.#{$query-name}baseline {
						align-items: baseline;
					}

					&.#{$query-name}stretch {
						align-items: stretch;
					}

					&.#{$query-name}start {
						justify-content: flex-start;
					}

					&.#{$query-name}center {
						justify-content: center;
					}

					&.#{$query-name}end {
						justify-content: flex-end;
					}

					&.#{$query-name}around {
						justify-content: space-around;
					}

					&.#{$query-name}between {
						justify-content: space-between;
					}

					&.#{$query-name}horizontal {
						flex-direction: row;
					}

					&.#{$query-name}horizontal-reverse {
						flex-direction: row-reverse;
					}

					&.#{$query-name}vertical {
						flex-direction: column;
					}

					&.#{$query-name}vertical-reverse {
						flex-direction: column-reverse;
					}

					&.#{$query-name}single-line {
						flex-flow: nowrap;
					}
				}
			}
		}
	}
}

@mixin dwx-grid-row($behavior: false) {
	@if $behavior == nest {
		width: auto;
		margin-#{$default-float}: -($column-gutter/2);
		margin-#{$opposite-direction}: -($column-gutter/2);
		margin-top: 0;
		margin-bottom: 0;
		max-width: none;
	} @else if $behavior == collapse {
		width: 100%;
		margin: 0;
		//max-width: $row-width;
	} @else if $behavior == nest-collapse {
		width: auto;
		margin: 0;
		max-width: none;
	} @else {
		width: 100%;
		margin-#{$default-float}: auto;
		margin-#{$opposite-direction}: auto;
		margin-top: 0;
		margin-bottom: 0;
		//max-width: $row-width;
		display: flex;
	}
}

@mixin dwx-grid-column($columns: false, $center: false, $offset: false, $push: false, $pull: false, $collapse: false, $position: false, $order: false) {
	@if $collapse {
		padding-left: 0;
		padding-right: 0;
	} @else if $collapse == false {
		padding-left: $column-gutter / 2;
		padding-right: $column-gutter / 2;
	}

	@if $columns {
		width: grid-calc($columns, $total-columns);
	}

	@if $push {
		#{$default-float}: grid-calc($push, $total-columns);
		#{$opposite-direction}: auto;
		position: relative;
	}

	@if $pull {
		#{$opposite-direction}: grid-calc($pull, $total-columns);
		#{$default-float}: auto;
		position: relative;
	}

	@if $center {
		margin-#{$default-float}: auto;
		margin-#{$opposite-direction}: auto;
	}

	@if $offset {
		margin-#{$default-float}: grid-calc($offset, $total-columns) !important;
	}

	@if $order {
		order: $order;
	}
}

@mixin dwx-grid-classes($size) {
	@if $size == small {
		$size-name: '';
	} @else {
		$size-name: #{$size};

		.#{$size-name}auto {
			width: auto;
			flex: 1;
		}

		.#{$size-name}auto-reset {
			flex: 0 0 auto;
		}

		@for $i from 0 through $total-columns {
			.#{$size-name}push-#{$i} {
				@include dwx-grid-column($push: $i, $collapse: null);
			}

			.#{$size-name}pull-#{$i} {
				@include dwx-grid-column($pull: $i, $collapse: null);
			}

			.#{$size-name}offset-#{$i} {
				@include dwx-grid-column($offset: $i, $collapse: null);
			}

			.#{$size-name}order-#{$i} {
				@include dwx-grid-column($order: $i, $collapse: null);
			}
		}
	}

	@for $i from 1 through $total-columns {
		.#{$size}-#{$i} {
			@include dwx-grid-column($columns: $i, $collapse: null);
		}
	}
}

@mixin dwx-mq-grid-generator($current-name, $current-breakpoint) {
	@media #{$current-breakpoint} {
		@include dwx-grid-classes($size: $current-name);
		@include dwx-grid-utility($query: $current-name);
	}
}

@mixin dwx-eq-grid-generator($current-name, $current-breakpoint) {
	// @if $current-name == small
	//	 [data-eq-state$="#{$current-name}"]
	//		 @include dwx-grid-classes($size: $current-name);
	//		 @include dwx-grid-utility($query: $current-name);
	// @else

	[data-eq-state$='#{$current-name}'] {
		@include dwx-grid-classes($size: $current-name);
		@include dwx-grid-utility($query: $current-name);
	}

	@if index($dwx-breakpoint-queries, $current-breakpoint) < length($dwx-breakpoint-queries) {
		$next-name: nth($dwx-breakpoint-name, index($dwx-breakpoint-queries, $current-breakpoint) + 1);

		[data-eq-state$='#{$next-name}'] {
			@extend [data-eq-state$='#{$current-name}'];
		}
	}
}

@mixin dwx-grid-generator {
	.row {
		@include dwx-grid-row;
		flex-wrap: wrap;

		&.collapse {
			> .column,
			> .columns {
				@include dwx-grid-column($collapse: true);
			}

			.row {
				margin-left: 0;
				margin-right: 0;
			}
		}

		&.nowrap {
			flex-wrap: nowrap;
		}

		.row {
			@include dwx-grid-row($behavior: nest);
			
			&.collapse {
				@include dwx-grid-row($behavior: nest-collapse);
			}
		}
	}

	.column,
	.columns {
		@include dwx-grid-column($columns: false, $position: true);
		flex-direction: column;
		display: flex;
	}

	@each $current-breakpoint in $dwx-breakpoint-queries {
		$current-name: nth($dwx-breakpoint-name, index($dwx-breakpoint-queries, $current-breakpoint));

		//@if $include-legacy-query-classes
		@include dwx-mq-grid-generator($current-name: $current-name, $current-breakpoint: $current-breakpoint);
		@include dwx-eq-grid-generator($current-name: $current-name, $current-breakpoint: $current-breakpoint);
		//@else
		//	@if $include-element-query-classes
		//		@include dwx-eq-grid-generator($current-name: $current-name, $current-breakpoint: $current-breakpoint)
		//	@else
		//		@include dwx-mq-grid-generator($current-name: $current-name, $current-breakpoint: $current-breakpoint)
	}
}

//@if $include-html-dwx-grid-classes
//	@if $include-namespace-grid-class
//		#{$include-namespace-grid-class}
//			+dwx-grid-generator
//	@else
@include dwx-grid-generator;
