.ExportDocuments {
  color: $neutral-highest;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-top: 1px solid $neutral-low;
  justify-content: flex-end;

  &__element {
    flex: 0 0 auto;
    padding: $column-gutter $column-gutter / 2;
    font-size: rem-calc(34);

    a {
      color: $neutral-medium-high;
    }

    &:first-child {
      padding-left: 0;
    }
  }
}
