// BADGE
// ------------------------------------
.DocumentBadge {
	display: inline-block;
	font-size: 0.7rem;
	vertical-align: top;
	margin-left: 2px;
	margin-top: -2px;
}

.Badge {
	align-self: baseline;
	background-color: $red;
	border-radius: 30px;
	box-shadow: $shadow-medium;
	color: $neutral-lowest;
	display: inline-block;
	font-size: rem-calc(14);
	font-weight: 500;
	height: 26px;
	padding: $gutter / 2;
	min-width: 26px;
	// color: $secondary-color;
	// font-size: 0.85em;
	// padding: 0.375em 0.75em;
	// text-transform: uppercase;

	@media #{$large-up} {
		position: absolute;
		top: -13px;
		right: 0;
	}

	//&--secondary {
	//  background-color: $primary-tone-high;
	//}
	//
	//&--accent {
	//  background-color: $secondary-accent;
	//}
	//
	//&--success {
	//  background-color: $mood-best;
	//}
	//
	//&--positive {
	//  background-color: $mood-better;
	//}
	//
	//&--neutral {
	//  background-color: $neutral-medium;
	//}
	//
	//&--alert {
	//  background-color: $mood-worse;
	//}
	//
	//&--error {
	//  background-color: $mood-worst;
	//}
	//
	//&--warning {
	//  background-color: $mood-bad;
	//}
	//
	//&--variant {
	//  background-color: $secondary-variant;
	//}
}
