//Table Layout
$table-head-color: #707070;

.custom-table-container {
	max-width: 100%;
	position: relative;
	overflow: hidden;
	margin-bottom: $column-gutter;

	.custom-table {
		margin-bottom: 0;
	}

	.custom-table-scroller {
		max-width: 100%;
		overflow: hidden;
		overflow-x: auto;
		margin-bottom: rem-calc(10);
		-webkit-overflow-scrolling: auto;

		&::-webkit-scrollbar {
			width: rem-calc(10);
			height: rem-calc(10);
			border-radius: rem-calc(5);
			background-color: $neutral-lower;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: rem-calc(5);
			background-color: $neutral-medium-low;
		}
	}

	// @media #{$medium-up} {
	// 	transform: translate(-($gutter * 1.5));
	// 	width: calc(100% + #{$gutter * 3});
	// 	max-width: unset;
	// }
}

.custom-table {
	overflow-x: hidden;
	display: table;
	width: 100%;
	line-height: $table-line-height;
	background: $neutral-lowest;
	color: $neutral-higher;
	margin-bottom: 0;

	.icon.icon-chevron-up {
		display: none;
	}
}

.ct-row {
	display: table-row;
	font-size: $table-row-font-size;
	line-height: $table-line-height;
	overflow: hidden;

	.no-touch &:hover:not(.ct-head):not(.highlighted):not(.locked) {
		background-color: $table-even-row-bg;
		transition: background-color 0.3s ease;

		.ct-cell.ct-head-col {
			background-color: $table-even-row-bg;
			transition-property: margin-left, background-color;
		}

		.ct-head-placeholder > div:after {
			background-color: $table-even-row-bg;
			transition: background-color $transition-ease $transition-speed;
		}
	}

	&.highlighted {
		background-color: mix($primary-color, $neutral-lower, 20%);

		.style_Z & {
			background-color: mix($style-z, $neutral-lower, 20%);
		}

		.style_A & {
			background-color: mix($style-a, $neutral-lower, 20%);
		}

		.style_Y & {
			background-color: mix($style-y, $neutral-lower, 20%);
		}
	}

	&.locked:not(.ct-row-small-expanded) {
		opacity: 1;
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAABhJREFUGBljYGBgkARiosGoYuSgomFoAADIVQD7ICCPwQAAAABJRU5ErkJggg==);
		background-repeat: repeat;
		background-position: fixed;

		.ct-cell:first-child {
			background: $neutral-lowest;
			transition: none;
		}

		.ct-head-col + .ct-cell:not(.ct-head-placeholder):not(.ct-head-col),
		.ct-head-shadow + .ct-cell {
			background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, $neutral-lowest 100%);
		}
	}

	// &:not(.ct-row-small-expanded) .ct-head-col {
	// 	background-color: $neutral-lowest;
	// }
}

.AccordionMainObj {
	.ct-row:last-of-type {
		background: $neutral-lower;
	}
}

.ct-head {
	background: $neutral-lower;
	color: $table-head-color;
	font-weight: 500;

	.ct-cell {
		padding-right: rem-calc(15);
		border-bottom: 1px solid $neutral-low;
		position: relative;
		vertical-align: top;
		line-height: 0.9rem;
		// text-transform: uppercase;

		&.sortable {
			cursor: pointer;
			min-width: rem-calc(60);
			//padding-right: 1.2rem
			//check se tenere
			//word-break: break-all

			&:before {
				margin-top: rem-calc(18);
				transform: rotate(0deg);
				//+css-triangle(6px, $table-border-color, top)
			}
		}
		//&:hover
		// background: $table-border-color
		// &:after
		//	 border-color: transparent transparent $neutral-lowest
		// &:before
		//	 border-color: $neutral-lowest transparent transparent
		&.ord-desc:before,
		&.ord-asc:after {
			background-color: $primary-color;
			color: $primary-color;

			.style_Z & {
				background-color: $style-z;
				color: $style-z;
			}

			.style_A & {
				background-color: $style-a;
				color: $style-a;
			}

			.style_Y & {
				background-color: $style-y;
				color: $style-y;
			}
		}

		&:nth-last-child(2).sortable:after,
		&:nth-last-child(2).sortable:before {
			right: 0;
		}
	}

	.ct-head-placeholder:after {
		border-width: 3px;
	}
}

.ct-cell {
	padding: rem-calc(11 15 11 7);
	vertical-align: middle;
	border-bottom: 1px solid $neutral-low;

	&.ct-head-col {
		transition: margin-left $transition-ease $transition-speed;

		@media #{$medium-up} {
			white-space: nowrap;
		}
	}

	.ct-stickied &.ct-head-col {
		position: absolute;
		background: $neutral-lowest;
		z-index: 3;
		//left: 0

		.ct-cell-content,
		&:not(.ct-head) > *:not(.ct-field-name) {
			display: block;
			width: 100%;
			position: relative;
			// top: 50%
			// transform: translateY(-50%)
		}
	}

	// @include box-shadow(0px -1px $neutral-low inset);
	.ct-field-name:after {
		display: inline-block;
		content: ':';
		margin-right: 1rem;
	}

	&.has-drilldown {
		padding: 0;

		> a {
			display: block;
			padding: $table-row-padding;
			background-color: transparent;
			background-image: url('/images/drilldown-triangle.png');
			background-repeat: no-repeat;
			background-position: bottom right;
			background-size: 8px 8px;

			.touch & {
				&:focus,
				&:active {
					background-color: rgba(black, 0.05);
				}
			}
		}
	}

	&.ct-nowrap {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	> .icon {
		font-size: rem-calc(20);
		color: $primary-color;

		.style_Z & {
			color: $style-z;
		}

		.style_A & {
			color: $style-a;
		}

		.style_Y & {
			color: $style-y;
		}
	}

	.icon-dots-circle {
		font-size: rem-calc(24);
		color: $primary-color;

		.style_Z & {
			color: $style-z;
		}

		.style_A & {
			color: $style-a;
		}

		.style_Y & {
			color: $style-y;
		}
	}

	.icon-lock {
		font-size: rem-calc(24);
		color: $neutral-medium-high;
	}

	//mobile toggler
	display: none;

	&.ct-small-visible {
		display: table-cell;

		.ct-field-name {
			display: none;
		}
	}

	&.ct-small-toggler {
		text-align: right;
		display: table-cell;
		cursor: pointer;
		max-width: rem-calc(80);

		.icon {
			font-size: rem-calc(20);
			line-height: rem-calc(20);
			color: mix($primary-color, $neutral-medium-low);
			padding: rem-calc(5);

			.style_Z & {
				color: mix($style-z, $neutral-medium-low);
			}

			.style_A & {
				color: mix($style-a, $neutral-medium-low);
			}

			.style_Y & {
				color: mix($style-y, $neutral-medium-low);
			}
		}
	}

	@media #{$medium-up} {
		display: table-cell;

		&.ct-small-toggler,
		.ct-field-name {
			display: none;
		}

		.layout-container > .layout-element.medium-3 &,
		.layout-container > .layout-element.medium-6 &,
		.layout-container > .layout-element.large-3 &,
		.layout-container > .layout-element.large-6 & {
			display: none;

			&.ct-small-visible,
			&.ct-small-toggler {
				display: table-cell;
			}
		}
	}

	@media #{$xlarge-up} {
		display: table-cell;

		.layout-container > .layout-element.large-6 & {
			display: table-cell;

			&.ct-small-toggler {
				display: none;
			}
		}
	}
}

.ct-stickied .ct-head-placeholder {
	position: relative;
	display: table-cell;
	padding: 0;
	vertical-align: middle;

	> div::after {
		content: ' ';
		width: rem-calc(8);
		height: 100%;
		display: block;
		position: absolute;
		right: rem-calc(-7);
		background: $neutral-lowest;
		z-index: 2;
		border-bottom: 1px solid $neutral-low;
	}
}

.ct-stickied .ct-head .ct-head-placeholder {
	> div::after {
		border-bottom: 1px solid $neutral-low;
	}
}

//Android behavoiur to avoid memory issues
.droid-scroller {
	will-change: transform;
}

.ct-head-shadow {
	display: none;
}

.ct-stickied .ct-head-shadow {
	content: ' ';
	width: rem-calc(7);
	display: block;
	position: absolute;
	z-index: 1;
	background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
	animation: fadeIn 1s ease 1;
	transition: margin-left $transition-ease $transition-speed;
}

//expand click
.ct-row.ct-row-small-expanded {
	background: $table-even-row-bg;

	.icon.icon-chevron-down {
		display: none;
	}

	.icon.icon-chevron-up {
		display: block;
	}
}

.ct-row.ct-small-expanded-clone {
	background: $table-even-row-bg;
	position: relative;
	display: block;
	padding: 0.5rem;

	.ct-cell {
		border-bottom: none;
		display: block !important;
		text-align: left !important;
		animation: fadeInLeft 1.2s;

		@for $i from 1 through 10 {
			&:nth-child(#{$i}) {
				animation: fadeInLeft #{$i * 0.1 + 0.2}s;
			}

			&:last-child {
				animation: none;
			}
		}

		&.ct-empty {
			display: none !important;
		}

		.ct-field-name {
			display: inline-block !important;
		}

		&.has-drilldown {
			padding: $table-row-padding;

			> a {
				display: inline-block;
				padding: 0;
				background: none;
			}
		}
	}

	.ct-small-toggler,
	.ct-head-placeholder,
	.ct-head-shadow,
	.ct-head-sticky,
	.ct-cell.ct-actions,
	.ct-actions-trigger,
	.ct-action {
		display: none !important;
	}

	.ct-cell-content {
		display: inline-block;
	}
	//mobile toggle end
}

//actions
.ct-actions:not(.ct-actions-active) {
	margin: 0;
	border-top: 1px solid $neutral-low;
	max-height: 0;
	max-width: 0;
	overflow: hidden;
	position: absolute;
	margin-bottom: 1px;
	border-bottom: 2px solid $neutral-lowest;
	z-index: -1;
	transition: width 300ms ease-out, height 0ms 300ms ease-out, z-index 0ms 300ms ease-out;

	.ct-action {
		margin-top: rem-calc(500);
	}

	.ct-small-expanded-clone + & {
		z-index: 1;
		opacity: 1;
		display: block;
		max-height: inherit;
		max-width: inherit;
		position: relative;
		text-align: center;
		background-color: $neutral-lower;
		border-radius: 0;
		transition: none;

		.ct-action {
			margin-top: 0;
		}
	}

	.small-2-5 {
		width: 20%;
	}

	.ct-action {
		opacity: 1;
		@include no-bullet;
		color: $blue;
		text-align: center;
		padding: rem-calc(10 0);
		display: inline-block;
		min-width: rem-calc(50);

		.icon {
			font-size: rem-calc(25);
		}

		.action-label {
			font-size: rem-calc(10);
		}
	}

	.ct-small-expanded-clone + & .ct-action:first-child {
		display: none;
	}
}

.ct-actions:before {
	content: ' ';
	left: $column-gutter / 2;
	background-color: $blue;
	position: absolute;
	width: 0;
	height: 0;
	top: 50%;
	display: block;
	border-radius: rem-calc(150);
	transform: translateY(-50%);
	transition: all $transition-ease $transition-speed;
}

.ct-actions-trigger {
	cursor: pointer;
	text-align: center;
}

.ct-actions.ct-actions-active {
	height: 100%;
	width: 100%;
	overflow: hidden;
	border-bottom: 1px solid $neutral-lowest;
	border-radius: 0;
	left: 0;
	position: absolute;
	z-index: 3;
	color: $neutral-lowest;
	margin: 0;
	margin-left: 0 !important;
	@include no-bullet;

	li {
		min-width: rem-calc(50);
		cursor: pointer;
		display: block;
		float: left;
		position: relative;
		top: 50%;
		text-align: center;
		transform: translateY(-50%);
		padding: rem-calc(0 10);

		@for $i from 1 through 20 {
			&:nth-child(#{$i}) {
				animation: fadeIn #{$i * 0.2 + 0.2}s;
			}
		}

		.icon {
			font-size: rem-calc(25);
		}

		.action-label {
			font-size: rem-calc(10);

			.no-touch & {
				display: none;
			}
		}
	}

	&:before {
		left: -50%;
		opacity: 1;
		width: rem-calc(300);
		height: rem-calc(300);
		border-radius: rem-calc(80);
	}
}
