.widget-portfolio-movements .date-range {
	margin-bottom: $column-gutter;

	.drop-down {
		font-size: rem-calc(11);
		font-weight: 600;
		text-transform: uppercase;

		.icon {
			margin-left: 5px;
		}
	}

	.filter-period {
		position: relative;
		margin-top: rem-calc(10);
		padding: $column-gutter;
		background-color: $tt-gray-light;
		opacity: 0;

		&.open {
			@include animation(fadeInDown 0.6s);
			opacity: 1;
		}

		&:before {
			@include css-triangle(rem-calc(10), $tt-gray-light, bottom);
			position: absolute;
			top: rem-calc(-20);
			left: rem-calc(10);
		}

		.label-title {
			font-size: rem-calc(12);
			font-weight: 600;
			text-transform: uppercase;
			color: $brown-light;
		}

		.button {
			margin-bottom: 0;
		}

		.reset-filter {
			position: absolute;
			right: rem-calc(10);
			top: 0;
			text-transform: uppercase;
			font-size: rem-calc(14);
			color: $brown-light;

			&:hover {
				color: $brown;
			}
		}

		select {
			@media #{$medium-up} {
				margin: 0;
			}
		}

		.input-with-icon {
			margin-bottom: rem-calc(20);
			
			@media #{$medium-up} {
				margin-bottom: 0;
			}

			input {
				padding-left: rem-calc(35);
				margin: 0;
			}

			.icon {
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}
