.mobile-logout {
  color: $white;
  cursor: pointer;
  padding: 10px 0px 0px 0px !important;
  font-size: rem-calc(14) !important;
}

.mobile-user-data {
  font-size: rem-calc(14) !important;
  padding: 20px 0px 0px 0px !important;
}

.show-border-bottom{
  border: solid 1px $white;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding-bottom: $gutter * 1.5;

  h6{
    color: $white;
  }
}
