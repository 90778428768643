#widget-focus {
  .panel-body {
    padding: 1.5 * $gutter;
  }
}

@media print {
  aside { display: none; }
  .Portal__view,
  .widget,
  .widget-container,
  #widget-focus,
  .layout-container,
  .main-content,
  main,
  #main-container,
  #content,
  #grid-frame,
  body {
    line-height: normal !important;
    margin: 0px !important;
    overflow: visible !important;
  }

  #widget-focus {
    .panel-body {
      padding: 0 !important;
      box-shadow: 0 0 0 !important;
      height: 23cm;
    }
  }
}

.advisor-name {
  @media print {
    display: none;
  }
}

title {
  @media print {
    display: none;
  }
}

header {
  nav {
    a,
    .treetop {
      @media print {
        display: none;
      }
    }
  }
  @media print {
    display: none !important;
  }
}

menu {
  nav {
    @media print {
      display: none;
    }
    ul {
      li {
        @media print {
          display: none;
        }
      }
    }
  }
}

main {
  nav {
    @media print {
      display: none;
    }
    li {
      @media print {
        display: none;
      }
    }
  }
}

@page {
  margin: 5mm 5mm 5mm 5mm !important;
}
