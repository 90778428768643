// Support page
$style-a: #385696 !default;
$style-z: #ec8400 !default;
$style-y: #533f33 !default;

$help-icon-size: rem-calc(80);

.support-page {
	display: block;
	width: 100%;
	flex: 1 0 auto;
	padding: rem-calc(30);

	.tab-group {
		.tabs {
			@include tabs;

			.tab-title {
				@include tab-title($bg: $neutral-low, $bg-hover: darken($neutral-low, 5%));
			}
		}

		.tabs-content {
			@include tabs-content;
		}
	}

	.external-resources {
		@media #{$medium-up} {
			.left-col {
				padding-right: rem-calc(30);
			}

			.right-col {
				padding-left: rem-calc(30);
			}
		}

		.wrapper {
			text-align: center;
			margin-bottom: $column-gutter * 1.5;
		}

		.icon-wrap {
			display: inline-block;
			@include rounded($help-icon-size);
			background-color: $neutral-medium-low;
			line-height: $help-icon-size * 0.9;
			vertical-align: middle;
			text-align: center;
			font-size: $help-icon-size * 0.45;
			color: $neutral-lowest;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: rem-calc(20) auto;
		}
	}

	.faqs {
		// .icon-qa {
		// 	color: $neutral-low;
		// 	font-size: rem-calc(100);
		// 	margin-right: rem-calc(10);
		// }

		.header {
			font-weight: bold;
			margin-bottom: rem-calc(4);
			font-size: rem-calc(16);
		}

		.subheader {
			color: $neutral-higher;
		}
	}

	.faqs-wrapper {
		margin: rem-calc(30 0 30 0);
	}

	.faq {
		border-bottom: 1px solid $neutral-low;
		display: block;

		.question {
			display: block;
			position: relative;
			font-size: rem-calc(16);
			line-height: 1.4;
			background-color: $neutral-lowest;
			color: $primary-color;
			padding: rem-calc(15 40 15 0);
			@include single-transition(background-color);

			.style_Z & {
				color: $style-z;
			}

			.style_A & {
				color: $style-a;
			}

			.style_Y & {
				color: $style-y;
			}

			&:hover {
				background-color: $neutral-lower;

				.icon {
					color: $neutral-medium-low;
				}
			}

			// @media #{$medium-up} {
			// 	font-size: rem-calc(16);
			// 	padding: rem-calc(10 40 10 0);
			// }

			// @media #{$large-up} {
			// 	padding: rem-calc(15 40 15 15);
			// 	font-size: rem-calc(11);
			// }

			.icon {
				position: absolute;
				top: 50%;
				right: rem-calc(10);
				@include translateY(-50%);
				font-size: rem-calc(20);
				color: $neutral-medium;
				@include single-transition(color);

				font-size: rem-calc(16);

				// &.icon-minus-circle {
				// 	display: none;
				// }

				// &.icon-plus-circle {
				// 	display: inherit;
				// }

				&.owicon-chevron-down {
					display: block;
				}

				&.owicon-chevron-up {
					display: none;
				}
			}
		}

		.answer {
			margin: rem-calc(10 0 30 0);
			font-size: rem-calc(14);
			display: none;

			@media #{$large-up} {
				padding-left: rem-calc(15);
				font-size: rem-calc(16);
			}
		}

		&.active {
			.answer {
				display: block;
			}

			.icon {

				&.owicon-chevron-down {
					display: none;
				}

				&.owicon-chevron-up {
					display: block;
				}
			}
		}
	}

	.external-faqs {
		.icon-question-circle {
			color: $neutral-low;
			font-size: rem-calc(60);
		}
	}

	.waiting {
		background-color: $green;
		z-index: 1;
		
		&:before {
			border-color: tint($green, 50%);
			border-top-color: $neutral-lowest;
		}
	}
}
