#widget-news-list {
  .widget {
    background-color: unset;
    box-shadow: none;
  }
}

.NewsList {
  &__header {
    margin: 10px 0 10px;
    padding: 10px 0;
    display: flex;
    width: 100%;

    &--link {
      color: #3d3e44;
      text-decoration: none;
      padding-left: 20px;
      cursor: pointer;
    }

    &--title {
      font-size: 1.6rem;
      font-weight: 400;
      color: #3d3e44;
      padding-left: 20px;
    }
  }


}
