@mixin single-transition($property:all, $speed:300ms, $ease:ease-out) {
  transition: $property $speed $ease; }

/// Clearfix
/// Uses the micro clearfix hack popularized by Nicolas Gallagher. Include this mixin on a container if its children are all floated, to give the container a proper height.
///
/// @see http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix {
  &:before, &:after {
    content: " ";
    display: table; }
  &:after {
    clear: both; } }

/// Invisible Element
/// Makes an element visually hidden, but accessible.
///
/// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); }

/// Invisible Element Off
/// Reverses the CSS output by the `element-invisible()` mixin.
@mixin element-invisible-off {
  position: static !important;
  height: auto;
  width: auto;
  overflow: visible;
  clip: auto; }


@mixin font-smoothing($antialiased: true) {
  @if $antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  @else if $antialiased == false {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto; } }

@mixin square($size: 100%) {
  width: $size;
  height: $size; }

@mixin rounded($size: false) {
  @if $size {
    @include square($size); }
  @include border-radius($global-rounded); }

@mixin no-bullet {
  list-style-type: none;
  margin-left: 0; }

@mixin show-for-medium-up($display: block) {
  display: none;
  @media #{$medium-up} {
    display: $display; } }

@mixin show-for-large-up($display: block) {
  display: none;
  @media #{$large-up} {
    display: $display; } }

@mixin hide-for-medium-up($display: block) {
  display: $display;
  @media #{$medium-up} {
    display: none; } }

@mixin hide-for-large-up($display: block) {
  display: $display;
  @media #{$large-up} {
    display: none; } }

@mixin panel($bg: white, $radius: true, $margin-bottom: $column-gutter, $padding: $column-gutter, $shadow: false) {
  display: block;
  margin-bottom: $margin-bottom;
  padding: $padding;
  border-width: 1px;
  border-style: solid;
  @include panel-color($bg);

  @if $radius == true {
    border-radius: $global-radius; }
  @else if $radius {
    border-radius: $radius; }

  @if $shadow == true {
    @include panel-shadow; }
  @else if $shadow {
    @include panel-shadow($shadow); } }

@mixin panel-shadow($shadow: $default-box-shadow) {
  border: none;
  box-shadow: $shadow; }

@mixin panel-color($bg: white, $border: true) {
  background-color: $bg;
  @if $border == false {
    border: none; }
  @else if $border == true {
    border-color: darken($bg, 10%); }
  @else if $border {
    border-color: $border; } }

@mixin input-with-icon($height: $form-element-height, $icon-color: $neutral-medium-high, $icon-size: rem-calc(15), $left: rem-calc(12), $datepicker: false) {
  position: relative;
  .icon {
    top: 0;
    left: $left;
    height: $height;
    line-height: $height;
    font-size: $icon-size;
    color: $icon-color;
    position: absolute; }
  @if $datepicker {
    .datepicker-trigger {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
      left: 0;
      cursor: pointer; } }
  input[type="text"] {
    padding-left: $height; } }
