/* SASS variables are information about icon's compiled state, stored under its original file name
 *
 * .icon-home
 *   width: $icon-home-width
 *
 * The large array-like variables contain all information about a single icon
 * $icon-home: x y offset_x offset_y width height total_width total_height image_path
 *
 * At the bottom of this section, we provide information about the spritesheet itself
 * $spritesheet: width height image $spritesheet-sprites
 */

$az-login-logo-name: 'az-login-logo';
$az-login-logo-x: 0px;
$az-login-logo-y: 0px;
$az-login-logo-offset-x: 0px;
$az-login-logo-offset-y: 0px;
$az-login-logo-width: 140px;
$az-login-logo-height: 131px;
$az-login-logo-total-width: 247px;
$az-login-logo-total-height: 131px;
$az-login-logo-image: '/images/sprites/generic-sprite.png';
$az-login-logo: 0px, 0px, 0px, 0px, 140px, 131px, 247px, 131px, '/images/sprites/generic-sprite.png', 'az-login-logo';
$azimut-logo-name: 'azimut-logo';
$azimut-logo-x: 142px;
$azimut-logo-y: 0px;
$azimut-logo-offset-x: -142px;
$azimut-logo-offset-y: 0px;
$azimut-logo-width: 105px;
$azimut-logo-height: 98px;
$azimut-logo-total-width: 247px;
$azimut-logo-total-height: 131px;
$azimut-logo-image: '/images/sprites/generic-sprite.png';
$azimut-logo: 142px, 0px, -142px, 0px, 105px, 98px, 247px, 131px, '/images/sprites/generic-sprite.png', 'azimut-logo';
$az-login-logo-2x-name: 'az-login-logo-2x';
$az-login-logo-2x-x: 0px;
$az-login-logo-2x-y: 0px;
$az-login-logo-2x-offset-x: 0px;
$az-login-logo-2x-offset-y: 0px;
$az-login-logo-2x-width: 280px;
$az-login-logo-2x-height: 262px;
$az-login-logo-2x-total-width: 494px;
$az-login-logo-2x-total-height: 262px;
$az-login-logo-2x-image: '/images/sprites/generic-sprite@2x.png';
$az-login-logo-2x: 0px, 0px, 0px, 0px, 280px, 262px, 494px, 262px, '/images/sprites/generic-sprite@2x.png', 'az-login-logo-2x';
$azimut-logo-2x-name: 'azimut-logo-2x';
$azimut-logo-2x-x: 284px;
$azimut-logo-2x-y: 0px;
$azimut-logo-2x-offset-x: -284px;
$azimut-logo-2x-offset-y: 0px;
$azimut-logo-2x-width: 210px;
$azimut-logo-2x-height: 196px;
$azimut-logo-2x-total-width: 494px;
$azimut-logo-2x-total-height: 262px;
$azimut-logo-2x-image: '/images/sprites/generic-sprite@2x.png';
$azimut-logo-2x: 284px, 0px, -284px, 0px, 210px, 196px, 494px, 262px, '/images/sprites/generic-sprite@2x.png', 'azimut-logo-2x';
$generic-sprite-width: 247px;
$generic-sprite-height: 131px;
$generic-sprite-image: '/images/sprites/generic-sprite.png';
$generic-sprite-sprites: $az-login-logo, $azimut-logo;
$generic-sprite: 247px, 131px, '/images/sprites/generic-sprite.png', $generic-sprite-sprites;
$generic-sprite-2x-width: 494px;
$generic-sprite-2x-height: 262px;
$generic-sprite-2x-image: '/images/sprites/generic-sprite@2x.png';
$generic-sprite-2x-sprites: $az-login-logo-2x, $azimut-logo-2x;
$generic-sprite-2x: 494px, 262px, '/images/sprites/generic-sprite@2x.png', $generic-sprite-2x-sprites;

/* These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
 *
 * The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
 */

$az-login-logo-group-name: 'az-login-logo';
$az-login-logo-group: 'az-login-logo', $az-login-logo, $az-login-logo-2x;
$azimut-logo-group-name: 'azimut-logo';
$azimut-logo-group: 'azimut-logo', $azimut-logo, $azimut-logo-2x;
$retina-groups: $az-login-logo-group, $azimut-logo-group;

/* The provided mixins are intended to be used with the array-like variables
 *
 * .icon-home
 *   @include sprite-width($icon-home)
 *
 * .icon-email
 *   @include sprite($icon-email)
 */

@mixin sprite-width($sprite) {
	width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
	height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
	$sprite-offset-x: nth($sprite, 3);
	$sprite-offset-y: nth($sprite, 4);
	background-position: $sprite-offset-x $sprite-offset-y;
}

@mixin sprite-image($sprite) {
	$sprite-image: nth($sprite, 9);
	background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
	@include sprite-image($sprite);
	@include sprite-position($sprite);
	@include sprite-width($sprite);
	@include sprite-height($sprite);
}

/* The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
 *   It should be used with a "retina group" variable.
 *
 * The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
 *
 * $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, )
 *
 * .icon-home
 *   @include retina-sprite($icon-home-group)
 */

@mixin sprite-background-size($sprite) {
	$sprite-total-width: nth($sprite, 7);
	$sprite-total-height: nth($sprite, 8);
	background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
	$normal-sprite: nth($retina-group, 2);
	$retina-sprite: nth($retina-group, 3);
	@include sprite($normal-sprite);

	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		@include sprite-image($retina-sprite);
		@include sprite-background-size($normal-sprite);
	}
}

/* The `sprites` mixin generates identical output to the CSS template
 *   but can be overridden inside of SASS
 *
 * @include sprites($spritesheet-sprites)
 */

@mixin sprites($sprites) {
	@each $sprite in $sprites {
		$sprite-name: nth($sprite, 10);

		.#{$sprite-name} {
			@include sprite($sprite);
		}
	}
}

/* The `retina-sprites` mixin generates a CSS rule and media query for retina groups
 *   This yields the same output as CSS retina template but can be overridden in SASS
 *
 * @include retina-sprites($retina-groups)
 */

@mixin retina-sprites($retina-groups) {
	@each $retina-group in $retina-groups {
		$sprite-name: nth($retina-group, 1);
		
		.#{$sprite-name} {
			@include retina-sprite($retina-group);
		}
	}
}
