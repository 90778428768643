// ----------------------------------------------------------
// AZ MY DOCUMENTS
// ----------------------------------------------------------
.widget-sign-room,
.widget-sign-room-advisor,
.widget-my-documents {
  width: 100%;
  
  .widget.generic-documents {
    .filter-bar {
      position: relative;
      margin-bottom: $gutter * 1.5;
      // padding: rem-calc(0 5)

      .utilsContainer{
        display: flex;
        flex-direction: row;
        justify-content: flex-end
      }

      ul.last {
        min-width: rem-calc(90);
        //width: 100%;

        .owicon-loop {
          transform: scaleX(-1);
        }

        @media #{$large-up} {
          min-width: unset;
          padding: 0;
          width: auto;

          .owicon-loop,
          .owicon-grid-multiple,
          .owicon-grid-list {
            font-size: rem-calc(24);
          }
        }
      }

      // li.divider {
      // 	background: $neutral-low;
      // 	position: absolute;
      // 	display: block;
      // 	width: 100%;
      // 	height: 1px;

      // 	@media #{$medium-up} {
      // 		width: 1px;
      // 		height: 100%;
      // 		right: 0;
      // 		top: 0;
      // 	}
      // }

      .filter-dropdown {
        font-size: 0.9em;
        padding: $column-gutter / 2 $column-gutter;

        @media #{$medium-up} {
          display: flex;
          flex-direction: column;
          font-size: rem-calc(16);
          padding: 0 $gutter * 1.5;

          span {
            font-size: rem-calc(12);
          }

          a[dropdown-toggle] {
            color: $neutral-higher;
            font-size: rem-calc(16);
            opacity: 1;
          }
        }

        a[data-dropdown],
        a[dropdown-toggle] {
          font-size: 1em;
          font-weight: 400;
          display: inline-block;
          padding: 0 $column-gutter / 2;
          position: relative;

          @media #{$medium-up} {
            padding: 0;
            text-align: left;
            text-transform: none;

            &:hover {
              background-color: transparent;
              border: none;
            }
          }

          &:after {
            @include css-triangle(5px, $primary-color, top);
            display: inline-block;
            position: absolute;
            right: -5px;
            top: 0;

            .style_Z & {
              @include css-triangle(5px, $style-z, top);
            }

            .style_A & {
              @include css-triangle(5px, $style-a, top);
            }

            .style_Y & {
              @include css-triangle(5px, $style-y, top);
            }
          }
        }

        span {
          color: $neutral-medium-high;
        }
      }

      #my-docs-order-by-dropdown {
        li,
        a {
          text-transform: none;
          text-align: left;
          font-weight: 400;
        }

        a {
          color: $neutral-higher;
          border: none;
        }
      }
    }

    .search-bar {
      padding-bottom: rem-calc(3);
      font-size: 0.8em;
      color: $neutral-higher;

      .left {
        padding-top: rem-calc(8);
      }

      @media #{$medium-up} {
        padding: ($gutter * 3) ($gutter * 1.5);

        p {
          margin: 0;
          font-size: rem-calc(14);
        }
      }
    }

    .document-grid {
      .title-caption {
        display: flex;
        flex-wrap: wrap;

        p {
          flex-basis: 100%;
          font-size: rem-calc(12);
          line-height: rem-calc(24);
          font-weight: 500;
          word-wrap: break-word;

          @media #{$medium-up} {
            font-size: rem-calc(12);
            letter-spacing: 0.8px;
            padding: $gutter ($gutter * 1.5);
          }
        }

        div {
          flex-basis: 50%;
          display: flex;
          flex-direction: column;
        }

        > div:last-of-type {
          padding-bottom: rem-calc(10);
        }

        span {
          display: inline-block;
          padding-top: 0;
          padding-bottom: 0;
          font-size: rem-calc(11);

          &.strong {
            display: inline-block;
            color: $gray-light;
            font-weight: 400;
          }

          &[a] {
            color: $neutral-higher;
          }

          @media #{$medium-up} {
            font-size: rem-calc(12);
            letter-spacing: rem-calc(0.8);
          }
        }

        @media #{$medium-up} {
          div {
            padding: 0 $gutter * 1.5 $gutter * 1.5;
          }

          span {
            padding: 0;
          }
        }
      }

      a.doc-thumb {
        .preview-mask {
          @include background-image(linear-gradient(#46a3e5, #005dad));
        }
      }

      .badge-info {
        background: $black;
      }
    }

    ul.pagination {
      margin-top: $column-gutter;
      text-align: center;
    }

    .custom-table {
      margin: rem-calc(15 0 30);

      .ct-row.ct-head {
        background: $neutral-lower;
        text-transform: uppercase;
        color: $neutral-high;
        font-weight: 800;
        font-size: rem-calc(11);

        .ct-cell {
          padding: rem-calc(5 20 15 15);
          border-right: 1px solid $neutral-low;
          border-bottom: 1px solid $neutral-low;

          @media #{$large-up} {
            padding: rem-calc(10 20 25);
          }

          &:last-of-type {
            border-right: none;
          }

          &.sortable:not(.ord-desc):before {
            border-color: $neutral-medium-low transparent transparent;
          }

          &.sortable:not(.ord-asc):after {
            border-color: transparent transparent $neutral-medium-low;
          }
        }
      }

      .ct-cell {
        padding: rem-calc(10 15);
        color: $neutral-medium-high;
        vertical-align: top;
        font-size: rem-calc(11);

        @media #{$large-up} {
          padding: rem-calc(15 20);
        }
      }

      a.left {
        max-width: rem-calc(40);
        margin-right: $column-gutter / 2;
      }

      .values {
        max-width: 60%;
        float: left;

        @media #{$large-up} {
          max-width: 100%;
        }
      }

      .icon-document {
        font-size: rem-calc(20);
      }

      i.icon-info-circle {
        color: $azure;
        font-size: rem-calc(40);
      }

      i.icon-warning {
        color: $orange;
        font-size: rem-calc(27);
      }

      i.icon-chevron-down-circle,
      i.icon-chevron-up-circle {
        color: $azure;
        font-size: rem-calc(27);
      }

      .custom-table {
        overflow: hidden;
        display: none;
        @include box-shadow(0 0 8px rgba(black, 0.2) inset);
        margin: 0;
        background: $neutral-lower;
        position: absolute;

        &.expanded {
          display: table;
          @include animation(fadeInDown 0.5s);

          @for $i from 2 through 11 {
            > .ct-row:nth-child(#{$i}) {
              @include animation(fadeInDown #{$i * 0.1 + 0.2}s);
            }
          }
        }

        .ct-row.ct-head {
          background: none;
        }

        .ct-row:hover {
          .no-touch & {
            background: none;
          }
        }
      }

      .place-holder {
        height: 0;
      }
    }

    // @include transition(height 0.3s);
    .label-value {
      font-size: rem-calc(14);
      color: $black;
      display: block;
    }

    .label-expiry {
      color: $red;
      text-transform: uppercase;
    }

    .label-action {
      color: $primary-color;
      text-transform: uppercase;
      font-weight: 800;
      line-height: rem-calc(18);

      .style_Z & {
        color: $style-z;
      }

      .style_A & {
        color: $style-a;
      }

      .style_Y & {
        color: $style-y;
      }

      .icon {
        font-size: rem-calc(16);
        margin: 0.2rem 0.2rem 0 0;
      }
    }

    .label-warning {
      color: white;
      font-weight: 800;
      background: $orange;
      text-transform: uppercase;
      padding: rem-calc(3 7);
    }

    .label-doc-number {
      font-size: rem-calc(24);
      line-height: rem-calc(24);
      font-weight: 600;
    }

    .RtoAcknowledgmentContainer {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;

      @media #{$medium-up} {
        width: 50%;
        flex-direction: row;
      }

      .FlexTable__disclaimer {
        align-items: center;
      }

      .CheckBox__label {
        font-size: 1.2rem;
        font-weight: 600;
      }
    }

    .RtoAcknowledgmentActionsContainer {
      display: flex;
      justify-content: center;
      width: 100%;

      a {
        margin: 0.2rem;

        &.is-disabled {
          cursor: default;
          background-color: $neutral-medium-high;
        }
      }

      @media #{$medium-up} {
        justify-content: flex-end;
        width: 50%;
      }
    }

    .RtoAcknowledgmentCheckContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      @media #{$medium-up} {
        width: 50%;
      }

      .RtoAcknowledgmentCheckboxContainer {
        display: flex;
        justify-content: space-between;
        width: 50%;
      }

      .RtoAcknowledgmentLabelWrapper {
        width: 50%;

        label {
          font-weight: 600;
          color: $neutral-medium-high;
        }
      }

      .CheckBox__label {
        margin-right: 0.5rem;
        font-size: 0.8rem;
        width: 100%;

        @media #{$medium-up} {
          font-size: 1.2rem;
        }
      }
    }

    .AzLife {

      display: flex;
      align-items: center;
      width: fit-content;
      font-size: 1.4rem;
      margin: 0 0 ($gutter * 2) ($gutter * 2);

      &__LinkContainer {

        @extend %Button;
        color: $white;
        margin-right: $gutter;
        cursor: pointer;

        .AzLife--icon {
          margin-left: $gutter;
        }
      }

      &__ErrorContainer {
        color: $red;

        .AzLife--icon {
          margin-right: $gutter * 0.5;
        }
      }
    }

    .ViewContainer {
      &--owui {
        margin: -10px;
      }

      @media #{$medium-up} {
        &--owui {
          margin: 0px;
        }
      }
    }
  }
}

#document-info-modal.sign-room {
  max-height: calc(100vh - 150px);
  overflow: auto;

  .label-value {
    display: block;
    font-size: 1rem;
  }
}

.waiting {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(white, 0.85);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    display: block;
    content: '';
    @include rounded($loading-spinner-size);
    border: 4px solid tint($primary-color, 70%);
    border-top-color: tint($primary-color, 30%);
    animation: rotate 0.6s linear infinite;

    .style_Z & {
      border: 4px solid tint($style-z, 70%);
      border-top-color: tint($style-z, 30%);
    }

    .style_A & {
      border: 4px solid tint($style-a, 70%);
      border-top-color: tint($style-a, 30%);
    }

    .style_Y & {
      border: 4px solid tint($style-y, 70%);
      border-top-color: tint($style-y, 30%);
    }
  }
}

.waiting-fullscreen {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(white, 0.85);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    display: block;
    content: '';
    @include rounded($loading-spinner-size);
    border: 4px solid tint($primary-color, 70%);
    border-top-color: tint($primary-color, 30%);
    animation: rotate 0.6s linear infinite;

    .style_Z & {
      border: 4px solid tint($style-z, 70%);
      border-top-color: tint($style-z, 30%);
    }

    .style_A & {
      border: 4px solid tint($style-a, 70%);
      border-top-color: tint($style-a, 30%);
    }

    .style_Y & {
      border: 4px solid tint($style-y, 70%);
      border-top-color: tint($style-y, 30%);
    }
  }
}

.MyDocuments {
  &__title {
    font-weight: 600;
    font-size: 1.6rem;
    margin: 0 0 10px 20px;
  }
}

body {
  .widget {
    .waiting {
      &:before {
        border: 4px solid tint($style-a, 70%);
        border-top-color: tint($style-a, 30%);
      }
    }
  }
}
