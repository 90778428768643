// THIS NEEDS TO BE HEAVILY REFACTORED!

$logoimg: url('/images/logo-style-default.png');
$logoz: url('/images/logo-style-z.png');
$logoa: url('/images/logo-style-a.svg');
$logoy: url('/images/logo-style-y.png');

body,
html {
  overflow: hidden;
  font-smoothing: subpixel-antialiased;
}

main {
  background-color: $neutral-lower;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;

  > * {
    flex-shrink: 0;
  }
}

//Header
.logo {
  display: inline-block;
  width: 100%;
  padding: rem-calc(15);
  border-top: 3px solid $neutral-lowest;

  .style_Z & {
    border-color: $style-z;
  }

  .style_A & {
    border-color: transparent;
  }

  .style_Y & {
    border-color: $style-y;
  }

  @media #{$large-up} {
    padding: rem-calc(16);
    position: absolute;
    z-index: 4;
  }

  .logo-image {
    width: 200px;
    height: 50px;
    background-image: $logoimg;
    background-size: 200px 50px;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 12px;
    margin-top: 12px;


    .style_Z & {
      background-image: $logoz;
    }

    .style_A & {
      background-image: $logoa;
    }

    .style_Y & {
      background-image: $logoy;
    }
  }
}

header.app {
  $tt-navbar-height: rem-calc(50);

  .tt-navbar {
    position: relative;
    background-image: linear-gradient(#8DB8E2, #185A9D);
    background-color: $black;
    color: $neutral-lowest;
    box-shadow: 0 0 10px rgba(black, 0.8);
    z-index: 2;
    height: $tt-navbar-height;
    line-height: $tt-navbar-height;
    vertical-align: middle;

    .treetop {
      flex: 1 0 auto;
    }

    a {
      display: inline-block;
      color: $neutral-lowest;

      .touch &:active {
        @include single-transition(background-color);
        background-color: rgba(black, 0.2);
      }

      &.icon {
        top: 0;
        font-size: rem-calc(20);
        line-height: $tt-navbar-height;
        vertical-align: middle;
        padding: rem-calc(0 10);
      }
    }
  }
}

// First sidebar
$first-sidebar-bg: $black !default;

aside.first-sidebar {
  display: flex;
  background-image: linear-gradient(#8DB8E2, #185A9D);
  flex-direction: column;
  z-index: 1;

  @media #{$large-up} {
    background-color: $neutral-lowest;
    box-shadow: 0px 0px 15px rgba($black, 0.2);
    z-index: 3;
    overflow: visible;
    max-width: rem-calc(220);
  }
}

.iefix {
  @media only screen and (max-width: 600px) {
    height: 140vh;
  }

  .uppercase-label {
    color: $white;
  }

  menu {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;

    nav {
      flex-shrink: 0;

      a {
        display: block;
        color: $neutral-lowest;
        padding: rem-calc(10 15);
        font-size: 1rem;

        &.active {
          background-color: $primary-color;

          .style_Z & {
            background-color: $style-z;
          }

          .style_A & {
            background-color: rgba($white, 0.2);
          }

          .style_Y & {
            background-color: $style-y;
          }
        }
      }
    }

    nav.primary {
      margin-bottom: rem-calc(20);

      a {
        text-transform: uppercase;
        white-space: nowrap;
        font-size: rem-calc(14);

        .icon {
          margin-right: $column-gutter;
        }

        // .no-touch & {
        // 	@include single-transition(background-color);

        // 	&:not(.active):hover {
        // 		background-color: rgba(black, 0.2);

        // 		@media #{$large-up} {
        // 			background-color: transparent;
        // 		}
        // 	}
        // }
      }
    }

    nav.secondary {
      flex-grow: 1;
      margin: rem-calc(20 0);
      order: 1;

      .widget-user-data .heading span {
        font-size: 1rem;

        @media #{$large-up} {
          font-size: rem-calc(12);
        }
      }

      .uppercase-label {
        font-size: rem-calc(10);
        margin: rem-calc(0 15 30 15);
        border-bottom: 1px solid $white;
        color: $white;
      }

      a {
        padding: rem-calc(10 0);
        text-transform: uppercase;
        padding-left: rem-calc(15);

        .no-touch & {
          @include single-transition(background-color);

          &:not(.active):hover {
            background-color: rgba(black, 0.2);

            @media #{$large-up} {
              background-color: $primary-color;

              .style_Z & {
                background-color: $style-z;
              }

              .style_A & {
                background-color: $style-a;
              }

              .style_Y & {
                background-color: $style-y;
              }
            }
          }
        }
      }
    }

    nav.third {
      padding: rem-calc(0 ($gutter * 2) ($gutter * 2.5));

      p {
        margin-bottom: 0;
        margin-right: rem-calc(15);
      }

      .advisor {
        display: block;
        position: relative;
        padding: rem-calc(0 0 5 0);

        .icon {
          display: block;
          position: absolute;
          font-size: rem-calc(18);
          color: $neutral-lowest;
          right: rem-calc(3);
          top: rem-calc(18);
        }
      }
    }

    @media #{$large-up} {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;

      nav.primary {
        display: flex;
        order: 2;
        // width: calc(65% - #{$off-canvas-width * 0.65});
        flex: 0 0 100%;
        width: auto;
        //max-width: $row-width * 0.65;
        margin: rem-calc(20 0 0);
        min-height: rem-calc(80);
        //max-width: $row-width * 0.65;

        // ul {
        // display: flex;
        // }

        li.upperLine .logo-az-sim {
          margin-left: 0;

          // &::before {
          // 	content: ' ';
          // 	display: block;
          // 	position: absolute;
          // 	left: 0;
          // 	top: rem-calc(-30);
          // 	right: 0;
          // 	width: 100%;
          // 	height: rem-calc(30);
          // 	background-color: $primary-color;

          // 	.style_Z & {
          // 		background-color: $style-z;
          // 	}

          // 	.style_A & {
          // 		background-color: $style-a;
          // 	}

          // 	.style_Y & {
          // 		background-color: $style-y;
          // 	}
          // }

          &:hover::after,
          &.active,
          &.active::after,
          &.active:hover::after {
            background-color: transparent;
          }

          .logo-image {
            width: 100%;
            height: 70px;
            background-image: $logoimg;
            background-size: 220px 70px;
            background-position: center;
            background-repeat: no-repeat;

            .style_Z & {
              background-image: $logoz;
            }

            .style_A & {
              background-image: $logoa;
            }

            .style_Y & {
              background-image: $logoy;
            }
          }
        }

        li {
          display: flex;
          flex: 0 0 auto;

          &:not(:first-child) {

            a {
              display: flex;
              position: relative;
              align-items: center;
              font-size: rem-calc(14);
              color: $white;
              font-size: rem-calc(16);
              padding-left: rem-calc(22);
              height: rem-calc(50);
              margin: 0;
              width: 100%;
              border-left: 4px solid transparent;
              box-sizing: border-box;


              .icon {
                font-size: rem-calc(20);
              }
              // &::after {
              // 	content: ' ';
              // 	display: block;
              // 	position: absolute;
              // 	left: 0;
              // 	bottom: 0;
              // 	right: 0;
              // 	width: 100%;
              // 	height: rem-calc(3);
              // 	@include single-transition(background-color);
              // 	background-color: transparent;
              // }

              // &:hover::after {
              // 	background-color: $neutral-high;
              // }

              &.active,
              &:hover {
                background-color: rgba($white, 0.2);
                border-left: 4px solid $white;


                // &:after {
                // 	background-color: $primary-color;

                // 	.style_Z & {
                // 		background-color: $style-z;
                // 	}
                // 	.style_A & {
                // 		background-color: $style-a;
                // 	}

                // 	.style_Y & {
                // 		background-color: $style-y;
                // 	}
                // }

                // &:hover {
                // 	color: $primary-color;

                // 	.style_Z & {
                // 		color: $style-z;
                // 	}

                // 	.style_A & {
                // 		color: $style-a;
                // 	}

                // 	.style_Y & {
                // 		color: $style-y;
                // 	}

                // 	&:after {
                // 		background-color: $primary-color;

                // 		.style_Z & {
                // 			background-color: $style-z;
                // 		}

                // 		.style_A & {
                // 			background-color: $style-a;
                // 		}

                // 		.style_Y & {
                // 			background-color: $style-y;
                // 		}
                // 	}
                // }
              }
            }
          }
        }
      }

      nav.secondary {
        order: 1;
        width: 100%;
        padding-right: $column-gutter / 2;
        background-color: $black;
        flex: 0 1 auto;
        margin: 0;
        //background-color: $black;
        //padding-left: $off-canvas-width;

        ul {
          max-width: $row-width;
          margin: 0 auto;
          text-align: right;

          li {
            display: inline-block;
            margin: 0;

            a {
              font-size: rem-calc(12);
              padding: rem-calc(7 10);
            }
          }
        }
      }

      nav.third {
        // order: 3;
        // width: auto;
        // max-width: $row-width * 0.35;
        // text-align: right;
        // padding: rem-calc(0 16);
        // flex: 0 0 100%;
        // justify-content: flex-end;

        // > div {
        // 	align-self: center;
        // }

        position: absolute;
        bottom: $column-gutter;
        left: 0;

        .button {
          display: inline-block;
          margin: 0;
        }

        .advisor {
          display: block;
          // flex-direction: row;
          // flex-wrap: nowrap;
          padding-top: rem-calc(8);


          &:hover {
            background-color: transparent !important;
            transition: none;
          }

          .advisor-name {
            padding-right: rem-calc(5);

            p {
              margin: 0;
            }
          }

          .icon {
            position: relative;
            align-self: flex-end;
            top: auto;
            right: auto;
          }

          .advisor-name,
          .icon {
            color: $white;

            .style_Z & {
              color: $style-z;
            }

            .style_A & {
              color: $white;
            }

            .style_Y & {
              color: $style-y;
            }
          }
        }
      }
    }
  }
}

#advisor-info {
  // max-width: 220px;
  // width: 220px;
  padding: rem-calc(10);
  word-break: break-all;
  word-wrap: break-word;

  @media #{$large-up} {
    max-width: 400px;
    width: 300px;
    padding: rem-calc(20);
  }

  .icon-text-block {
    margin-top: rem-calc(10);

    &:first-child {
      margin-top: 0;
    }
  }

  .icon-wrap {
    display: none;
    background-color: $blue;

    @media #{$large-up} {
      display: block;
      text-align: center;

      .icon {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .info-wrap {
    max-width: 240px;

    @media #{$large-up} {
      max-width: 310px;
      margin-left: rem-calc(10);
    }
  }

  .uppercase-label {
    color: $blue;
  }
}

// Second sidebar
$second-sidebar-bg: $neutral-high !default;

aside.second-sidebar {
  position: absolute;
  background-color: $second-sidebar-bg;
  color: $neutral-lowest;
  padding-top: rem-calc(20);

  @media #{$large-up} {
    position: relative;
    padding-top: rem-calc(60);
  }

  .main-title {
    color: $neutral-lowest;
    text-transform: uppercase;
  }

  p.valuation {
    font-size: rem-calc(12);
    color: tint($second-sidebar-bg, 50%);
    text-transform: uppercase;
    margin: 0;

    span.val-date {
      color: $neutral-lowest;
    }
  }

  ul.portfolio-info-items {
    @include no-bullet;
    margin: 0;

    li {
      display: block;
      padding: rem-calc(15);

      &.value-perf {
        padding: 0;
        display: flex;

        p {
          margin: 0;
        }

        .uppercase-label {
          line-height: 2;
        }

        .total-value {
          flex: 1 0 auto;
          padding: rem-calc(10 15);
          background-color: rgba($neutral-lowest, 0.1);
        }

        .total-ytd {
          padding: rem-calc(10 15);
          background-color: $neutral-higher;

          .uppercase-label {
            color: $neutral-lowest;
            opacity: 0.5;
          }

          &.positive {
            background-color: $green-dark;
          }

          &.negative {
            background-color: $red-dark;
          }
        }
      }

      &.portfolio-status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: rgba($neutral-lowest, 0.2);

        .icon {
          font-size: rem-calc(30);
          text-align: center;
          min-width: rem-calc(40);
        }
      }
    }
  }

  .uppercase-label {
    font-weight: normal;
    color: tint($green, 50%);
  }

  .values {
    .uppercase-label {
      line-height: 1;
    }

    p {
      margin-bottom: rem-calc(15);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// FOOTER
footer.app {
  *::selection {
    background-color: #2c3e50;
  }

  background-color: transparent;
  color: $black;
  min-height: rem-calc(40);
  padding: rem-calc(50 60 30);
  margin-top: $column-gutter;

  .row.bottom{
    justify-content: flex-end;
  }
}

.FooterLogoSection {
  display: block;
  position: relative;
  padding-right: $column-gutter * 2;

  &:after {
    @media #{$medium-up} {
      background-color: $neutral-low;
      content: ' ';
      display: block;
      height: 100%;
      position: absolute;
      right: $column-gutter / 2;
      top: 0;
      transform: rotate(180deg);
      width: rem-calc(1);
    }
  }

  &__image {
    background: url('/images/azimut-footer-logo-sgr.png');
  }

  &__image2 {
    background: url('/images/azimut-footer-logo-afi.png');
  }

  &__image,
  &__image2 {
    background-size: cover;
    height: rem-calc(55);
    width: rem-calc(156);
    margin-bottom: rem-calc(15);
  }

  &__contacts ul {
    list-style: none;
    color: $neutral-medium-high;
    margin-left: 0;
    font-size: rem-calc(14);
  }
}

.FooterText {
  font-size: rem-calc(12);
  color: $neutral-medium-high;

  @media #{$medium-up} {
    margin-top: rem-calc(70);
  }

  &__second-paragraph {
    margin-top: rem-calc(10);
  }
}

.main-content {
  padding: rem-calc(10 0);

  height: auto;
  min-height: 100%;

  @media #{$large-up} {
    padding: 0;
  }
}

.datepicker {
  position: absolute;
}

.widget {
  max-width: 100%;
}

// .tabbed-widget-group {
// 	.widget-tabs .tab-title {
// 		@include tab-title($bg: $tt-gray-dark, $bg-hover: darken($tt-gray-dark, 5%));
// 	}
// }

.cart-cta {
  .label {
    position: relative;
    background-color: $neutral-lowest;
    color: $primary-color;
    margin-left: rem-calc(5);
    border-radius: 50%;
    padding-left: rem-calc(6);
    padding-right: rem-calc(6);
    top: -1px;

    .style_Z & {
      color: $style-z;
    }

    .style_A & {
      color: $style-a;
    }

    .style_Y & {
      color: $style-y;
    }
  }
}

// client navigation on home page
nav.client-nav {
  width: 100%;
  padding: 0 0 2rem 0;
  box-shadow: 0 1px 3px rgba($black, 0.2);
  margin-bottom: $column-gutter;

  .widget {
    box-shadow: none;
  }

  ul {
    margin: 0 auto;
    text-align: right;

    li {
      display: inline-block;
      margin: 0;

      &:last-child {
        margin-right: $column-gutter;
      }

      a {
        font-family: Roboto, sans-serif;
        font-size: rem-calc(14);
        padding: rem-calc(7 3);
      }
    }
  }
}
