// Login widget
.widget-login {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow-y: auto;
	background-color: $neutral-higher;
	color: $neutral-lowest;
	padding: rem-calc(30 20 20 20);
	@include single-transition(padding);

	@media #{$medium-up} {
		padding-top: rem-calc(150);
	}

	@media #{$medium-landscape-up} {
		padding-top: rem-calc(80);
	}

	@media #{$large-up} {
		padding-top: rem-calc(120);
	}

	.widget {
		background: none;
		margin: 0;
		padding: 0;
		border: none;
		position: relative;
	}

	form {
		display: block;
		position: relative;
		width: 100%;
		max-width: rem-calc(400);
		margin: 0 auto;
    text-align: center;

		.logo-wrapper {
			text-align: center;
			margin-bottom: rem-calc(40);

			.az-login-logo {
				display: inline-block;
				@include sprite-2x($az-login-logo, $az-login-logo-2x);
			}
		}
	}

	input[type='text'] {
		margin-bottom: rem-calc(10);
	}

	input[type='text'],
	input[type='password'] {
    background-color: $white !important;
    box-shadow: none !important;
    flex: 1 0 100% !important;
    width: 100% !important;
    padding: ($column-gutter / 2) 0 !important;
    margin-bottom: ($column-gutter) !important;
    color: $primary-color !important;
    border: 1px solid $neutral-medium-low !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;

		&:focus {
      border: 1px solid $mood-good !important;
      border-top: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;
		}

    &::placeholder{
      color: $neutral-medium-high !important;
    }

		&.error {
			//margin-bottom: rem-calc(5);
      color: $mood-bad !important;
      border: 1px solid $mood-bad !important;
      border-top: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;

      &::placeholder{
        color: $mood-bad !important;
      }
		}
	}

	.error-text {
		font-size: rem-calc(14);
		margin-bottom: rem-calc(20);
	}

	.micro-text {
		margin-top: 4em;
		font-size: 0.75em !important;
	}

	.login-submit {
    background-color: $primary-color;
		font-family: $primary-font;
		font-size: rem-calc(14);
		font-weight: 300;
    width: 140px;
    padding: ($gutter) ($gutter * 2);

    &:hover{
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2)
    }

		&:active {
			background-color: $primary-color;
			box-shadow: none;
			background-image: none;

			.style_Z & {
				background-color: $style-z;
			}

			.style_A & {
				background-color: $style-a;
			}

			.style_Y & {
				background-color: $style-y;
			}
		}
	}

	.alert-box {
		margin-top: rem-calc(20);
	}

	a.registration,
	a.credential {
		font-size: rem-calc(14);
		text-decoration: none;
    color: $mood-good !important;
	}

	button.cancel {
		background-color: $neutral-high;
	}

	.goBack {
		display: inline-block;
		margin-bottom: $column-gutter / 2;
		font-size: 1.4rem;
    color: $mood-good !important;
    text-decoration: none;
		
		&::before {
			content: '< ';
			font-size: 1.4rem;
		}
	}
}

.pinfield {
	-webkit-text-security: disc;
}
