// WIDGET TABS
.tabbed-widget-group {
	margin-bottom: $column-gutter;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

	.widget-tabs {
		@include show-for-medium-up(flex);
		@include tabs;
		background-color: $neutral-lowest;
    border-radius: 4px 4px 0 0;

		.tab-title {
			font-size: rem-calc(14);
			margin: 0;
			min-height: rem-calc(50);
			padding: rem-calc(15 20);
			text-transform: uppercase;
			transition: border-bottom 300ms;
			letter-spacing: 1.4px;
      font-weight: 500;
      border-bottom: 2px solid transparent;
			
			a {
				color: #033A68;
        opacity: 0.7;
				transition: color 300ms;
			}

			&.active {
				
				a {
          opacity: 1;
				}
				
				border-bottom: 2px solid #033A68;
			}

      &:hover{
        background-color: rgba(3, 58, 104, 0.1);
        border-bottom: 2px solid #033A68;
        a{
          opacity: 1;
        }
      }
		}
	}

	.widget-tabs-content {
    border-radius:  0 0 4px 4px;

		@media #{$medium-up} {
			@include tabs-content;
      border-radius:  0 0 4px 4px;
		}

		.widget {
			@media #{$medium-up} {
				margin: 0;
				box-shadow: none;

				.panel-body {
					padding: 0;
				}
			}

			.panel-head {
				@include hide-for-medium-up;
			}
		}

		.widget-tabbed {
			@media #{$medium-up} {
				display: none;

				&.active {
					display: block;
				}
			}
		}
	}
}

.hiddentab {
	display: none;
}

.visibletab {
	display: block;
}
