// Alert boxes
.alert-box {
	position: relative;
	display: block;
	margin-bottom: $column-gutter;
	padding: rem-calc(10 30 10 15);
	font-size: rem-calc(14);
	font-weight: 400;
	line-height: 1.4;
	border-radius: $global-radius;
	color: white;
	background-color: $alert-info-color;

	&.success {
		background-color: $alert-success-color;
	}

	&.warning {
		background-color: $alert-warning-color;
	}

	&.error {
		background-color: $alert-error-color;
	}

	.alert-icon {
		position: relative;
		top: rem-calc(-2);
		color: white;
		font-size: rem-calc(16);
		margin-right: rem-calc(7);
	}

	.close {
		position: absolute;
		top: $column-gutter / 2;
		right: $column-gutter / 2;
		color: white;
		font-size: rem-calc(16);
		line-height: 1;
		font-weight: 400;
		opacity: 0.75;
		@include single-transition(opacity);

		&:hover {
			opacity: 1;
		}
	}
}

// Info message
.info-message {
	.message {
		font-size: rem-calc(14);
		color: $neutral-medium-high;
	}
}

// Courtesy message (es.: no data to display)
.general-courtesy-message {
	text-align: center;

	.icon {
		font-size: rem-calc(75);
		color: $tt-gray-dark;
		margin-bottom: rem-calc(20);

		@include mediaeq('medium-landscape') {
			font-size: rem-calc(100);
		}
	}

	.message {
		font-size: rem-calc(16);
		font-weight: 300;
		color: $neutral-medium-high;
		margin: 0;

		@include mediaeq('medium-landscape') {
			font-size: rem-calc(24);
		}
	}
}

// Message Panel: pannello con messaggi (messaggio generico, di errore, di success)
.message-panel {
	@include panel;
	text-align: center;
	padding: rem-calc(20);

	@include mediaeq('medium') {
		padding: rem-calc(40);
	}

	@include mediaeq('large') {
		padding: rem-calc(40 60);
	}

	.heading-icon {
		font-size: rem-calc(120);
		color: $neutral-low;
		margin-bottom: $column-gutter;
	}

	.title {
		font-size: $h2-font-size - $h2-font-reduction;
		color: $neutral-medium-high;
		margin-bottom: $column-gutter / 2;
		line-height: 1.2;

		@include mediaeq('medium') {
			font-size: $h2-font-size;
		}
	}

	.message {
		color: $neutral-medium-low;
	}

	*:last-child {
		margin-bottom: 0;
	}

	&.success {
		.heading-icon {
			color: $green-light;
		}
	}

	&.fatal-error {
		.heading-icon {
			color: $red;
		}
	}
}

// Our ICON webfont
.icon {
	position: relative;
	top: rem-calc(-1);

	&.large {
		font-size: 1.5em;
		top: rem-calc(-3);
	}

	&.margin-left,
	&.margin-right {
		margin-left: 0.3em;
	}

	&.success {
		color: $green-light;
	}
}

.button .icon,
button .icon {
	vertical-align: text-bottom;
}

// Foundation Dropdown
.f-dropdown {
	box-shadow: $default-box-shadow;
	border-radius: $global-radius;
	animation-duration: 0.3s;

	&.open {
		animation-name: fadeInDown;
	}

	> li {
		font-size: rem-calc(13);
		@include single-transition(background-color);

		@media #{$medium-up} {
			font-size: rem-calc(16);
			line-height: 3;

			a {
				padding: 0 $gutter * 1.5;
			}
		}

		&.title {
			color: $neutral-medium-high;
			font-size: rem-calc(11);
			padding: rem-calc(5 10);
			border-bottom: 1px solid $neutral-low;
			cursor: default;
			// background: white;

			@media #{$medium-up} {
				font-size: rem-calc(12);
				padding: 0 $gutter * 1.5;
			}
		}

		&.view-all {
			> a {
				text-align: center;
				color: $neutral-lowest;
				background-color: $neutral-medium-high;
				font-size: rem-calc(12);
				font-weight: 500;
				text-transform: uppercase;
				padding: rem-calc(8);
				@include single-transition(background-color);

				&:hover {
					background-color: $anchor-font-color-hover;
				}
			}
		}
	}
}

// Pagination
// ------------------------------------
ul.pagination {
	margin-bottom: 0;
	$pagination-color: $neutral-lower;
	$pagination-color-hover: $primary-color;
	$pagination-active-color: $green-light;
	@include no-bullet;
	@include clearfix;

	li {
		@include square(rem-calc(30));
		display: inline-block;
		margin-right: rem-calc(5);

		a {
			@include square(100%);
			background-color: transparent;
			display: inline-block;
			line-height: rem-calc(30);
			text-align: center;
			vertical-align: middle;
			font-size: rem-calc(16);
			transition: color $transition-speed $transition-ease;
			// font-size: rem-calc(12);
			// transition: background-color $transition-speed $transition-ease, color $transition-speed $transition-ease;
		}

		&:hover a,
		&.current a {
			color: $primary-color;

			.style_Z & {
				color: $style-z;
			}

			.style_A & {
				color: $style-a;
			}

			.style_Y & {
				color: $style-y;
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&.pagination-center {
		text-align: center;
	}
}

// Uppercase label
.uppercase-label {
	text-transform: uppercase;
	font-size: rem-calc(12);
	font-weight: 500;
	color: $neutral-medium-high;
	margin: 0;

	&.white {
		color: $neutral-lowest;
	}

	&.light {
		color: $neutral-medium-low;
	}

	&.dark {
		color: $neutral-high;
	}

	&.thin {
		font-weight: 300;
	}

	&.thick {
		font-weight: 700;
	}
}

// Block: icon + text
.icon-text-block {
	$icon-wrap-size-small: rem-calc(40);
	$icon-wrap-size: rem-calc(48);
	display: flex;
	flex-direction: row;

	.icon-wrap {
		display: flex;
		flex-basis: $icon-wrap-size-small;
		min-width: $icon-wrap-size-small;
		@include square($icon-wrap-size-small);
		background-color: $purple;
		border-radius: $global-radius;
		color: $neutral-lowest;
		align-items: center;
		justify-content: center;

		@include mediaeq('medium') {
			flex-basis: $icon-wrap-size;
			min-width: $icon-wrap-size;
			@include square($icon-wrap-size);
		}

		&.rounded {
			border-radius: 50%;
		}

		.icon {
			font-size: rem-calc(16);

			@include mediaeq('medium') {
				font-size: rem-calc(22);
			}
		}
	}
}

// Wrapping box
.wrapping-box {
	border: 1px solid $neutral-low;
	margin-bottom: $column-gutter;

	.box-item {
		border-bottom: 1px solid $neutral-low;
		padding: $column-gutter $column-gutter / 2;

		@include mediaeq('medium') {
			padding: $column-gutter;
		}

		.title {
			font-size: $h4-font-size - $h4-font-reduction;
			font-weight: normal;
			margin-bottom: 0;

			@include mediaeq('medium') {
				font-size: $h4-font-size;
			}
		}

		p.note {
			font-size: rem-calc(14);
			color: $neutral-medium-high;
			margin: 0;
		}

		p.value {
			font-size: rem-calc(21);
			margin: rem-calc(0 0 5);

			@include mediaeq('medium') {
				margin: 0;
			}
		}
	}

	.cta-box {
		background-color: $neutral-lower;
		padding: $column-gutter $column-gutter / 2;

		@include mediaeq('medium') {
			padding: $column-gutter;
		}

		.button {
			margin: 0;
		}
	}
}

// Special Input - da spostare nel master
.input-with-text-icon {
	position: relative;
	margin-bottom: $column-gutter;

	input {
		padding-right: rem-calc(80);
		margin-bottom: 0;
	}

	span {
		position: absolute;
		top: 50%;
		right: rem-calc(20);
		transform: translateY(-50%);
		user-select: none;
		color: $neutral-medium-low;
	}
}

// Custom radios & checkboxes
label.custom {
	position: relative;
	display: inline-block;
	// margin-bottom: $form-spacing

	span {
		pointer-events: none;
	}

	input[type='checkbox'],
	input[type='radio'] {
		position: absolute;
		visibility: hidden;
		user-select: none;
		left: -99999px;
	}

	%custom-input-base {
		display: inline-block;
		position: absolute;
		left: 0;
		top: 0;
		background-color: $neutral-lowest;
		border: 1px solid $neutral-medium-low;
		pointer-events: none;

		&.vertical-align {
			top: 50%;
			@include translateY(-50%);
		}

		+ span {
			display: inline-block;
			padding-left: rem-calc(30);
		}
	}

	.custom-radio {
		@extend %custom-input-base;
		@include rounded(rem-calc(20));

		&:after {
			content: '';
			display: block;
			background-color: $primary-color;
			position: absolute;
			@include rounded(rem-calc(10));
			top: rem-calc(4);
			left: rem-calc(4);
			transform: scale(0);
			transition: all 0.2s linear;

			.style_Z & {
				background-color: $style-z;
			}

			.style_A & {
				background-color: $style-a;
			}

			.style_Y & {
				background-color: $style-y;
			}
		}

		&.checked:after {
			transition: all 0.3s cubic-bezier(0, 0.9, 0.7, 1.7);
			transform: scale(1, 1);
		}
	}

	.custom-checkbox {
		@extend %custom-input-base;
		@include square(rem-calc(20));
		border-radius: rem-calc(5);

		svg {
			display: inline-block;
			position: relative;

			path {
				stroke-dasharray: 100;
				stroke-dashoffset: 100;
				stroke: $primary-color;
				stroke-width: 0;
				transition: all 0.2s ease-in-out;
			}
		}

		.style_Z & {
			svg path {
				stroke: $style-z;
			}
		}

		.style_A & {
			svg path {
				stroke: $style-a;
			}
		}

		.style_Y & {
			svg path {
				stroke: $style-y;
			}
		}

		&.checked svg path {
			stroke: $primary-color;
			stroke-dasharray: 100;
			stroke-dashoffset: 0;
			stroke-width: 14;
		}
	}
}

.modal {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: -1;
	opacity: 0;
	@include single-transition(opacity, 0.3s);
	background-color: rgba(black, 0.8);
	backface-visibility: hidden;
	overflow-y: auto;

	&.open {
		display: block;
		z-index: 99999;
		opacity: 1;

		.wrapper {
			animation: wrapperFadeIn 0.75s;

			@include mediaeq('medium') {
				animation: bounceInDown 0.75s;
			}
		}
	}

	.wrapper {
		display: block;
		position: relative;
		margin: 0 auto;
		padding: 0;
		width: 100%;
		height: 100%;
		background-color: $neutral-lowest;
		max-width: 800px;

		@include mediaeq('medium') {
			height: auto;
			padding: $column-gutter;
			margin: rem-calc(100) auto 0 auto;
			background-color: transparent;
		}
	}

	.modal-close {
		position: absolute;
		display: inline-block;
		top: rem-calc(10);
		right: rem-calc(10);
		font-size: rem-calc(30);
		line-height: 0.5;
		color: $neutral-higher;
		margin: 0;
		padding: rem-calc(5);
		opacity: 1;

		@include mediaeq('large') {
			@include single-transition(opacity);
			opacity: 0.5;

			&:hover {
				opacity: 1;
			}
		}
	}

	.content {
		background-color: $neutral-lowest;
		color: $black;
	}

	.heading {
		position: relative;
		padding: rem-calc(30 35 30 20);
		background-color: $primary-color;
		color: $neutral-lowest;

		.style_Z & {
			background-color: $style-z;
		}

		.style_A & {
			background-color: $style-a;
		}

		.style_Y & {
			background-color: $style-y;
		}

		.modal-close {
			color: $neutral-lowest;
		}

		.icon {
			margin-right: rem-calc(15);
		}

		.modal-title {
			color: $neutral-lowest;
			margin: 0;
		}
	}

	.modal-body {
		padding: $column-gutter;
	}
}

.modal.fund-documents {
	.document-list {
		@include no-bullet;
		margin: 0;

		.item-wrap {
			margin-bottom: $column-gutter;

			&:hover {
				.document-icon {
					background-color: $blue;
				}

				.description {
					color: $neutral-high;
				}
			}
		}

		.document-icon {
			$document-icon-size: rem-calc(50);
			@include rounded($document-icon-size);
			line-height: $document-icon-size * 0.95;
			background-color: $primary-color;
			text-align: center;
			margin-right: rem-calc(10);
			@include single-transition(background-color);

			.style_Z & {
				background-color: $style-z;
			}

			.style_A & {
				background-color: $style-a;
			}

			.style_Y & {
				background-color: $style-y;
			}

			.icon {
				color: $neutral-lowest;
				font-size: rem-calc(20);
			}
		}

		.document-name {
			font-size: rem-calc(16);
			font-weight: 500;
			margin: 0;

			@include mediaeq('medium') {
				font-size: rem-calc(21);
			}
		}

		.description {
			font-size: rem-calc(12);
			color: $neutral-medium-high;
			margin: 0;
			@include single-transition(color);

			@include mediaeq('medium') {
				font-size: rem-calc(14);
			}
		}
	}
}

// Tooltip
.tooltip {
	top: 0;
	left: 0;
	pointer-events: none;
	opacity: 0;
	position: absolute;
	z-index: 999;
	background-color: $black;
	color: $neutral-lowest;
	font-weight: normal;
	padding: rem-calc(5 10);
	font-size: rem-calc(14);
	@include translateY(rem-calc(0));

	&:after {
		@include css-triangle(rem-calc(6), $black, top);
		position: absolute;
		bottom: rem-calc(-12);
	}

	&.open {
		@include transition(opacity 0.2s ease-out 0.5s, transform 0.3s ease-out 0.5s);
		opacity: 0.9;
		@include translateY(rem-calc(-10));
	}
}

// Content-dropdown
.content-droptip {
	$droptip-triangle-color: $neutral-lowest;
	display: none;
	z-index: 10;
	border-radius: $global-radius;
	transition: opacity 150ms ease-out;
	// @include single-transition(opacity);

	&.over {
		@include translateY(rem-calc(-10));

		&:after {
			@include css-triangle(rem-calc(10), $droptip-triangle-color, top);
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			border-bottom-width: 0;
		}
	}

	&.under {
		@include translateY(rem-calc(10));

		&:after {
			@include css-triangle(rem-calc(10), $droptip-triangle-color, bottom);
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: rem-calc(-10);
			border-top-width: 0;
		}
	}

	&.right {
		@include translateX(rem-calc(10));

		&:after {
			@include css-triangle(rem-calc(10), $droptip-triangle-color, right);
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: rem-calc(-10);
			border-left-width: 0;
		}
	}

	&.left {
		@include translateX(rem-calc(-10));

		&:after {
			@include css-triangle(rem-calc(10), $droptip-triangle-color, left);
			position: absolute;
			top: 50%;
			right: rem-calc(-10);
			transform: translateY(-50%);
			border-right-width: 0;
		}
	}

	&.overLeft {
		@include translateY(rem-calc(-10));
		@include translateX(rem-calc(40));

		&:after {
			@include css-triangle(rem-calc(10), $droptip-triangle-color, top);
			position: absolute;
			right: rem-calc(2);
			border-bottom-width: 0;
		}
	}

	&.overRight {
		@include translateY(rem-calc(-10));
		@include translateX(rem-calc(-40));

		&:after {
			@include css-triangle(rem-calc(10), $droptip-triangle-color, top);
			position: absolute;
			left: rem-calc(2);
			border-bottom-width: 0;
		}
	}

	&.underLeft {
		@include translateY(rem-calc(10));
		@include translateX(rem-calc(40));

		&:after {
			@include css-triangle(rem-calc(10), $droptip-triangle-color, bottom);
			position: absolute;
			right: rem-calc(2);
			top: rem-calc(-10);
			border-top-width: 0;
		}
	}

	&.underRight {
		@include translateY(rem-calc(10));
		@include translateX(rem-calc(-40));

		&:after {
			@include css-triangle(rem-calc(10), $droptip-triangle-color, bottom);
			position: absolute;
			left: rem-calc(2);
			top: rem-calc(-10);
			border-top-width: 0;
		}
	}
}

// Datepiker
.datepicker-days table,
.datepicker-months table,
.datepicker-years table {
	background-color: $primary-color;
	border-top: none;
	margin-bottom: 0;

	.style_Z & {
		background-color: $style-z;
	}

	.style_A & {
		background-color: $style-a;
	}

	.style_Y & {
		background-color: $style-y;
	}

	thead {
		background-color: $primary-color;

		.style_Z & {
			background-color: $style-z;
		}

		.style_A & {
			background-color: $style-a;
		}

		.style_Y & {
			background-color: $style-y;
		}

		tr:first-child th {
			color: $neutral-lowest;
			text-align: center;

			&:nth-child(2) {
				// pointer-events: none
			}

			&.prev,
			&.next {
				cursor: pointer;
			}
		}

		tr:nth-child(2) th {
			font-weight: normal;
			color: $primary-color;

			.style_Z & {
				color: $style-z;
			}

			.style_A & {
				color: $style-a;
			}

			.style_Y & {
				color: $style-y;
			}
		}
	}

	tbody {
		background-color: $neutral-lowest;

		tr td .day {
			text-align: center;
			cursor: pointer;

			&.old,
			&.new {
				color: $neutral-medium-low;
				cursor: initial;
				pointer-events: none;
			}

			&:hover {
				background-color: $primary-color;
				color: white;

				.style_Z & {
					background-color: $style-z;
				}

				.style_A & {
					background-color: $style-a;
				}

				.style_Y & {
					background-color: $style-y;
				}
			}
		}

		tr td span {
			&.month,
			&.year {
				width: 33%;
				display: inline-block;
				text-align: center;
				padding: rem-calc(10 0);
				margin: rem-calc(5 0);
				cursor: pointer;

				&.disabled {
					color: $neutral-medium-low;
					cursor: initial;
					pointer-events: none;
				}
			}

			&:hover {
				background-color: $primary-color;
				color: white;

				.style_Z & {
					background-color: $style-z;
				}

				.style_A & {
					background-color: $style-a;
				}

				.style_Y & {
					background-color: $style-y;
				}
			}
		}
	}

	tfoot {
		display: none;
	}
}

// Video
.flex-video {
	margin-bottom: 0;
}
