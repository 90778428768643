.widget-user-settings-container {
	flex-grow: 1;
}

.WSettings {
	border-radius: rem-calc(4);
	//margin: 0 ($column-gutter * -1) ($column-gutter * -1);
	//padding: 0 ($column-gutter) ($column-gutter);
	padding: $column-gutter;
	position: relative;
	overflow: hidden;

	&__container {
		@media #{$medium-up} {
			&.ng-leave {
				animation: fadeOutUp $transition-speed $transition-ease;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				padding: 0 ($column-gutter) ($column-gutter);
			}

			&.ng-enter {
				animation: fadeInDown $transition-speed $transition-ease ($transition-speed * 1.5);
				opacity: 0;
			}
		}
	}

	&__userTitle {
		align-items: center;
		display: flex;
		margin: ($column-gutter * 0.5) ($column-gutter * -0.5) ($column-gutter);

		@media #{$medium-up} {
			margin: 0 ($column-gutter * -0.5) ($column-gutter / 2);
		}
	}

	&__image {
		@extend .icon;
		@extend .icon-key;

		color: $primary-color;
		font-size: rem-calc(36);
		padding: 0 ($column-gutter / 2);

		@media #{$medium-up} {
			font-size: rem-calc(48);
		}

		@media #{$medium-landscape-up} {
			font-size: rem-calc(56);
		}

		.style_Z & {
			color: $style-z;
		}

		.style_A & {
			color: $style-a;
		}

		.style_Y & {
			color: $style-y;
		}
	}

	&__userName {
		font-size: rem-calc(18);
		line-height: rem-calc(24);
		color: $neutral-high;
		padding: 0 ($column-gutter / 2);
		flex: 1 1 auto;

		@media #{$medium-up} {
			font-size: rem-calc(24);
			line-height: rem-calc(30);
		}

		@media #{$medium-landscape-up} {
			font-size: rem-calc(38);
			line-height: rem-calc(46);
		}
	}

	&__subtitle {
		font-size: rem-calc(18);
		line-height: rem-calc(20);
		color: $neutral-high;
		padding: 0;
		flex: 1 1 auto;
	}

	&__subtitle2 {
		font-size: rem-calc(18);
		line-height: rem-calc(20);
		color: $neutral-high;
		padding: 0;
		flex: 1 1 auto;
		margin-top: $column-gutter;
	}

	&__infos {
		display: flex;
		flex-wrap: wrap;
		margin: 0 ($column-gutter * -0.5);
	}

	&__info {
		flex: 1 0 100%;
		padding: 0 ($column-gutter * 0.5);
		margin-bottom: $column-gutter;
		margin-top: $column-gutter;

		@media #{$medium-up} {
			flex: 1 0 50%;
		}

		@media #{$medium-landscape-up} {
			margin-bottom: $column-gutter;
			margin-top: $column-gutter;
		}
	}

	&__label {
		font-size: rem-calc(12);
		line-height: rem-calc(12);
		color: $neutral-medium-high;
		font-variant: uppercase;
		text-transform: uppercase;

		&__error {
			color: $red;
		}
	}

	&__value {
		display: flex;
		align-items: center;

		&--editable {
			cursor: pointer;

			&::after {
				@extend .icon, .icon-pencil:before;
				color: $primary-color;
				margin-left: ($column-gutter / 2);

				.style_Z & {
					color: $style-z;
				}

				.style_A & {
					color: $style-a;
				}

				.style_Y & {
					color: $style-y;
				}
			}
		}
	}

	&__title {
		font-size: rem-calc(18);
		line-height: rem-calc(24);
		font-weight: 300;
		color: $neutral-high;
		margin-bottom: ($column-gutter);

		@media #{$medium-landscape-up} {
			font-size: rem-calc(24);
			line-height: rem-calc(30);
		}

		&--recap,
		&--notEditable {
			margin-bottom: 0;
		}
	}

	&__label {
		margin: 0;
	}

	&__row {
		position: relative;
		padding: 1.25rem;
		border-bottom: 1px solid #ebebeb;
	}

	&__radio {
		display: inline-block;
		margin-right: 0.625rem;
	}
	
	&__radio .custom {
		margin-bottom: 0;
	}

	&__radio .custom-radio {
		margin-top: 0.125rem;
	}
}
.button-settings {
	@extend button;
	margin: 1rem 0 0 0;
	padding: 0.8rem;

	&-cancel {
		background-color: $neutral-high !important;
	}
}
