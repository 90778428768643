.info-modal-dossier-selector {
  width: auto;
  max-width: rem-calc(800);
}

.DossierFilter {
  max-height: 100vh;
  overflow-y: auto;
  letter-spacing: 0.8px;

  @media #{$medium-up} {
    max-height: 80vh;
  }

  &__header {
    position: relative;
    padding-bottom: $gutter;

    .icon {
      color: $neutral-lowest;

      &.icon-docs {
        position: absolute;
        top: 50%;
        left: rem-calc(20);
        transform: translateY(-50%);
        font-size: rem-calc(40);
      }

      &.icon-close {
        font-size: rem-calc(22);
        top: rem-calc(-10);
        right: rem-calc(-10);
      }
    }

    .title {
      font-family: Roboto, sans-serif;
      text-align: center;
      font-size: rem-calc(18);
      font-weight: bold;
    }
  }

  &__row {
    position: relative;
    padding: rem-calc(20 55);
    border-bottom: 1px solid $neutral-low;
    color: $primary;

    &.is-open {
      background-color: $neutral-lowest;
    }
  }

  &__dossierIcon {
    display: inline-block;
    position: absolute;
    top: rem-calc(20);
    left: rem-calc(20);

    .icon {
      font-size: rem-calc(20);
    }

    &--green .icon {
      color: $green-dark;
    }

    &--red .icon {
      color: $red;
    }

    &--yellow .icon {
      color: $yellow;
    }
  }

  &__checkbox {
    display: inline-block;
    margin-right: rem-calc(10);

    .custom {
      margin-bottom: 0;
      color: $primary;
    }

    label.custom {
      .custom-checkbox {
        margin: 0;
        border-radius: rem-calc(3);

        &.checked {
          background-color: $primary;

          svg path {
            stroke: $white;
          }
        }
      }
    }

    .dossierNumber {
      font-size: rem-calc(16);
      font-weight: 400;
      padding: 0;
      margin-left: rem-calc(8);
    }
  }

  &__jointAccount {
    display: inline-block;
    margin-right: rem-calc(10);
    font-size: rem-calc(12);

    .icon {
      font-size: 10px;

      @include media-query(medium) {
        font-size: inherit;
      }
    }

    .co-owner {
      display: inline-block;
      margin-left: rem-calc(5);
      font-size: 10px;

      @include media-query(medium) {
        font-size: inherit;
      }
    }
  }

  &__toggler {
    position: absolute;
    top: rem-calc(20);
    right: rem-calc(20);
    font-size: rem-calc(16);
    color: $neutral-high;

    &--empty {
      display: none;
    }
  }

  &__nestedRow {
    padding-top: rem-calc(10);
    padding-left: rem-calc(30);
    position: relative;

    &::before {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: rem-calc(10);
      background-color: $neutral-medium-high;
    }

  }

  &__cta {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: rem-calc(20);

    @include media-query(large) {
      padding: rem-calc(0 40);
    }

    .close-modal.close-reveal-modal {
      flex: 0 0 auto;
      font-size: rem-calc(16);
      color: $primary;
      cursor: pointer;
      font-weight: normal;
      line-height: 1;
      position: relative;
      top: auto;
      right: auto;
      text-transform: uppercase;
    }

    .button {
      flex: 0 0 auto;
      margin: 0;
      font-family: Roboto, sans-serif;
    }
  }
}

iframe.scrollable {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}
