.widget-user-data-edit-container {
	flex-grow: 1;
}
.WUserData {
	border-radius: rem-calc(4);
	//margin: 0 ($column-gutter * -1) ($column-gutter * -1);
	//padding: 0 ($column-gutter) ($column-gutter);
	//padding: $column-gutter;
  padding: ($gutter * 0.2) ($gutter * 1.5) ($gutter * 1.5);
  position: relative;
	overflow: hidden;

	&--error {
		text-align: center;
	}

	&__container {
		&.ng-leave {
			animation: fadeOutUp $transition-speed $transition-ease;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			padding: 0 ($column-gutter) ($column-gutter);
		}

		&.ng-enter {
			animation: fadeInDown $transition-speed $transition-ease ($transition-speed * 1.5);
			opacity: 0;
		}
	}

	&__userTitle {
		align-items: center;
		display: flex;
		margin: ($column-gutter * 0.5)
						/*($column-gutter * -0.5)*/
            0
						($column-gutter);

		@media #{$medium-up} {
			margin: 0
              /*($column-gutter * -0.5)*/
              0
              ($column-gutter / 2);
		}
	}

	&__image {
		//@extend .icon;
		//@extend .icon-user;
		color: $primary-color;
		font-size: rem-calc(35);

		/*@media #{$medium-up} {
			font-size: rem-calc(48);
		}

		@media #{$medium-landscape-up} {
			font-size: rem-calc(56);
		}*/

		.style_Z & {
			color: $style-z;
		}

		// NOTE: moved to _style-a.scss
		// .style_A & {
		// 	color: $style-a;
		// }

		.style_Y & {
			color: $style-y;
		}
	}

	&__userName {
		font-size: rem-calc(18);
		line-height: rem-calc(24);
		color: $neutral-higher;
		padding: 0 ($column-gutter / 2);
		flex: 1 1 auto;

		@media #{$medium-up} {
			font-size: rem-calc(16);
			line-height: rem-calc(30);
		}

		@media #{$medium-landscape-up} {
			font-size: rem-calc(16);
			line-height: rem-calc(46);
		}
	}

	&__infos {
		display: flex;
		flex-wrap: wrap;
		margin: 0 ($column-gutter * -0.5) 35px ($column-gutter * -0.5);

	}

	&__info {
		flex: 1 0 100%;
		padding: 0 ($column-gutter * 0.5);
		margin-bottom: $column-gutter;

		@media #{$medium-up} {
			flex: 1 0 50%;
		}

		@media #{$medium-landscape-up} {
			margin-bottom: $column-gutter;
			margin-top: $column-gutter;
		}

	}

	&__label {
		font-size: rem-calc(12);
		line-height: rem-calc(12);
		color: $neutral-medium-low;
    margin-bottom: 7px;
		//text-transform: uppercase;
	}

	&__value {
		display: flex;
		align-items: center;
    font-size: rem-calc(16);
    float: left;

		&--editable {
			cursor: pointer;

			/*&::after {
				//@extend .icon, .icon-pencil:before;
				@extend .icon;
				@extend .owicon-createFilled:before;
				color: $primary-color;
				margin-left: ($column-gutter / 2);

				.style_Z & {
					color: $style-z;
				}

				// NOTE: moved to _style-a.scss
				// .style_A & {
				// 	color: $style-a;
				// }

				.style_Y & {
					color: $style-y;
				}
			}*/
		}
	}

  &__setPassword{
    color: $neutral-medium-high;
    margin-left: ($gutter*2.4);
    cursor: pointer;
    font-size: rem-calc(18);
    margin-top: -$gutter;

    &:hover{
      color: $neutral-higher;
    }
  }

	&__subTitle {
		color: $neutral-higher;
		flex: 1 0 100%;
		font-size: rem-calc(16);
		padding: 0 ($column-gutter / 2);
		margin-bottom: $column-gutter;

		@media #{$medium-landscape-up} {
			font-size: rem-calc(16);
			margin-bottom: 0;
		}

		&::before {
			content: ' ';
			display: block;
			//border-top: 1px solid $neutral-low;
			margin-bottom: $column-gutter*2;
		}
	}

  &__advancedEdit{
    margin: $column-gutter ($gutter * 1.5) ($gutter * 1.5) 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

	&__editRequest {
    font-family: 'Roboto', sans-serif;
		margin: 0;
    padding: ($gutter) ($gutter * 2.1);

    &:active {
      background-color: $primary-color;
      //box-shadow: $inner-shadow;
      box-shadow: none;
      background-image: none;
    }

    &:hover{
      background-color: $primary-color !important;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2)
    }

		&.is-disabled {
			pointer-events: none;
			background-color: $neutral-medium-high;
		}
	}

	&__editRequest.is-disabled {
		&:hover,
		&:focus {
			pointer-events: none;
			background-color: $neutral-medium-high;
			box-shadow: none;
		}
	}

	&__editing {
		@extend .row;
		//@extend .row.center;
    display: flex;
    flex-direction: row;
    justify-content: center;
	}

	&__editingCont {
		@extend .column;
		width: 100%;

		@media #{$medium-up} {
			width: 66.666%;
		}

		@media #{$medium-landscape-up} {
			width: 50%;
		}
	}

	&__title {
		font-size: rem-calc(18);
		line-height: rem-calc(24);
		font-weight: 300;
		color: $neutral-higher;
    margin-top: ($gutter * 1.5);
		margin-bottom: ($column-gutter * 1.5);

		@media #{$medium-landscape-up} {
			font-size: rem-calc(16);
			line-height: rem-calc(30);
		}

		&--recap,
		&--notEditable {
			margin-bottom: 0;
		}
	}

	&__form {
		margin-bottom: $column-gutter;
		display: flex;
		flex-wrap: wrap;
	}

	&__input,
	&__input[type] {
		box-shadow: none !important;
		flex: 1 0 100% !important;
		width: 100% !important;
		padding: ($column-gutter / 2) 0 !important;
		margin-bottom: ($column-gutter) !important;
		//border-radius: rem-calc(4);
    color: $primary-color !important;
		border: 1px solid $neutral-medium-low !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;

    &:disabled{
      opacity: 0.3 !important;
      background-color: transparent !important;
      border: 1px dotted $neutral-medium-low !important;
      border-top: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;
    }

    &:focus {
      border: 1px solid $mood-good !important;
      border-top: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;
    }

    &::placeholder{
      color: $neutral-medium-high !important;
    }

		&.has-error {
			border-color: $red;
		}

		&.ng-enter {
			animation: fadeInDown $transition-speed $transition-ease ($transition-speed * 1.5);
			opacity: 0;
		}
	}

	&__actions {
		display: flex;
    margin: ($column-gutter*1.5) ($gutter * 1.5) ($gutter * 1.5) 0;
		flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;

    @media #{$medium-up} {
      justify-content: center;
    }

    @media #{$medium-landscape-up} {
      justify-content: flex-end;
    }
	}

	&__action {
		margin: 0 ($column-gutter / 2);
    padding: ($gutter) ($gutter * 2.1);
    font-family: 'Roboto', sans-serif;
		//flex: 1 0 40%;
    min-width: 140px;

    &:last-child{
      @media #{$medium-landscape-up} {
        margin: 0 0 0 ($column-gutter / 2);
      }
    }

    &:active {
      background-color: $primary-color;
      //box-shadow: $inner-shadow;
      box-shadow: none;
      background-image: none;
    }

    &:hover{
      background-color: $primary-color !important;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2)
    }

    &:disabled{
      background-color: $neutral-medium-high !important;
      color: $neutral-higher;
      opacity: 0.3;

      &:hover{
        color: $neutral-higher;
        box-shadow: none;
      }
    }

		&--cancel {
      background-color: #fff !important;
      color: $primary-color !important;
      background-image: none !important;

      &:hover{
        background-color: rgba(0, 0, 0, 0.07) !important;
        box-shadow: none;
      }
		}

		&.is-disabled {
      background-color: $neutral-medium-high !important;
      color: $neutral-higher;
      opacity: 0.3;

      &:hover{
        color: $neutral-higher;
      }
      //opacity: 0.5;

			.style_Z & {
				background-color: $style-z;
			}

			// NOTE: moved to _style-a.scss
			// .style_A & {
			// 	background-color: $style-a;
			// }

			.style_Y & {
				background-color: $style-y;
			}
		}
	}

	&__msg {
		color: $neutral-high;
		margin-top: $column-gutter;
		font-size: rem-calc(12);
		display: block;

		@media #{$medium-up} {
			font-size: rem-calc(14);
		}

		&--error {
			color: $red;
			margin-top: 0;
		}

		&--notEditable {
			margin-top: 0;
			margin-bottom: $column-gutter;
		}

		&.ng-enter {
			animation: fadeInDown $transition-speed $transition-ease ($transition-speed * 1.5);
			opacity: 0;
		}
	}

	&__checkbox {
		padding: 0 ($column-gutter / 2);
		flex-grow: 1;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: ($column-gutter / 2);
	}
}

.CheckBox {
	$height: rem-calc(20);
	$width: rem-calc(20);

	align-items: center;
	color: $black;
	display: flex;

	&__container {
		display: inline-block;
		height: $height;
		position: relative;
		width: $width;
		margin-right: rem-calc(16);
	}

	&__input:not(.is-touch) {
		border-radius: 0;
		display: block;
		// height: calc(#{$height} + #{rem-calc(3)});
		height: ($height * 3);
		left: 0;
		opacity: 0;
		padding: 0;
		position: absolute;
		top: 0;
		// width: calc(#{$width} + #{rem-calc(3)});
		width: ($width * 3);
		z-index: 1;
		transform: translate(($width * -1.5), ($height * -1.5));
	}

	&__background {
		background-color: $neutral-lowest;
		border: 1px solid $neutral-medium-low;
		border-radius: rem-calc(3);
		height: $height;
		transition: background-color ($transition-speed / 3);
		width: $width;
		will-change: background-color;

		&.is-touch {
			display: none;
		}
	}

	&__input:checked ~ &__background {
		background-color: $primary-color;
		border-color: $primary-color;
		height: $height;
		transition: background-color $transition-speed;
		width: $width;

		.style_Z & {
			background-color: $style-z;
			border-color: $style-z;
		}

		// NOTE: moved to _style-a.scss
		// .style_A & {
		// 	background-color: $style-a;
		// 	border-color: $style-a;
		// }

		.style_Y & {
			background-color: $style-y;
			border-color: $style-y;
		}
	}

	&__svg {
		left: 0;
		max-height: 100%;
		max-width: 100%;
		position: absolute;
		top: 0;

		&.is-touch {
			display: none;
		}

		.CheckBox__path {
			stroke: $neutral-lowest;
			stroke-dasharray: 100;
			stroke-dashoffset: 100;
			stroke-width: 0;
			transition: stroke-dashoffset $transition-speed ($transition-speed * 0.3),
									stroke-width $transition-speed ($transition-speed * 0.3);
			will-change: stroke-dashoffset, stroke-width;
		}
	}

	&__input:checked ~ &__svg {
		.CheckBox__path {
			stroke-dashoffset: 0;
			stroke-width: 10;
		}
	}
	&__label {
		margin: 0;
	}
}
