// WIDGET USER DATA
.widget-user-data,
.widget-user-data-advanced,
.widget-user-data-header {
	box-shadow: none;

	.heading {
		color: $brown-light;
		margin-bottom: rem-calc(20);
		font-size: rem-calc(21);
		font-weight: 400;
		max-width: 100%;

		a {
			padding-left: 0 !important;
			word-wrap: break-word;

			@media #{$large-up} {
				word-wrap: normal;
			}
		}

		@include mediaeq('medium') {
			font-size: $h1-font-size;
			font-weight: 300;
		}

		.icon-chevron-right {
			@media #{$large-up} {
				display: none;
			}
		}

		.icon-chevron-down {
			display: none;

			@media #{$large-up} {
				display: inline-block;
			}
		}
	}

	.icon-text-block {
		p {
			word-wrap: break-word;
		}

		.icon-wrap {
			background-color: $brown-light;
		}
	}

	.account-info {
		.panel {
			@include panel-color($brown-light, false);

			.uppercase-label {
				color: tint($brown-light, 50%);
				font-weight: bold;
			}

			p {
				color: $neutral-lowest;
				font-size: $paragraph-font-size;
				font-weight: 300;
				margin: 0;

				@include mediaeq('large') {
					font-size: $h4-font-size;
				}

				// +mediaeq("xlarge")
				//   font-size: $h3-font-size
			}
		}
	}

	.edit-link {
		text-transform: initial;
		margin-left: rem-calc(5);
	}

	.flex-vertical-align {
		align-items: center;
	}

	.flex-1-auto {
		flex: 1 0 auto;
	}

	.edit-form {
		display: block;
		margin: 0;
		position: relative;

		@include mediaeq('medium') {
			max-width: 75%;
		}

		input {
			position: relative;
			margin: 0;
			padding-right: rem-calc(40);
		}

		button {
			position: absolute;
			right: rem-calc(10);
			top: 50%;
			@include translateY(-50%);
			border: none;
			border-radius: 0;
			background: none;
			padding: 0;
			margin: 0;
			color: $neutral-medium-high;
			line-height: 1;
			font-size: rem-calc(22);
			@include single-transition(color);

			&:hover {
				color: $anchor-font-color-hover;
			}
		}
	}
}

.widget-user-data {
	margin-left: rem-calc(15);
	background: none;
	margin-bottom: 0;

	@media #{$large-up} {
		margin-left: 0;
	}

	.panel-body {
		padding: 0;
	}

	.heading {
		margin-bottom: 0;
		color: $neutral-lowest;
		font-weight: 400;
		line-height: 1;

		span {
			font-size: rem-calc(14);

			@media #{$large-up} {
				font-size: rem-calc(12);
			}
		}
	}
}

.widget-user-data-header {
	margin-left: rem-calc(15);
	background: none;
	margin-bottom: 0;

	@media #{$large-up} {
		margin-left: 0;
	}

	.panel-head {
		display: none;
	}

	.panel-body {
		padding: 0;
	}

	.heading {
		font-family: Roboto, sans-serif;
		margin-bottom: 0;
		color: $primary;
		font-weight: 400;
		line-height: 1;

		span {
			font-size: rem-calc(14);

			@media #{$large-up} {
				font-size: rem-calc(14);
			}
		}
	}
}
