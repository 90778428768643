.PromoBanner {
	&__image {
		width: 100%;
	}
}

.ImageWrapper {
	@media #{$large-up} {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	
	img {
		max-width: 100%;
		width: 100%;
	}
}
