//@if $include-html-dwx-visibility-classes

@mixin visibility-mq-classes {
	@each $current-breakpoint in $dwx-breakpoint-queries {
		$current-name: nth($dwx-breakpoint-name, index($dwx-breakpoint-queries, $current-breakpoint));

		@media #{$current-breakpoint} {
			.show-for-#{$current-name}-up,
			.show-for-#{$current-name}-only {
				display: inherit !important;

				&.column,
				&.columns {
					display: block !important;
				}
			}

			.hide-for-#{$current-name}-up,
			.hide-for-#{$current-name}-only,
			.column.hide-for-#{$current-name}-up,
			.column.hide-for-#{$current-name}-only,
			.columns.hide-for-#{$current-name}-up,
			.columns.hide-for-#{$current-name}-only {
				display: none !important;
			}

			@each $current-breakpoint-name in $dwx-breakpoint-name {
				@if index($dwx-breakpoint-queries, $current-breakpoint) ==1 {
					.show-for-#{$current-breakpoint-name}-up,
					.show-for-#{$current-breakpoint-name}-only {

						@if $current-breakpoint-name==$current-name {
							display: inherit !important;

							&.column,
							&.columns {
								display: block !important;
							}
						} @else {
							display: none !important;
						}
					}

					.hide-for-#{$current-breakpoint-name}-only {
						@if $current-breakpoint-name==$current-name {
							display: none !important;
						} @else {
							display: inherit !important;

							&.column,
							&.columns {
								display: block !important;
							}
						}
					}
				} @else {
					@if $current-breakpoint-name==$current-name {
						$prev-name: nth($dwx-breakpoint-name, index($dwx-breakpoint-queries, $current-breakpoint) - 1);

						.hide-for-#{$prev-name}-only {
							display: inherit !important;

							&.column,
							&.columns {
								display: block !important;
							}
						}

						.show-for-#{$prev-name}-only,
						.column.show-for-#{$prev-name}-only,
						.columns.show-for-#{$prev-name}-only {
							display: none !important;
						}
					}
				}
			}
		}
	}
}

@mixin visibility-eq-classes {
	@each $current-breakpoint in $dwx-breakpoint-queries {
		$current-name: nth($dwx-breakpoint-name, index($dwx-breakpoint-queries, $current-breakpoint));

		.show-for-#{$current-name}-up,
		.show-for-#{$current-name}-only {
			@include mediaeq($current-name) {
				display: inherit !important;
			}

			&.column,
			&.columns {
				@include mediaeq($current-name) {
					display: block !important;
				}
			}
		}

		.hide-for-#{$current-name}-up,
		.hide-for-#{$current-name}-only,
		.column.hide-for-#{$current-name}-up,
		.column.hide-for-#{$current-name}-only,
		.columns.hide-for-#{$current-name}-up,
		.columns.hide-for-#{$current-name}-only {
			@include mediaeq($current-name) {
				display: none !important;
			}
		}

		@each $current-breakpoint-name in $dwx-breakpoint-name {
			@if index($dwx-breakpoint-queries, $current-breakpoint) ==1 {
				.show-for-#{$current-breakpoint-name}-up,
				.show-for-#{$current-breakpoint-name}-only {
					@include mediaeq($current-name) {
						@if $current-breakpoint-name==$current-name {
							display: inherit !important;

							&.column,
							&.columns {
								display: block !important;
							}
						} @else {
							display: none !important;
						}
					}
				}

				.hide-for-#{$current-breakpoint-name}-only {
					@include mediaeq($current-name) {
						@if $current-breakpoint-name==$current-name {
							display: none !important;
						} @else {
							display: inherit !important;
						}
					}

					&.column,
					&.columns {
						@include mediaeq($current-name) {
							display: block !important;
						}
					}
				}
			} @else {
				@if $current-breakpoint-name==$current-name {
					$prev-name: nth($dwx-breakpoint-name, index($dwx-breakpoint-queries, $current-breakpoint) - 1);

					.hide-for-#{$prev-name}-only {
						@include mediaeq($current-name) {
							display: inherit !important;
						}

						&.column,
						&.columns {
							@include mediaeq($current-name) {
								display: block !important;
							}
						}
					}

					.show-for-#{$prev-name}-only,
					.column.show-for-#{$prev-name}-only,
					.columns.show-for-#{$prev-name}-only {
						@include mediaeq($current-name) {
							display: none !important;
						}
					}
				}
			}
		}
	}

	//@if $include-legacy-query-classes
}

@include visibility-mq-classes;

@include visibility-eq-classes;

//@else
//  @if $include-element-query-classes
//    +visibility-eq-classes
//  @else
//    +visibility-mq-classes

.show-for-touch {
	display: none !important;
}

.hide-for-touch {
	display: inherit !important;
}

.touch .show-for-touch {
	display: inherit !important;
}

.touch .hide-for-touch {
	display: none !important;
}
