// Animations: animated base mixin
@mixin animated($duration: 1s) {
	@include animation-duration($duration);
	@include animation-fill-mode(both);
}

// Animation: bounceInDown
@keyframes bounceInDown {
	0%,
	60%,
	75%,
	90%,
	100% {
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}

	0% {
		opacity: 0;
		transform: translateY(-3000px);
	}

	60% {
		opacity: 1;
		transform: translateY(25px);
	}

	75% {
		transform: translateY(-10px);
	}

	90% {
		transform: translateY(5px);
	}

	100% {
		transform: none;
	}
}

// Animation: fadeIn
@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

// Animation: fadeInDown
@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

// Animation: fadeInUp
@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

// Animation: fadeInLeft
@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translateX(-20px);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

// Animation: fadeInRight
@keyframes fadeInRight {
	0% {
		opacity: 0;
		transform: translateX(20px);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

// Animation: fadeOutUp
@keyframes fadeOutUp {
	0% {
		opacity: 1;
		transform: translateY(0px);
	}

	100% {
		opacity: 0;
		transform: translateY(-20px);
	}
}

// Animation: fadeOutRight
@keyframes fadeOutRight {
	0% {
		opacity: 1;
		transform: translateX(0px);
	}

	100% {
		opacity: 0;
		transform: translateX(20px);
	}
}

//flipcard effect
@keyframes flipIn {
	0% {
		transform: rotateX(45deg);
	}

	100% {
		transform: rotate(0);
	}
}

@keyframes flipOut {
	0% {
		transform: rotateX(-45deg);
	}

	100% {
		transform: rotate(0);
	}
}

// Animation: wrapperFadeIn
@keyframes wrapperFadeIn {
	0% {
		opacity: 0;
		transform: translateY(30px);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

// Animation: rotate
@keyframes rotate {
	0% {
		transform: rotate(-360deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

// Animation: accordion

// Animation: fadeInUp
@keyframes accordion {
	0% {
		max-height: 0;
		overflow: hidden;
	}

	100% {
		max-height: 50vh;
		overflow: visible;
	}
}
