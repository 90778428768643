//@import "../../../c3/c3.css"

.widget-portfolio-summary {

	.azimut-table {
		margin-bottom: $gutter;
	}

	a.button {
		background-color: $blue;
	}

	a.portfolio-summary-action {
		display: block;
		font-size: rem-calc(14);
		font-weight: 500;
		padding: 0 rem-calc(20);
		text-align: right;
		text-transform: uppercase;
		width: 100%;
	}

	.tooltip-chart {
		background-color: $black;
		color: $neutral-lowest;
		font-weight: 400;
		padding: rem-calc(10);
		font-size: rem-calc(13);
		white-space: nowrap;
		line-height: 1.2;

		&:after {
			@include css-triangle(rem-calc(6), $black, bottom);
			position: absolute;
			top: rem-calc(-12);
		}
	}

	.donut-container {
		position: relative;

		.c3-chart-arc path {
			cursor: default !important;
		}

		.donut-total-portfolio-summary {
			color: $black;
			position: absolute;
			top: 50%;
			left: 50%;
			text-align: center;
			text-transform: uppercase;
			@include translate(-50%, -50%);

			.total-title {
				display: block;
				color: $neutral-medium-high;
				font-size: rem-calc(12);
			}

			.total-value {
				font-size: rem-calc(21);
				font-weight: 500;
				line-height: rem-calc(21);
			}

			.total-currency {
				display: block;
				line-height: 0.8rem;
				font-weight: 400;
				font-size: rem-calc(12);
			}
		}
	}

	.legend-container {
		letter-spacing: 0.8px;

		.legend-name {
			font-size: rem-calc(14);
			color: $black;
			font-weight: 400;
		}

		ul {
			@include no-bullet;
			margin: 0;
			padding: 0;

			li {
				border-left: 3px solid $primary-color;
				margin: rem-calc(10 0);
				padding: rem-calc(2 0 2 10);
				cursor: default;
				line-height: rem-calc(20);

				.style_Z & {
					border-left: 3px solid $style-z;
				}

				.style_A & {
					border-left: 3px solid $style-a;
				}

				.style_Y & {
					border-left: 3px solid $style-y;
				}

				.legend-name {
					display: block;
					font-size: rem-calc(14);
					color: $black;
					font-weight: 400;
				}

				.legend-value {
					font-size: rem-calc(13);
					color: $neutral-high;
				}

				.legend-currency {
					font-size: rem-calc(12);
					color: $neutral-medium-high;
					display: inline-block;
					margin-left: rem-calc(5);
				}
			}

			&:hover {
				li {
					opacity: 0.7;

					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}

	.PortfolioSummary {
		&__pirDisclaimer {
			margin: 0 0 $gutter $gutter;
			font-size: rem-calc(14);
		}
	}
}
