// DASHBOARD

.DashboardAdvisor {
	font-size: 16px;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;

	&__wrapper {
		padding: $column-gutter $column-gutter 0;

		&:last-child {
			padding-bottom: $column-gutter;
		}
	}

	&__emailLabel,
	&__addressLabel,
	&__phoneLabel {
		font-size: rem-calc(14);
		color: $neutral-medium-high;

		.icon {
			color: $primary-color;
			font-size: rem-calc(16);
			width: 16px;
			margin-right: $column-gutter / 2;
		}
	}

	&__emailData,
	&__addressData,
	&__phoneData {
		margin-left: calc(1rem + 16px);
		font-size: rem-calc(16);
	}
}