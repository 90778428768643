body {
	font-family: $primary-font;
}

.f-open-dropdown {
	a {
		font-size: 1em;
		color: $neutral-high;

		&::after {
			display: none;
		}
	}
}

// table.dataTable
//	 thead
//		 th
//			 border-bottom-color: $table-border-color
//			 border-bottom-width: 3px
//			 padding: rem-calc(10)
//			 font-weight: 600
//			 color: $black
//	 tbody
//		 tr.even
//			 background-color: $neutral-lowest
//		 tr.odd
//			 background-color: $table-even-row-bg
//		 td
//			 color: $gray-dark
//			 &.has-drilldown
//				 padding: 0
//				 > a
//					 display: block
//					 padding: $table-row-padding
//					 background-color: transparent
//					 background-image: url("/images/drilldown-triangle.png")
//					 background-repeat: no-repeat
//					 background-position: bottom right
//					 background-size: 8px 8px
//					 .touch &
//						 &:focus, &:active
//							 background-color: rgba(black, 0.05)
//	 &.no-footer
//		 border-bottom: none
//	 &.hover
//		 tbody
//			 tr
//				 .no-touch &
//					 +single-transition(background-color)
//				 &.odd:hover, &.even:hover
//					 background-color: darken($neutral-lower, 2%)
//	 tr.child ul
//		 font-size: rem-calc(12)
//	 tr.child > td.child
//		 padding-left: rem-calc(15) !important

.no-animation {
	animation: none !important;
	transition: none !important;
}

.label-title {
	color: $neutral-medium-high;
	font-size: rem-calc(11);
	// font-weight: bold;
	// text-transform: uppercase;
	// font-family: $base-sans-serif;
}

.label-correct {
	color: $green;
	font-size: rem-calc(12);
	// font-family: $base-sans-serif;

	.icon {
		font-size: rem-calc(14);
		display: inline-block;
		margin-right: rem-calc(5);
	}
}

ul.pagination {
	@include no-bullet;
	@include clearfix;

	> li {
		@include square(rem-calc(30));
		display: inline-block;
		margin-right: rem-calc(5);

		> a {
			display: inline-block;
			@include square(100%);
			line-height: rem-calc(30);
			text-align: center;
			vertical-align: middle;
			@include single-transition(background-color);
			font-weight: 500;
			background-color: transparent;
			color: $neutral-medium-low;
			font-size: rem-calc(16);
			// background-color: $neutral-lower;
			// font-size: rem-calc(12);
		}

		&.current {
			> a {
				color: $primary-color;

				.style_Z & {
					color: $style-z;
				}

				.style_A & {
					color: $style-a;
				}

				.style_Y & {
					color: $style-y;
				}
			}
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.pagination-center {
	text-align: center;
}

.custom-checkbox,
.custom-radio {
	background: $neutral-lowest;
	border: 1px solid $neutral-low;
	display: inline-block;
	text-align: center;
}

.custom-checkbox {
	@include square(rem-calc(25));
	border-radius: rem-calc(5);

	svg {
		display: inline-block;

		path {
			stroke-dasharray: 100;
			stroke-dashoffset: 100;
			stroke: $green;
			stroke-width: 0;
		}
	}

	//+animation(dash 5s linear alternate infinite)
	&.checked {
		svg path {
			@include transition(all 0.3s ease);
			stroke: $green;
			stroke-dasharray: 100;
			stroke-dashoffset: 0;
			stroke-width: 20;
		}
	}

	& ~ input[type='checkbox'] {
		visibility: hidden;
		left: -9999px;
	}
}

.custom-radio {
	@include square(rem-calc(26));
	border-radius: rem-calc(25);
	position: relative;

	&:after {
		content: ' ';
		display: block;
		background: $green;
		position: absolute;
		@include square(16px);
		border-radius: 16px;
		top: 4px;
		left: 4px;
		@include scale(0);
		@include transition(all 0.2s ease);
	}

	&.checked:after {
		@include transition(all 0.4s cubic-bezier(0, 1.095, 0.555, 2.55));
		@include scale(1);
	}

	& ~ input[type='radio'] {
		visibility: hidden;
		left: -9999px;
	}
}

//calendario
.datepicker {
	position: absolute;
	perspective: 1000px;
	text-align: center;
	max-width: rem-calc(250);
	border-left: 1px solid rgba($primary-color, 0.3);
	border-right: 1px solid rgba($primary-color, 0.3);
	min-height: rem-calc(277);
	background: $neutral-lowest;

	.style_Z & {
		border-left: 1px solid rgba($style-z, 0.3);
		border-right: 1px solid rgba($style-z, 0.3);
	}

	.style_A & {
		border-left: 1px solid rgba($style-a, 0.3);
		border-right: 1px solid rgba($style-a, 0.3);
	}

	.style_Y & {
		border-left: 1px solid rgba($style-y, 0.3);
		border-right: 1px solid rgba($style-y, 0.3);
	}

	&.datepicker-orient-bottom {
		&.datepicker-animate {
			@include animation(fadeInUp 0.4s);

			> div {
				@include animation(flipIn 0.2s);
			}
		}
	}

	&.datepicker-orient-top {
		border-bottom: 1px solid rgba($primary-color, 0.3);

		.style_Z & {
			border-bottom: 1px solid rgba($style-z, 0.3);
		}

		.style_A & {
			border-bottom: 1px solid rgba($style-a, 0.3);
		}

		.style_Y & {
			border-bottom: 1px solid rgba($style-y, 0.3);
		}

		&.datepicker-animate {
			@include animation(fadeInDown 0.4s);

			> div {
				@include animation(flipOut 0.2s);
			}
		}
	}

	table {
		margin-bottom: 0;
	}

	.dow {
		font-weight: bold;
		color: $primary-color;

		.style_Z & {
			color: $style-z;
		}

		.style_A & {
			color: $style-a;
		}

		.style_Y & {
			color: $style-y;
		}
	}

	.datepicker-switch,
	.prev,
	.next {
		text-align: center;
		background-color: $primary-color;
		color: $neutral-lowest;
		font-weight: bold;
		cursor: pointer;

		.style_Z & {
			background-color: $style-z;
		}

		.style_A & {
			background-color: $style-a;
		}

		.style_Y & {
			background-color: $style-y;
		}
	}

	.day {
		text-align: center;

		&.old,
		&.new {
			opacity: 0.5;
		}

		&:hover {
			color: $neutral-lowest;
			background: $primary-color;
			cursor: pointer;
			opacity: 1;

			.style_Z & {
				background: $style-z;
			}

			.style_A & {
				background: $style-a;
			}

			.style_Y & {
				background: $style-y;
			}
		}
	}

	.month,
	.year {
		display: inline-block;
		width: 25%;
		padding: 1.2rem 0.5rem;
		margin: 0.55rem 0;
		text-align: center;

		&:hover {
			color: $neutral-lowest;
			background: $primary-color;
			cursor: pointer;

			.style_Z & {
				background: $style-z;
			}

			.style_A & {
				background: $style-a;
			}

			.style_Y & {
				background: $style-y;
			}
		}
	}
}

.input-with-icon {
	@include input-with-icon;

	&.with-datepicker {
		@include input-with-icon($datepicker: true);
	}
}

//generic documents
%filter-bar-container {
	@include no-bullet;
	margin: 0;
	height: 50px;
	@include clearfix;

	@media #{$medium-up} {
		height: 100%;
	}

	> li {
		display: block;
		float: left;
		height: 100%;

		> a,
		> span {
			display: inline-block;
			height: 100%;

			&.expand {
				color: $primary-color;
				position: relative;
				padding-right: 1.2rem;

				.style_Z & {
					color: $style-z;
				}

				.style_A & {
					color: $style-a;
				}

				.style_Y & {
					color: $style-y;
				}

				&:before {
					@include css-triangle(rem-calc(5), $primary-color, top);
					content: '';
					display: inline-block;
					position: absolute;
					right: rem-calc(5);
					top: 50%;
					@include translateY(-10%);
					@include single-transition;

					.style_Z & {
						@include css-triangle(rem-calc(5), $style-z, top);
					}

					.style_A & {
						@include css-triangle(rem-calc(5), $style-a, top);
					}

					.style_Y & {
						@include css-triangle(rem-calc(5), $style-y, top);
					}
				}

				&:hover:before {
					@include css-triangle(rem-calc(5), $anchor-font-color-hover, top);
				}
			}
		}

		&.divider {
			width: 1px;
			margin: rem-calc(0 10);
			height: 100%;
			background-color: $divider-color-light;
		}
	}
}

.generic-documents {
	.filter-bar {
		// border-bottom: 1px solid $divider-color-light;

		ul.left {
			@extend %filter-bar-container;
			margin-left: rem-calc(10);

			> li {
				> a,
				> span {
					position: relative;
					overflow: hidden;
					padding: rem-calc(15 10);
					color: $neutral-medium-high;
					font-size: rem-calc(14);

					&:after {
						content: '';
						display: block;
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						height: rem-calc(3);
						background-color: $blue;
						opacity: 0;
						@include transform(translate(0, 100%) scale(0));
						@include single-transition;
					}
				}

				a:hover,
				a:active {
					color: $anchor-font-color-hover;
				}

				&.divider {
					border-right: 1px solid $divider-color-light;
				}

				&.active > a {
					color: $primary-color;

					.style_Z & {
						color: $style-z;
					}

					.style_A & {
						color: $style-a;
					}

					.style_Y & {
						color: $style-y;
					}

					&:after {
						opacity: 1;
						@include transform(none);
					}

					&:hover,
					&:active {
						color: $anchor-font-color-hover;

						&:after {
							background-color: $anchor-font-color-hover;
						}
					}
				}

				&.icon-wrapper {
					> a {
						padding: rem-calc(12 5);

						// icon: NEW
						&.new-icon {
							span.label {
								display: inline-block;
								padding: rem-calc(8);
								background-color: $neutral-medium-low;
								color: white;
								font-weight: 600;
								text-transform: uppercase;
								@include single-transition;
							}

							&:hover,
							&:active {
								span.label {
									background-color: $neutral-medium-high;
								}
							}

							&.active {
								span.label {
									background-color: $red;
								}
							}
						}

						// icon: FAV
						&.fav-icon {
							.icon {
								color: $neutral-medium-low;
								font-size: rem-calc(24);
								@include single-transition(color);
							}

							&:hover,
							&:active {
								.icon {
									color: $neutral-medium-high;
								}
							}

							&.active {
								.icon {
									color: $yellow;
								}
							}
						}
					}
				}
			}
		}

		ul.right {
			@extend %filter-bar-container;

			left: auto !important;
			right: 0;

			@media #{$medium-up} {
				height: auto;
				margin-right: 1rem;
			}

			> li {
				width: 100%;
				> a,
				> span {
					width: 100%;
					padding: rem-calc(15 10);
					color: $neutral-medium-high;

					.icon {
						margin: rem-calc((-2) -5);
						font-size: rem-calc(24);
						vertical-align: middle;
						color: $neutral-low;
						@include single-transition(color);

						&:hover,
						&:active {
							color: $neutral-medium-low;
						}
					}
				}

				> a.active {
					.icon {
						color: $neutral-medium-high;
					}
				}
			}
		}
	}

	.filter-bar-mobile {
		@include hide-for-medium-up;
		padding: rem-calc(10);

		.label-title {
			margin-bottom: rem-calc(10);
		}

		.button {
			margin: 0;
		}
	}

	.search-bar {
		form.right {
			position: relative;
			margin: 0;
			width: 100%;

			@media #{$medium-up} {
				width: rem-calc(300);
			}

			input.search-text {
				margin: 0;
				height: rem-calc(35);
				padding-right: 40px;
				padding-left: rem-calc(10);
				@include border-radius($global-radius);
				font-size: rem-calc(14);
			}

			button.submit {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: rem-calc(40);
				height: 100%;
				margin: 0;
				padding: 0;
				@include border-left-radius(0);
				@include border-right-radius($global-radius);
			}
		}
	}

	.empty-list {
		text-align: center;

		&.no-documents {
			@media #{$medium-up} {
				background-color: #fafbfc;
				border: 1px solid $divider-color-light;
				padding: rem-calc(50 0 30 0);
			}

			img {
				margin-bottom: rem-calc(30);
				max-width: 300px;
			}

			.button {
				margin: 0;
			}
		}

		&.searching {
			img {
				margin: rem-calc(50 0);
				max-width: 220px;
			}
		}

		.title {
			color: $neutral-medium-high;
			text-transform: uppercase;
			font-weight: normal;
			margin-bottom: rem-calc(20);
		}

		p {
			color: $neutral-medium-high;
			font-size: rem-calc(14);
			margin-bottom: rem-calc(40);
		}
	}

	.document-list-mobile {
		@include hide-for-medium-up;

		p.label-title {
			padding-bottom: rem-calc(5);
			border-bottom: 1px solid $divider-color-light;
			margin-bottom: rem-calc(10);

			span.label {
				@include label($padding: rem-calc(6), $bg: $neutral-low);
				margin: rem-calc(0 0 0 5);
			}
		}

		ul.list {
			@include no-bullet;
			padding-bottom: rem-calc(10);
			border-bottom: 1px solid $divider-color-light;
			margin: rem-calc(0 0 20 0);

			> li {
				background-repeat: no-repeat;
				background-position: 0 0;
				margin-bottom: rem-calc(10);
				min-height: rem-calc(40);

				// &.pdf {
				// }

				//+retinize("filetypes/pdf", "png", 32px, 40px)
				// &.doc {
				// }

				//+retinize("filetypes/doc", "png", 32px, 40px)
				// &.xls {
				// }

				//+retinize("filetypes/xls", "png", 32px, 40px)
				> a {
					display: block;
					padding-left: 40px;

					span {
						display: block;
						font-weight: 500;
						font-size: rem-calc(13);
						color: $neutral-higher;
						margin-bottom: rem-calc(3);
					}

					small {
						display: block;
						color: $neutral-medium-high;
					}
				}
			}
		}
	}

	.document-grid {
		margin: 0 - $column-gutter / 4;

		@media #{$medium-up} {
			padding-bottom: $gutter * 3;
		}

		ul.list {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			// @include block-grid(1);

			li {
				list-style: none;
				width: 100%;
				padding: 0 $gutter * 1.5;
			}

			@media #{$medium-up} {
				li {
					width: calc(100% / 3);
				}
			}

			@media #{$large-up} {
				li {
					width: 25%;
				}
			}
		}

		.doc-grid-item {
			display: block;
			position: relative;
			@include radius;
			overflow: hidden;
			background-color: lighten($neutral-lower, 1%);
			@include box-shadow(0 0 10px rgba($black, 0));
			min-height: 200px;

			@media #{$large-up} {
				min-height: 230px;
				box-shadow: 0 3px 6px rgba($black, 0.2);
			}

			.no-touch & {
				@include single-transition(box-shadow);

				&:hover {
					@include box-shadow(0 0 10px rgba($black, 0.1));

					.badge-info {
						opacity: 1;
						@include transform(none);
					}
				}
			}
		}

		a.doc-thumb {
			display: block;
			position: relative;

			.preview-mask {
				position: relative;
				@include background-image(linear-gradient(rgba(white, 0) rgba(white, 0.3)));
				background-color: #334d66;
				overflow: hidden;
				text-align: center;
				height: 140px;
				padding-top: rem-calc(40);

				@media #{$large-up} {
					min-height: 170px;
					padding-top: rem-calc(60);
				}

				.loading-msg {
					position: absolute;
					top: rem-calc(60);
					left: 0;
					width: 100%;
					height: auto;
					text-align: center;
					color: white;
					text-transform: uppercase;
					font-size: rem-calc(12);
					line-height: 1.2;
					font-weight: normal;
					opacity: 0.9;
					z-index: 1;

					@media #{$large-up} {
						top: rem-calc(80);
					}
				}

				img.doc-preview {
					max-width: 150px;
					@include box-shadow(0 0 20px rgba($black, 0.5));
					position: relative;
					z-index: 2;
					background-color: $neutral-lowest;
				}
			}

			.call-to-action {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 3;
				background: rgba(0, 0, 0, 0.5);
				@include single-transition;

				.button {
					margin-top: rem-calc(50);
					text-transform: uppercase;
					@include box-shadow(1px 3px 7px rgba(0, 0, 0, 0.75));

					@media #{$large-up} {
						margin-top: rem-calc(65);
					}

					&.WAIT_MASTER_SIGNATURE,
					&.WAIT_MASTER_POSTSIGN_PROCESSING {
						background-color: $neutral-medium-high;

						&:hover {
							background-color: $neutral-medium-high;
						}

						&:focus {
							background-color: $neutral-medium-high;
						}
					}
				}
			}

			.title-caption {
				position: relative;
				@include border-bottom-radius;
				min-height: 60px;

				p {
					margin: 0;
					padding: rem-calc(10 10 0 10);
					color: $neutral-higher;
					font-weight: 500;
					font-size: rem-calc(14);
					line-height: 1.2;
					overflow: hidden;
				}

				span {
					display: block;
					padding: rem-calc(5 10);
					color: $neutral-medium-high;
					font-size: rem-calc(12);
				}
			}
		}

		a.doc-thumb.default-cursor {
			cursor: default;
		}

		%badge-base {
			display: block;
			text-align: center;
			width: rem-calc(60);
			height: rem-calc(34);
			color: white;
			@include box-shadow(2px 2px 5px rgba(black, 0.3));
		}

		.left-badges {
			position: absolute;
			top: 10px;
			left: 0;
			z-index: 3;

			.badge-new {
				@extend %badge-base;
				font-weight: 500;
				// font-size: rem-calc(10);
				text-transform: uppercase;
				//+user-select(none)
				cursor: default;
				// padding: rem-calc(11 10);
				// SWITCH COLOR SIM
				background-color: $green;
				border-radius: 0 $radius $radius 0;
				font-size: rem-calc(14);
				height: auto;
				padding: $gutter ($gutter * 1.5);
				width: auto;

				.style_Z & {
					background-color: $style-z;
				}

				.style_A & {
					background-color: $green;
				}

				.style_Y & {
					background-color: $style-y;
				}
			}

			.badge-expiry {
				@extend %badge-base;
				width: auto;
				height: auto;
				line-height: rem-calc(15);
				padding: rem-calc(5);
				background-color: $red;
				font-weight: 600;
				font-size: rem-calc(15);
				text-transform: uppercase;
				cursor: default;
				//@include user-select(none);

				.icon {
					margin-right: rem-calc(5);
				}

				> span {
					font-size: rem-calc(10);
					vertical-align: top;
				}
			}

			.badge-star {
				@extend %badge-base;
				background-color: $neutral-medium-low;
				opacity: 0.8;
				font-size: rem-calc(20);
				@include single-transition;

				&:hover {
					opacity: 1;
				}

				&.active {
					opacity: 1;
					background-color: $yellow;
				}
			}
		}

		.badge-info {
			position: absolute;
			top: rem-calc(10);
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			@extend %badge-base;
			background-color: $neutral-medium-high;
			font-size: rem-calc(20);
			z-index: 3;

			&:active {
				background-color: $blue-dark;
			}

			.no-touch & {
				@include translateX(100%);
				opacity: 0;
				@include single-transition;

				&:hover {
					background-color: $blue;
				}

				&:active {
					background-color: $blue-dark;
				}
			}
		}

		.details-overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba($blue-dark, 0.97);
			color: white;
			z-index: 5;
			padding: rem-calc(15 40 15 15);
			@include radius;
			@include backface-visibility(hidden);
			overflow-y: auto;

			.close-icon {
				display: block;
				position: absolute;
				top: rem-calc(18);
				right: rem-calc(10);
				font-size: rem-calc(20);
				color: white;
			}

			ul.details-list {
				@include no-bullet;
				margin: 0;

				li.label-title {
					margin: 0;
					line-height: 1.6;
					color: $neutral-medium-low;
				}

				li.detail {
					color: white;
					margin: 0;
					font-size: rem-calc(12);
					margin-bottom: rem-calc(8);
				}

				li:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.document-list {
		margin-bottom: rem-calc(20);

		.heading-bar {
			border-bottom: 1px solid $divider-color-light;
			margin-bottom: rem-calc(15);
			padding-bottom: rem-calc(2);

			p.label-title {
				margin: 0;
			}
		}

		ul.list {
			@include no-bullet;
			margin: rem-calc(0 0 20 0);

			> li {
				background-repeat: no-repeat;
				background-position: 0 0;
				min-height: rem-calc(40);
				font-size: rem-calc(14);
				color: $neutral-medium-high;
				padding-bottom: rem-calc(20);
				border-bottom: 1px solid $divider-color-light;
				margin-bottom: rem-calc(20);

				// &.pdf {
				// }

				// +retinize("filetypes/pdf", "png", 32px, 40px)
				// &.doc {
				// }

				// +retinize("filetypes/doc", "png", 32px, 40px)
				// &.xls {
				// }

				// +retinize("filetypes/xls", "png", 32px, 40px)
				.filename {
					padding-left: 45px;

					a {
						display: block;
						color: $black;
						font-size: rem-calc(14);
						font-weight: 600;

						&:hover,
						&:active {
							color: $anchor-font-color-hover;
						}
					}

					small {
						display: block;
					}
				}

				p.description-text {
					line-height: rem-calc(30);
					margin: 0;
				}
			}
		}

		.badge-expiry {
			display: inline-block;
			background: $red;
			color: white;
			padding: rem-calc(6);
			margin-left: 1rem;
			font-size: rem-calc(11);
			font-weight: 500;
			text-transform: uppercase;
			line-height: rem-calc(12);
			vertical-align: text-bottom;

			span {
				vertical-align: middle;
			}
		}

		a.fav-icon {
			display: inline-block;
			color: $neutral-medium-low;
			font-size: rem-calc(22);

			&:hover,
			&:active {
				color: $neutral-medium-high;
			}

			&.active {
				color: $yellow;
			}
		}

		.icon-new {
			@include label($padding: 8px, $bg: $neutral-medium-low);
			white-space: inherit;
			color: white;
			display: inline-block;
			font-weight: 600;
			text-transform: uppercase;
			@include single-transition(background-color);

			&:hover,
			&:active {
				background-color: $neutral-medium-high;
			}

			&.active {
				background-color: $red;
			}
		}

		.custom-table {
			.button {
				margin: 0;
			}
		}
	}

	.search-results-title {
		font-weight: 300;
		color: $neutral-high;

		strong {
			font-weight: 500;
			margin-left: rem-calc(5);
		}

		a {
			font-size: 70%;
		}
	}
}

//popup generic document
#document-info-modal {
	max-width: none;
	max-height: 100vh;
	padding: rem-calc(30);
	-webkit-overflow-scrolling: touch;

	@media #{$medium-up} {
		padding: 0;
	}

	iframe {
		min-height: 50vh;
		display: block;
		width: 100%;
		height: 100%;
	}

	.modal-topbar {
		padding: 0 0 $column-gutter;

		@media #{$medium-up} {
			padding: ($gutter * 3) ($gutter * 1.5);
		}

		h3 {
			max-width: 83%;
			font-weight: 400;
			color: $neutral-higher;
			display: inline-block;
			margin: rem-calc(2 10 2 0);

			@media #{$medium-up} {
				max-width: 90%;
				font-size: rem-calc(16);
				font-weight: 500;
				margin: 0;
			}

			span {
				font-weight: 600;
			}
		}

		.badge-expiry {
			display: inline-block;
			background: $red;
			color: white;
			padding: rem-calc(6);
			margin-top: rem-calc(4);
			margin-left: 1rem;
			font-size: rem-calc(11);
			font-weight: 600;
			text-transform: uppercase;
			line-height: rem-calc(12);
			vertical-align: text-bottom;

			.icon {
				font-size: rem-calc(16);
				margin-right: 0.5rem;
				vertical-align: middle;
			}

			span {
				vertical-align: middle;
			}
		}

		.close {
			font-size: rem-calc(36);
			cursor: pointer;
			color: $neutral-medium-high;

			&:hover {
				color: $neutral-high;
			}
		}
	}

	.modal-tabs .tabs-header {
		@include clearfix;

		ul {
			@extend %filter-bar-container;

			@media #{$medium-up} {
				line-height: rem-calc(12);
				font-size: rem-calc(12);
				margin-bottom: $gutter * 2;
			}

			> li {
				opacity: 0.7;

				> a,
				> span {
					color: $neutral-higher;
					font-size: rem-calc(14);
					margin: rem-calc(0 10);
					overflow: hidden;
					padding: rem-calc(15 0);
					position: relative;
					text-align: center;
					text-transform: uppercase;

					.style_Z & {
						color: $style-z;
					}

					.style_A & {
						color: $style-a;
					}

					.style_Y & {
						color: $style-y;
					}

					@media #{$medium-up} {
						font-size: rem-calc(12);
						height: 100%;
						margin: 0;
						max-height: 40px;
						min-width: 100px;
						padding: ($gutter * 1.2) ($gutter * 2);
					}

					// &:after {
					// 	content: '';
					// 	display: block;
					// 	position: absolute;
					// 	bottom: 0;
					// 	left: 0;
					// 	right: 0;
					// 	height: rem-calc(3);
					// 	background-color: $blue;
					// 	opacity: 0;
					// 	@include transform(translate(0, 100%) scale(0));
					// 	@include single-transition;
					// }
				}

				&:hover,
				&.active {
					opacity: 1;

					.style_Z & {
						background-color: rgba($style-z, 0.1);
					}

					.style_A & {
						background-color: rgba($style-a, 0.1);
					}

					.style_Y & {
						background-color: rgba($style-y, 0.1);
					}
				}

				// &.divider {
				// 	border-right: 1px solid $divider-color-light;
				// }
			}
		}
	}

	.custom-table {
		.ct-row {
			@include box-shadow(none);

			// &:nth-child(2n)
			//	 background: $neutral-lower
			> *:first-child {
				padding-left: $column-gutter;
				width: 30em;
			}

			> *:last-child {
				padding-right: $column-gutter;
			}

			&:hover:not(.ct-head):not(.ct-blue-row) {
				background: mix($primary-color, $neutral-lower, 10%);

				.style_Z & {
					background: mix($style-z, $neutral-lower, 10%);
				}

				.style_A & {
					background: mix($style-a, $neutral-lower, 10%);
				}

				.style_Y & {
					background: mix($style-y, $neutral-lower, 10%);
				}
			}

			&.ct-head {
				font-weight: 600;
				font-size: rem-calc(11);
				line-height: rem-calc(14);
				// color: $neutral-higher;
				// text-transform: uppercase;

				.ct-cell {
					border-bottom: none;
					padding-top: rem-calc(18);
					vertical-align: inherit;

					> span.subtitle {
						display: block;
						font-weight: 600;
						color: $neutral-medium-high;
					}
				}
			}

			@media #{$medium-up} {
				> *:first-child {
					width: auto;
				}
			}

			&.ct-blue-row {
				background-color: $neutral-lower;
				// background-color: $primary-color;
			}

			.ct-cell {
				> span.subtitle {
					display: block;
				}
			}
		}

		&-container {
			@media #{$medium-up} {
				transform: none;
				width: 100%;
			}
		}
	}

	> .content {
		padding: $column-gutter $column-gutter / 2;

		@media #{$medium-up} {
			padding: 0;

			.column {
				padding: 0 $gutter * 1.5;

				p,
				label {
					display: flex;
					flex-direction: row;
					font-weight: 400;
					width: 100%;

					.label-title {
						font-size: rem-calc(12);
						flex-basis: 50%;
					}

					.label-value {
						font-size: rem-calc(16);
						flex-basis: 50%;
					}
				}
			}
		}
	}
}

.tooltip {
	top: 0;
	left: 0;
	pointer-events: none;
	opacity: 0;
	position: absolute;
	z-index: 9999;
	background-color: $black;
	color: $neutral-lowest;
	font-weight: 500;
	padding: rem-calc(5 10);
	font-size: rem-calc(14);
	@include translateY(rem-calc(0));

	&:after {
		@include css-triangle(rem-calc(6), $black, top);
		position: absolute;
		bottom: rem-calc(-12);
	}

	&.open {
		@include transition(opacity 0.2s ease-out 0.5s, transform 0.3s ease-out 0.5s);
		opacity: 0.9;
		@include translateY(rem-calc(-10));
	}
}

// Modal for products-movements-modal.jade
.reveal-modal,
.reveal-modal-bg {
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}

.reveal-modal {
	min-height: rem-calc(180);
}

#detail-info-modal {
	@extend #document-info-modal;
	padding: rem-calc(0 20);
	max-height: calc(100vh - 100px);
	overflow: auto;
	bottom: 5px;
	// .ct-head .ct-cell.sortable:before,
	// .ct-head .ct-cell.sortable:after
	//	 display: none

	.title-head-for-small {
		@media #{$medium-up} {
			display: none;
		}
	}

	.button {
		display: inline;
		margin-top: $column-gutter;
		margin-right: $column-gutter;

		&:last-child {
			display: inline-block;
		}
	}

	.icon-close-circle {
		padding-top: rem-calc(10);
	}

	.performance-table-container {
		width: 100%;

		@media #{$large-up} {
			width: 30%;
		}

		.ct-row {
			.ct-cell {
				width: 50%;
			}
		}
	}
}

// Accordion group (mmain.jade)
.AccordionContainer {
	margin-bottom: $column-gutter;
	color: $neutral-higher;
}

.AccordionGroup {
	display: flex;
	border-bottom: 1px solid $neutral-low;
	justify-content: space-between;
	margin-bottom: $column-gutter;
	font-weight: 600;

	& ~ {
		margin-bottom: $column-gutter;
	}

	&__title {
		display: block;
		padding: rem-calc(15 0 10);
		margin-bottom: 0;
		// color: white;
		// border-radius: rem-calc(6 6 0 0);
		text-transform: capitalize;
		// color: $gray-darker;
		font-size: rem-calc(14);
	}

	&__value {
		margin-top: $column-gutter;
		text-align: right;
		font-size: rem-calc(14);

		.AccordionGroup__total {
			display: inline;
		}
		.AccordionGroup__total--fia {
			font-size: rem-calc(12);
		}
	}
}

.AccordionMainObj {
	will-change: max-height;
	animation-name: custom-accordion;
	animation-duration: $transition-speed;
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

// Animation
@keyframes custom-accordion {
	0% {
		max-height: 0;
		opacity: 0;
	}

	99% {
		max-height: 100vh;
		opacity: 1;
	}

	100% {
		max-height: auto;
		opacity: 1;
	}
}

// Accordion
.AccordionElements {
	 border-bottom: 1px solid $neutral-low;

	&:last-child {
		border-bottom: 0;
	}

	&--border {
		border-bottom: 1px solid $neutral-medium-low !important;
	}

	&__trigger {
		cursor: pointer;
		padding: rem-calc(8 0);
		font-size: rem-calc(14);

		span {
			color: $neutral-higher;
		}

		.icon {
			font-size: rem-calc(20);
			// padding-right: rem-calc(10);
			vertical-align: middle;
			color: $gray-dark;
			float: right;
		}
	}

	&__trigger:active {
		background: $neutral-medium-low;
	}
}

// Element p as a link
.filter-bar {
	p.colorize-element-as-link {
		@extend a;
		opacity: 0.7;

		&:hover,
		&.active {
			// color: $neutral-higher;
			opacity: 1;
		}

		@media #{$medium-up} {
			min-width: auto;
		}
	}
}

// Override default style for Azimut table
.azimut-table {
	letter-spacing: 0.8px;

	// .ct-head .ct-cell.sortable:after,
	// .ct-head .ct-cell.sortable:before {
	//	 	display: none
	//}

	.ct-row.ct-blue-row {
		background: $neutral-lower;
		color: $neutral-medium-high;
		
		.ct-head-sticky {
			background: $neutral-lower;
			
		}



		// &.ct-row:not(.ct-row-small-expanded) .ct-head-col,
		// .ct-head-placeholder > div:after {
		// 	background: $primary-color;

		// 	.style_Z & {
		// 		background: $style-z;
		// 	}

		// 	.style_A & {
		// 		background: $style-a;
		// 	}

		// 	.style_Y & {
		// 		background: $style-y;
		// 	}
		// }

		// &:hover:not(.ct-head):not(.highlighted):not(.locked) {
		// 	background: $primary-color;

		// 	.style_Z & {
		// 		background: $style-z;
		// 	}

		// 	.style_A & {
		// 		background: $style-a;
		// 	}

		// 	.style_Y & {
		// 		background: $style-y;
		// 	}

		// 	.ct-cell.ct-head-col,
		// 	.ct-head-placeholder > div:after {
		// 		background: $primary-color;

		// 		.style_Z & {
		// 			background: $style-z;
		// 		}

		// 		.style_A & {
		// 			background: $style-a;
		// 		}

		// 		.style_Y & {
		// 			background: $style-y;
		// 		}
		// 	}
		// }
	}

  .ct-row.ct-blue-row.table-total {
    background: $neutral-lower;
    color: $neutral-higher;

    .ct-head-sticky {
      background: $neutral-lower;
    }
  }
}
