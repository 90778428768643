// BUTTONS DOWNLOADING ANIMATION
@keyframes loadingDots {
	0% {
		transform: scale(0);
		margin-left: 10px;
		margin-right: 15px;
	}

	26% {
		transform: scale(1);
		margin-left: 10px;
		margin-right: 15px;
	}

	33% {
		transform: scale(0);
		margin-left: 10px;
		margin-right: 15px;
	}

	34% {
		transform: scale(0);
		margin-left: 20px;
		margin-right: 5px;
	}

	59% {
		transform: scale(1);
		margin-left: 20px;
		margin-right: 5px;
	}

	66% {
		transform: scale(0);
		margin-left: 20px;
		margin-right: 5px;
	}

	67% {
		transform: scale(0);
		margin-left: 30px;
		margin-right: -5px;
	}

	93% {
		transform: scale(1);
		margin-left: 30px;
		margin-right: -5px;
	}

	100% {
		transform: scale(0);
		margin-left: 30px;
		margin-right: -5px;
	}
}

// BUTTONS

// Default styles applied outside of media query
button,
.button {
	@include button-base;
	@include button-size;
	@include button-style($radius: true, $bg: $primary-color);
	@include single-transition(background-color);
	text-transform: uppercase;
	background-image: linear-gradient(rgba(black, 0), rgba(black, 0.1));

	.style_Z & {
		@include button-style($radius: true, $bg: $style-z);
	}

	.style_A & {
		@include button-style($radius: true, $bg: $style-a);
	}

	.style_Y & {
		@include button-style($radius: true, $bg: $style-y);
	}

	&.secondary {
		@include button-style($bg: lighten($neutral-medium-high, 5%), $bg-hover: darken($neutral-medium-high, 5%), $border-color: lighten($neutral-medium-high, 5%));
	}

	&.success {
		@include button-style($bg: $success-color, $bg-hover: $success-button-bg-hover, $border-color: $success-button-border-color);
	}

	&.alert {
		@include button-style($bg: $alert-color, $bg-hover: $alert-button-bg-hover, $border-color: $alert-button-border-color);
	}

	&.warning {
		@include button-style($bg: $warning-color, $bg-hover: $warning-button-bg-hover, $border-color: $warning-button-border-color);
	}

	&.info {
		@include button-style($bg: $info-color, $bg-hover: $info-button-bg-hover, $border-color: $info-button-border-color);
	}

	&.large {
		@include button-size($padding: $button-lrg);
	}

	&.huge {
		@include button-size($padding: $button-huge);
		padding-left: $button-huge * 2.5;
		padding-right: $button-huge * 2.5;
		font-size: $button-font-huge;
		font-weight: 500;
	}

	&.WAIT_MASTER_SIGNATURE,
	&.WAIT_MASTER_POSTSIGN_PROCESSING,
	&.SIGNED,
	&.RETIRED,
	&.EXPIRED,
	&.INIT {
		&,
		&:hover,
		&:focus {
			background-color: $neutral-medium-high;
		}
	}

	&.small {
		@include button-size($padding: $button-sml);
	}

	&.tiny {
		@include button-size($padding: $button-tny);
	}

	&.expand {
		@include button-size($padding: null, $full-width: true);
	}

	&.left-align {
		text-align: left;
		text-indent: rem-calc(12);
	}

	&.right-align {
		text-align: right;
		padding-right: rem-calc(12);
	}

	&.radius {
		@include button-style($bg: false, $radius: true);
	}

	&.round {
		@include button-style($bg: false, $radius: $button-round);
	}

	&.disabled,
	&[disabled] {
		@include button-style($bg: $button-bg-color, $disabled: true, $bg-hover: $button-bg-hover, $border-color: $button-border-color);

		&.secondary {
			@include button-style($bg: lighten($neutral-medium-high, 5%), $disabled: true, $bg-hover: darken($neutral-medium-high, 5%), $border-color: lighten($neutral-medium-high, 5%));
		}

		&.success {
			@include button-style($bg: $success-color, $disabled: true, $bg-hover: $success-button-bg-hover, $border-color: $success-button-border-color);
		}

		&.alert {
			@include button-style($bg: $alert-color, $disabled: true, $bg-hover: $alert-button-bg-hover, $border-color: $alert-button-border-color);
		}

		&.warning {
			@include button-style($bg: $warning-color, $disabled: true, $bg-hover: $warning-button-bg-hover, $border-color: $warning-button-border-color);
		}

		&.info {
			@include button-style($bg: $info-color, $disabled: true, $bg-hover: $info-button-bg-hover, $border-color: $info-button-border-color);
		}
	}

	&.is_downloading::after {
		will-change: transform, margin-left, margin-right;
		content: '';
		display: inline-block;
		width: 10px;
		height: 10px;
		background: $neutral-lowest;
		border-radius: 20px;
		animation: loadingDots 3s infinite;
	}
}

//firefox 2px fix
button::-moz-focus-inner {
	border: 0;
	padding: 0;
}

@media #{$medium-up} {
	button,
	.button {
		@include button-base($style: false, $display: inline-block);
		@include button-size($padding: false, $full-width: false);
	}
}
