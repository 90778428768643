// OW LAYOUT BASE VARIABLES
$include-legacy-query-classes: true;
$include-ow-flex-table: true;

// css dependencies

// scss dependencies
// bower:scss
@import '~compass-mixins/lib/_compass.scss';
@import '~compass-mixins/lib/animation/_core.scss';
@import '~sweetalert/lib/sweet-alert.scss';

// endbower

// variables
@import './helpers/functions';
@import './helpers/variables';
@import './partials/foundation-settings';
@import './helpers/mixins';

// @import fonts/futura;

@import '~@owui-thule/theme/theme';
@import '~@owui-thule/theme/fonts';
@import '~@owui-thule/theme/icons';

// ** FOUNDATION
// @import foundation/scss/foundation.scss
$include-html-classes: false;
$include-html-global-classes: true;
$include-html-grid-classes: false;
$include-xl-html-grid-classes: false;
$include-html-type-classes: true;
$include-html-accordion-classes: false;
$include-html-alert-classes: false;
$include-html-block-grid-classes: true;
$include-xl-html-block-grid-classes: false;
$include-html-nav-classes: false;
$include-html-button-classes: false;
$include-html-clearing-classes: false;
$include-html-dropdown-classes: true;
$include-html-media-classes: true;
$include-html-form-classes: true;
$include-html-icon-bar-classes: false;
$include-html-inline-list-classes: false;
$include-html-joyride-classes: false;
$include-html-keystroke-classes: false;
$include-html-label-classes: true;
$include-html-magellan-classes: false;
$include-html-off-canvas-classes: false;
$include-html-orbit-classes: false;
$include-pagination-classes: false;
$include-html-panel-classes: false;
$include-html-pricing-classes: false;
$include-html-range-slider-classes: false;
$include-html-reveal-classes: true;
$include-html-table-classes: true;
$include-html-tabs-classes: true;
$include-html-tooltip-classes: false;
$include-html-top-bar-classes: false;
$include-html-visibility-classes: false;

@import './foundation-sites/scss/normalize';
@import './foundation-sites/scss/foundation/components/block-grid';
@import './foundation-sites/scss/foundation/components/buttons';
@import './foundation-sites/scss/foundation/components/dropdown';
@import './foundation-sites/scss/foundation/components/flex-video';
@import './foundation-sites/scss/foundation/components/forms';
@import './foundation-sites/scss/foundation/components/labels';
@import './foundation-sites/scss/foundation/components/reveal';
@import './foundation-sites/scss/foundation/components/tables';
@import './foundation-sites/scss/foundation/components/tabs';
@import './foundation-sites/scss/foundation/components/tooltips';
@import './foundation-sites/scss/foundation/components/type';
@import './foundation-sites/scss/foundation/components/visibility';

// Import sprites here, so they can be used by widgets too
@import './partials/sprites/country-flags';
@import './partials/sprites/generic-sprite';

// sass dependencies
// bower:sass
@import './json2css-sprite-mixins/_json2css_sprite_mixins';

// endbower

@import './layout-base/layout-base';

// IMPORTED RESOURCES
@import './helpers/animations';
@import './partials/buttons';
@import './partials/icons';
@import './partials/layout';
@import './partials/components';
@import './partials/utilities';
@import './partials/table';
@import './partials/shared-generic';
@import './partials/badge';
@import './partials/badge-documents';
@import './partials/export';
@import './loading-spinner';

// IMPORTED WIDGETS
@import './widgets/az-login';
@import './widgets/az-portfolio-holdings';
@import './widgets/az-portfolio-movements';
@import './widgets/az-advisor';
@import './widgets/az-my-documents';
@import './widgets/az-ng-cloak';
@import './widgets/az-video-library';
@import './widgets/az-dossier-selector-modal';
@import './widgets/az-user-data-edit';
@import './widgets/_az-custom-login';
@import './widgets/_az-mobile-utils';
@import './widgets/az-settings-edit';
@import './widgets/az-dossier-selector';
@import './widgets/az-html-content';
@import './widgets/az-portfolio-ownership-view';
@import './widgets/az-portfolio-performance-azimut';
@import './widgets/az-portfolio-summary';
@import './widgets/az-settings-customer';
@import './widgets/az-support-page';
@import './widgets/az-user-data';
@import './widgets/az-news-list';
@import './widgets/az-news';
@import './widgets/az-focus';
@import './widgets/az-pirbox';

// CUSTOM THEME
@import './custom/variables';

// @import layout-base/layout-theme/style-a

*::selection {
	background-color: $primary-color;
	color: $neutral-lowest;

	.style_Z & {
		background-color: $style-z;
	}

	.style_A & {
		background-color: $style-a;
	}

	.style_Y & {
		background-color: $style-y;
	}
}

input[type='number'] {
	-moz-appearance: textfield;

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

input::-ms-clear,
input::-ms-reveal {
	display: none;
}

main {
	background-color: $background-color-primary;
}

$chart-outside-color: $neutral-medium-high;

.c3 {
	.c3-axis-y-label,
	.c3-axis-x-label {
		fill: $chart-outside-color;
	}

	text {
		font-family: $primary-font;
	}

	.c3-axis-x,
	.c3-axis-y {
		.domain {
			stroke: $chart-outside-color;
		}

		.tick {
			line {
				stroke: $chart-outside-color;
			}

			text {
				fill: $chart-outside-color;
			}
		}
	}
}

// Support page
$help-icon-size: rem-calc(80);

// .support-page {
// 	.tab-group {
// 		.tabs {
// 			@include tabs;

// 			.tab-title {
// 				@include tab-title($bg: $tt-gray-dark, $bg-hover: darken($tt-gray-dark, 5%));
// 			}
// 		}

// 		.tabs-content {
// 			@include tabs-content;
// 		}
// 	}

// 	.external-resources {
// 		.wrapper {
// 			text-align: center;
// 			margin-bottom: $column-gutter * 1.5;
// 		}

// 		.icon-wrap {
// 			display: inline-block;
// 			@include rounded($help-icon-size);
// 			background-color: $tt-gray-darker;
// 			line-height: $help-icon-size * 0.9;
// 			vertical-align: middle;
// 			text-align: center;
// 			font-size: $help-icon-size * 0.45;
// 			color: $neutral-lowest;
// 			padding: 0;
// 			margin: 0;
// 			margin-bottom: $column-gutter;
// 		}
// 	}

// 	.faqs {
// 		.icon-qa {
// 			color: $tt-gray-dark;
// 			font-size: rem-calc(100);
// 			margin-right: rem-calc(10);
// 		}

// 		.subheader {
// 			color: $neutral-higher;
// 		}
// 	}

// 	.faqs-wrapper {
// 		margin: rem-calc(20 0 30 0);
// 	}

// 	.faq {
// 		border-bottom: 1px solid $tt-gray;
// 		display: block;

// 		.question {
// 			display: block;
// 			position: relative;
// 			padding: rem-calc(10 30 10 0);
// 			font-size: rem-calc(14);
// 			line-height: 1.4;
// 			background-color: $neutral-lowest;
// 			color: $primary-color;
// 			@include single-transition(background-color);

// 			.style_Z & {
// 				color: $style-z;
// 			}

// 			.style_A & {
// 				color: $style-a;
// 			}

// 			.style_Y & {
// 				color: $style-y;
// 			}

// 			&:hover {
// 				background-color: $tt-gray-light;
// 				.icon {
// 					color: $tt-gray-darker;
// 				}
// 			}

// 			@media #{$medium-up} {
// 				font-size: rem-calc(16);
// 				padding: rem-calc(10 40 10 0);
// 			}

// 			@media #{$large-up} {
// 				padding: rem-calc(15 40 15 15);
// 				font-size: rem-calc(21);
// 			}

// 			.icon {
// 				position: absolute;
// 				top: 50%;
// 				right: rem-calc(10);
// 				@include translateY(-50%);
// 				font-size: rem-calc(20);
// 				color: $tt-gray-dark;
// 				@include single-transition(color);

// 				@media #{$medium-up} {
// 					font-size: rem-calc(25);
// 				}

// 				@media #{$large-up} {
// 				}
// 			}
// 		}

// 		.answer {
// 			margin: rem-calc(10 0 30 0);
// 			font-size: rem-calc(14);

// 			@media #{$large-up} {
// 				padding-left: rem-calc(15);
// 				font-size: rem-calc(16);
// 			}
// 		}
// 	}

// 	.external-faqs {
// 		.icon-question-circle {
// 			color: $tt-gray-dark;
// 			font-size: rem-calc(60);
// 		}
// 	}
// }

//Colorize for multigroup
.label {
	background-color: $green;
	border-radius: 4px;
	text-transform: uppercase;
	font-weight: 500;

	@media #{$medium-up} {
		font-size: rem-calc(14);
		letter-spacing: 1.4px;
		padding: $gutter;
		margin-right: $gutter * 3;
	}
}

// HTML CONTENT
div[data-dwx-widget-html-content] {
	background-color: black;
}

// EQJS INIT
.widget {
	@include eq-pts(('small: 0, medium: 641, medium-landscape: 961, large: 1201, xlarge: 1441, xxlarge: 1921'));
}

html:before {
	display: none;
	content: '.generic-documents';
}

body {
	@include mediaeq('large') {
		display: block;
	}
}

#azLinkContainer {
	background-color: #f0f0f0
}

.Portal__view {
	margin: 0 ($gutter * 6) 0 ($gutter * 6);
  height: calc(100% - 170px);

  @media #{$small-up}{
    margin: 0;
  }

  @media #{$medium-up}{
    margin: 0;
  }

  @media #{$large-up}{
    margin: 0 ($gutter * 6) 0 ($gutter * 6);
    height: calc(100% - 254px);
  }
}

.Header {
	&__advisorLabel {
		font-family: $primary-font;
		color: rgba($white, .8);
		font-weight: 400;
		font-size: rem-calc(12);
		margin-bottom: 1px;
	}

	&__advisorName {
		font-size: rem-calc(16);
	}
}
