/* SASS variables are information about icon's compiled state, stored under its original file name
 *
 * .icon-home
 *   width: $icon-home-width
 *
 * The large array-like variables contain all information about a single icon
 * $icon-home: x y offset_x offset_y width height total_width total_height image_path
 *
 * At the bottom of this section, we provide information about the spritesheet itself
 * $spritesheet: width height image $spritesheet-sprites
 */

$ar-ae-name: 'ar-ae';
$ar-ae-x: 0px;
$ar-ae-y: 0px;
$ar-ae-offset-x: 0px;
$ar-ae-offset-y: 0px;
$ar-ae-width: 21px;
$ar-ae-height: 15px;
$ar-ae-total-width: 67px;
$ar-ae-total-height: 49px;
$ar-ae-image: '../../../images/sprites/country-flags.png';
$ar-ae: 0px, 0px, 0px, 0px, 21px, 15px, 67px, 49px, '../../../images/sprites/country-flags.png', 'ar-ae';
$en-gb-name: 'en-gb';
$en-gb-x: 23px;
$en-gb-y: 0px;
$en-gb-offset-x: -23px;
$en-gb-offset-y: 0px;
$en-gb-width: 21px;
$en-gb-height: 15px;
$en-gb-total-width: 67px;
$en-gb-total-height: 49px;
$en-gb-image: '../../../images/sprites/country-flags.png';
$en-gb: 23px, 0px, -23px, 0px, 21px, 15px, 67px, 49px, '../../../images/sprites/country-flags.png', 'en-gb';
$es-es-name: 'es-es';
$es-es-x: 0px;
$es-es-y: 17px;
$es-es-offset-x: 0px;
$es-es-offset-y: -17px;
$es-es-width: 21px;
$es-es-height: 15px;
$es-es-total-width: 67px;
$es-es-total-height: 49px;
$es-es-image: '../../../images/sprites/country-flags.png';
$es-es: 0px, 17px, 0px, -17px, 21px, 15px, 67px, 49px, '../../../images/sprites/country-flags.png', 'es-es';
$fr-be-name: 'fr-be';
$fr-be-x: 23px;
$fr-be-y: 17px;
$fr-be-offset-x: -23px;
$fr-be-offset-y: -17px;
$fr-be-width: 21px;
$fr-be-height: 15px;
$fr-be-total-width: 67px;
$fr-be-total-height: 66px;
$fr-be-image: '../../../images/sprites/country-flags.png';
$fr-be: 23px, 17px, -23px, -17px, 21px, 15px, 67px, 66px, '../../../images/sprites/country-flags.png', 'fr-be';
$fr-fr-name: 'fr-fr';
$fr-fr-x: 23px;
$fr-fr-y: 17px;
$fr-fr-offset-x: -23px;
$fr-fr-offset-y: -17px;
$fr-fr-width: 21px;
$fr-fr-height: 15px;
$fr-fr-total-width: 67px;
$fr-fr-total-height: 49px;
$fr-fr-image: '../../../images/sprites/country-flags.png';
$fr-fr: 23px, 17px, -23px, -17px, 21px, 15px, 67px, 49px, '../../../images/sprites/country-flags.png', 'fr-fr';
$it-it-name: 'it-it';
$it-it-x: 46px;
$it-it-y: 0px;
$it-it-offset-x: -46px;
$it-it-offset-y: 0px;
$it-it-width: 21px;
$it-it-height: 15px;
$it-it-total-width: 67px;
$it-it-total-height: 49px;
$it-it-image: '../../../images/sprites/country-flags.png';
$it-it: 46px, 0px, -46px, 0px, 21px, 15px, 67px, 49px, '../../../images/sprites/country-flags.png', 'it-it';
$nl-be-name: 'nl-be';
$nl-be-x: 0px;
$nl-be-y: 34px;
$nl-be-offset-x: 0px;
$nl-be-offset-y: -34px;
$nl-be-width: 21px;
$nl-be-height: 15px;
$nl-be-total-width: 67px;
$nl-be-total-height: 66px;
$nl-be-image: '../../../images/sprites/country-flags.png';
$nl-be: 0px, 34px, 0px, -34px, 21px, 15px, 67px, 66px, '../../../images/sprites/country-flags.png', 'nl-be';
$nl-nl-name: 'nl-nl';
$nl-nl-x: 46px;
$nl-nl-y: 17px;
$nl-nl-offset-x: -46px;
$nl-nl-offset-y: -17px;
$nl-nl-width: 21px;
$nl-nl-height: 15px;
$nl-nl-total-width: 67px;
$nl-nl-total-height: 49px;
$nl-nl-image: '../../../images/sprites/country-flags.png';
$nl-nl: 46px, 17px, -46px, -17px, 21px, 15px, 67px, 49px, '../../../images/sprites/country-flags.png', 'nl-nl';
$pt-pt-name: 'pt-pt';
$pt-pt-x: 0px;
$pt-pt-y: 34px;
$pt-pt-offset-x: 0px;
$pt-pt-offset-y: -34px;
$pt-pt-width: 21px;
$pt-pt-height: 15px;
$pt-pt-total-width: 67px;
$pt-pt-total-height: 49px;
$pt-pt-image: '../../../images/sprites/country-flags.png';
$pt-pt: 0px, 34px, 0px, -34px, 21px, 15px, 67px, 49px, '../../../images/sprites/country-flags.png', 'pt-pt';
$zh-cn-name: 'zh-cn';
$zh-cn-x: 23px;
$zh-cn-y: 34px;
$zh-cn-offset-x: -23px;
$zh-cn-offset-y: -34px;
$zh-cn-width: 21px;
$zh-cn-height: 15px;
$zh-cn-total-width: 67px;
$zh-cn-total-height: 49px;
$zh-cn-image: '../../../images/sprites/country-flags.png';
$zh-cn: 23px, 34px, -23px, -34px, 21px, 15px, 67px, 49px, '../../../images/sprites/country-flags.png', 'zh-cn';
$ar-ae-2x-name: 'ar-ae-2x';
$ar-ae-2x-x: 0px;
$ar-ae-2x-y: 0px;
$ar-ae-2x-offset-x: 0px;
$ar-ae-2x-offset-y: 0px;
$ar-ae-2x-width: 42px;
$ar-ae-2x-height: 30px;
$ar-ae-2x-total-width: 134px;
$ar-ae-2x-total-height: 98px;
$ar-ae-2x-image: '../../../images/sprites/country-flags@2x.png';
$ar-ae-2x: 0px, 0px, 0px, 0px, 42px, 30px, 134px, 98px, '../../../images/sprites/country-flags@2x.png', 'ar-ae-2x';
$en-gb-2x-name: 'en-gb-2x';
$en-gb-2x-x: 46px;
$en-gb-2x-y: 0px;
$en-gb-2x-offset-x: -46px;
$en-gb-2x-offset-y: 0px;
$en-gb-2x-width: 42px;
$en-gb-2x-height: 30px;
$en-gb-2x-total-width: 134px;
$en-gb-2x-total-height: 98px;
$en-gb-2x-image: '../../../images/sprites/country-flags@2x.png';
$en-gb-2x: 46px, 0px, -46px, 0px, 42px, 30px, 134px, 98px, '../../../images/sprites/country-flags@2x.png', 'en-gb-2x';
$es-es-2x-name: 'es-es-2x';
$es-es-2x-x: 0px;
$es-es-2x-y: 34px;
$es-es-2x-offset-x: 0px;
$es-es-2x-offset-y: -34px;
$es-es-2x-width: 42px;
$es-es-2x-height: 30px;
$es-es-2x-total-width: 134px;
$es-es-2x-total-height: 98px;
$es-es-2x-image: '../../../images/sprites/country-flags@2x.png';
$es-es-2x: 0px, 34px, 0px, -34px, 42px, 30px, 134px, 98px, '../../../images/sprites/country-flags@2x.png', 'es-es-2x';
$fr-be-2x-x: 46px;
$fr-be-2x-y: 34px;
$fr-be-2x-offset-x: -46px;
$fr-be-2x-offset-y: -34px;
$fr-be-2x-width: 42px;
$fr-be-2x-height: 30px;
$fr-be-2x-total-width: 134px;
$fr-be-2x-total-height: 132px;
$fr-be-2x-image: '../../../images/sprites/country-flags@2x.png';
$fr-be-2x: 46px, 34px, -46px, -34px, 42px, 30px, 134px, 132px, '../../../images/sprites/country-flags@2x.png', 'fr-be-2x';
$fr-fr-2x-name: 'fr-fr-2x';
$fr-fr-2x-x: 46px;
$fr-fr-2x-y: 34px;
$fr-fr-2x-offset-x: -46px;
$fr-fr-2x-offset-y: -34px;
$fr-fr-2x-width: 42px;
$fr-fr-2x-height: 30px;
$fr-fr-2x-total-width: 134px;
$fr-fr-2x-total-height: 98px;
$fr-fr-2x-image: '../../../images/sprites/country-flags@2x.png';
$fr-fr-2x: 46px, 34px, -46px, -34px, 42px, 30px, 134px, 98px, '../../../images/sprites/country-flags@2x.png', 'fr-fr-2x';
$it-it-2x-name: 'it-it-2x';
$it-it-2x-x: 92px;
$it-it-2x-y: 0px;
$it-it-2x-offset-x: -92px;
$it-it-2x-offset-y: 0px;
$it-it-2x-width: 42px;
$it-it-2x-height: 30px;
$it-it-2x-total-width: 134px;
$it-it-2x-total-height: 98px;
$it-it-2x-image: '../../../images/sprites/country-flags@2x.png';
$it-it-2x: 92px, 0px, -92px, 0px, 42px, 30px, 134px, 98px, '../../../images/sprites/country-flags@2x.png', 'it-it-2x';
$nl-be-2x-name: 'nl-be-2x';
$nl-be-2x-x: 0px;
$nl-be-2x-y: 68px;
$nl-be-2x-offset-x: 0px;
$nl-be-2x-offset-y: -68px;
$nl-be-2x-width: 42px;
$nl-be-2x-height: 30px;
$nl-be-2x-total-width: 134px;
$nl-be-2x-total-height: 132px;
$nl-be-2x-image: '../../../images/sprites/country-flags@2x.png';
$nl-be-2x: 0px, 68px, 0px, -68px, 42px, 30px, 134px, 132px, '../../../images/sprites/country-flags@2x.png', 'nl-be-2x';
$nl-nl-2x-name: 'nl-nl-2x';
$nl-nl-2x-x: 92px;
$nl-nl-2x-y: 34px;
$nl-nl-2x-offset-x: -92px;
$nl-nl-2x-offset-y: -34px;
$nl-nl-2x-width: 42px;
$nl-nl-2x-height: 30px;
$nl-nl-2x-total-width: 134px;
$nl-nl-2x-total-height: 98px;
$nl-nl-2x-image: '../../../images/sprites/country-flags@2x.png';
$nl-nl-2x: 92px, 34px, -92px, -34px, 42px, 30px, 134px, 98px, '../../../images/sprites/country-flags@2x.png', 'nl-nl-2x';
$pt-pt-2x-name: 'pt-pt-2x';
$pt-pt-2x-x: 0px;
$pt-pt-2x-y: 68px;
$pt-pt-2x-offset-x: 0px;
$pt-pt-2x-offset-y: -68px;
$pt-pt-2x-width: 42px;
$pt-pt-2x-height: 30px;
$pt-pt-2x-total-width: 134px;
$pt-pt-2x-total-height: 98px;
$pt-pt-2x-image: '../../../images/sprites/country-flags@2x.png';
$pt-pt-2x: 0px, 68px, 0px, -68px, 42px, 30px, 134px, 98px, '../../../images/sprites/country-flags@2x.png', 'pt-pt-2x';
$zh-cn-2x-name: 'zh-cn-2x';
$zh-cn-2x-x: 46px;
$zh-cn-2x-y: 68px;
$zh-cn-2x-offset-x: -46px;
$zh-cn-2x-offset-y: -68px;
$zh-cn-2x-width: 42px;
$zh-cn-2x-height: 30px;
$zh-cn-2x-total-width: 134px;
$zh-cn-2x-total-height: 98px;
$zh-cn-2x-image: '../../../images/sprites/country-flags@2x.png';
$zh-cn-2x: 46px, 68px, -46px, -68px, 42px, 30px, 134px, 98px, '../../../images/sprites/country-flags@2x.png', 'zh-cn-2x';
$country-flags-width: 67px;
$country-flags-height: 49px;
$country-flags-image: '../../../images/sprites/country-flags.png';
$country-flags-sprites: $ar-ae, $en-gb, $es-es, $fr-fr, $it-it, $nl-nl, $pt-pt, $zh-cn;
$country-flags: 67px, 49px, '../../../images/sprites/country-flags.png', $country-flags-sprites;
$retina-spritesheet-width: 134px;
$retina-spritesheet-height: 98px;
$retina-spritesheet-image: '../../../images/sprites/country-flags@2x.png';
$retina-spritesheet-sprites: $ar-ae-2x, $en-gb-2x, $es-es-2x, $fr-fr-2x, $it-it-2x, $nl-nl-2x, $pt-pt-2x, $zh-cn-2x;
$retina-spritesheet: 134px, 98px, '../../../images/sprites/country-flags@2x.png', $retina-spritesheet-sprites;

/* These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.
 *
 * The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
 */

$ar-ae-group-name: 'ar-ae';
$ar-ae-group: 'ar-ae', $ar-ae, $ar-ae-2x;
$en-gb-group-name: 'en-gb';
$en-gb-group: 'en-gb', $en-gb, $en-gb-2x;
$es-es-group-name: 'es-es';
$es-es-group: 'es-es', $es-es, $es-es-2x;
$fr-fr-group-name: 'fr-fr';
$fr-fr-group: 'fr-fr', $fr-fr, $fr-fr-2x;
$it-it-group-name: 'it-it';
$it-it-group: 'it-it', $it-it, $it-it-2x;
$nl-nl-group-name: 'nl-nl';
$nl-nl-group: 'nl-nl', $nl-nl, $nl-nl-2x;
$pt-pt-group-name: 'pt-pt';
$pt-pt-group: 'pt-pt', $pt-pt, $pt-pt-2x;
$zh-cn-group-name: 'zh-cn';
$zh-cn-group: 'zh-cn', $zh-cn, $zh-cn-2x;
$retina-groups: $ar-ae-group, $en-gb-group, $es-es-group, $fr-fr-group, $it-it-group, $nl-nl-group, $pt-pt-group, $zh-cn-group;

/* The provided mixins are intended to be used with the array-like variables
 *
 * .icon-home
 *   @include sprite-width($icon-home)
 *
 * .icon-email
 *   @include sprite($icon-email)
 */

@mixin sprite-width($sprite) {
	width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
	height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
	$sprite-offset-x: nth($sprite, 3);
	$sprite-offset-y: nth($sprite, 4);
	background-position: $sprite-offset-x $sprite-offset-y;
}

@mixin sprite-image($sprite) {
	$sprite-image: nth($sprite, 9);
	background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
	@include sprite-image($sprite);
	@include sprite-position($sprite);
	@include sprite-width($sprite);
	@include sprite-height($sprite);
}

/* The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
 *   It should be used with a "retina group" variable.
 *
 * The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/
 *
 * $icon-home-group: ('icon-home', $icon-home, $icon-home-2x, )
 *
 * .icon-home
 *   @include retina-sprite($icon-home-group)
 */

@mixin sprite-background-size($sprite) {
	$sprite-total-width: nth($sprite, 7);
	$sprite-total-height: nth($sprite, 8);
	background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
	$normal-sprite: nth($retina-group, 2);
	$retina-sprite: nth($retina-group, 3);
	@include sprite($normal-sprite);

	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
		@include sprite-image($retina-sprite);
		@include sprite-background-size($normal-sprite);
	}
}

/* The `sprites` mixin generates identical output to the CSS template
 *   but can be overridden inside of SASS
 *
 * @include sprites($spritesheet-sprites)
 */

@mixin sprites($sprites) {
	@each $sprite in $sprites {
		$sprite-name: nth($sprite, 10);

		.#{$sprite-name} {
			@include sprite($sprite);
		}
	}
}

/* The `retina-sprites` mixin generates a CSS rule and media query for retina groups
 *   This yields the same output as CSS retina template but can be overridden in SASS
 *
 * @include retina-sprites($retina-groups)
 */

@mixin retina-sprites($retina-groups) {
	@each $retina-group in $retina-groups {
		$sprite-name: nth($retina-group, 1);

		.#{$sprite-name} {
			@include retina-sprite($retina-group);
		}
	}
}
