// Documents unread badge
.documents-badge-unread {
   background: $blue;
   border-radius: rem-calc(1000);
   color: $neutral-lowest;
   display: inline-block;
   font-size: rem-calc(10);
   line-height: rem-calc(1);
   padding: rem-calc(8 5);
   position: absolute;
   right: rem-calc(10);
   text-align: center;

   @media #{$large-up} {
      top: rem-calc(16);
      right: rem-calc(-18);
   }
}