@mixin sprite-width($sprite) {
  width: nth($sprite, 5); }

@mixin sprite-height($sprite) {
  height: nth($sprite, 6); }

@mixin sprite-position($sprite, $extra-offset-x: 0, $extra-offset-y: 0) {
  $sprite-offset-x: nth($sprite, 3) + $extra-offset-x;
  $sprite-offset-y: nth($sprite, 4) + $extra-offset-y;
  background-position: $sprite-offset-x $sprite-offset-y; }

@mixin sprite-image($sprite) {
  background-image: url(nth($sprite, 9)); }

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite); }



///////////////////////////////////////////////////
// R E T I N A !
///////////////////////////////////////////////////

// Shorthand retina sprite mixin
// via https://github.com/Ensighten/spritesmith/issues/19#issuecomment-24843826
@mixin sprite-2x($sprite1x, $sprite2x) {
  @include sprite($sprite1x);

  @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    // Use the 2x image...
    @include sprite-image($sprite2x);

    // ...but set background-size to the size of the 1x image...
    background-size: nth($sprite1x, 7) nth($sprite1x, 8);

    // ...so you can use 1x dimensions & position!
    @include sprite-position($sprite1x);
    @include sprite-height($sprite1x);
    @include sprite-width($sprite1x); } }


// Set retina background-image only
// Once this is set you can use the position, height & width mixins individually with 1x values
// (e.g. if you only want to set a new background-position on hover, don't need to re-set background-image)
@mixin sprite-2x-image($sprite1x, $sprite2x) {
  @include sprite-image($sprite1x);

  @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
    @include sprite-image($sprite2x);
    background-size: nth($sprite1x, 7) nth($sprite1x, 8); } }
