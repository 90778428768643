
.BannerContainer {
  .Alert {
    margin-bottom: $gutter;
  }
  @media print {
    display: none;
  }
}

.Alert {
  font-family: $primary-font;
  padding: 0 30px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  height: $gutter * 4;
  max-width: 100%;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  color: $white;
  position: relative;
  display: flex;

  background: linear-gradient(281deg, $mood-warning-low, $mood-bad-lowest);

  &__message {
    font-size: rem-calc(14);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
