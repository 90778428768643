//.custom-table
//  margin: rem-calc(15 0 30)
//  .ct-row.ct-head
//    background: $neutral-lower
//    text-transform: uppercase
//    color: $gray-dark
//    font-weight: 800
//    font-size: rem-calc(11)
//    .ct-cell
//      padding: rem-calc(5 20 15 15)
//      border-right: 1px solid $neutral-low
//      border-bottom: 1px solid $neutral-low
//      @media #{$large-up}
//        padding: rem-calc(10 20 25)
//      &:last-of-type
//        border-right: none
//      &.sortable:not(.ord-desc):before
//        border-color: $gray-light transparent transparent
//      &.sortable:not(.ord-asc):after
//        border-color: transparent transparent $gray-light

//Table Layout
.ct-head {
	.ct-cell {
		font-size: rem-calc(12);
		font-family: $primary-font;
		font-weight: 400;
		padding: rem-calc(15 10);

		&.has-drilldown > a {
			background: none;
		}

		&.sortable {
			min-width: rem-calc(100);

			&:after,
			&:before {
				font-family: $primary-font;
			}
		}

		&.ct-head-col,
		&.ct-head-col.ct-head-sticky {
			background: $neutral-lower;
		}

		@media #{$medium-up} {
			padding: ($gutter * 1.5) ($gutter * 2);
			font-size: rem-calc(14);
			line-height: rem-calc(14);
		}

		&:last-of-type {
			border-right: none;
		}

		&.sortable:not(.ord-desc):before {
			border-color: $neutral-medium-low transparent transparent;
		}

		&.sortable:not(.ord-asc):after {
			border-color: transparent transparent $neutral-medium-low;
		}
	}

	.ct-head-sticky,
	.ct-head-placeholder,
	.ct-head-shadow {
		top: 0;
	}
}

.ct-row:not(:last-of-type) {
	.ct-head-sticky a {
		color: $azure;
	}
}

.ct-cell {
	min-width: rem-calc(90);
	padding: rem-calc(15 10);

	@media #{$medium-up} {
		padding: ($gutter) ($gutter * 2);
	}
}

.ct-stickied {
	.ct-head,
	.ct-row:last-of-type,
	.ct-blue-row {
		.ct-head-placeholder > div::after {
			background-color: $neutral-lower;
		}
	}

	.ct-cell.ct-head-col {
		background: $neutral-lowest;
	}

	.ct-row:last-of-type {
		.ct-cell.ct-head-col {
			background: $neutral-lower;

			a {
				cursor: default;
				color: $neutral-medium-high;
			}
		}
	}
}

.ct-nowrap.ct-small-visible {
	white-space: normal;

	@media #{$medium-up} {
		white-space: nowrap;
	}
}

.ct-row.ct-small-expanded-clone .ct-cell {
	padding: 0;

	> span {
		padding: ($column-gutter / 2) ($column-gutter / 2) ($column-gutter / 2) 0;
	}

	> strong {
		padding: ($column-gutter / 2) 0 ($column-gutter / 2) ($column-gutter / 2);
	}
}
