// Animations: animated base mixin
@mixin animated($duration: 1s) {
  @include animation-duration($duration);
  @include animation-fill-mode(both); }

// Animation: bounceInDown
@include keyframes(bounceInDown) {
  0%, 60%, 75%, 90%, 100% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    @include transparent;
    @include translateY(-3000px); }
  60% {
    @include opaque;
    @include translateY(25px); }
  75% {
    @include translateY(-10px); }
  90% {
    @include translateY(5px); }
  100% {
    @include transform(none); } }

// Animation: fadeIn
@include keyframes(fadeIn) {
  0% {
    @include transparent; }
  100% {
    @include opaque; } }

// Animation: fadeInDown
@include keyframes(fadeInDown) {
  0% {
    @include transparent;
    @include translateY(-20px); }
  100% {
    @include opaque;
    @include transform(none); } }

// Animation: fadeInDownLong
@include keyframes(fadeInDownLong) {
  0% {
    @include transparent;
    @include translateY(-100%); }
  100% {
    @include opaque;
    @include transform(none); } }

// Animation: fadeInUp
@include keyframes(fadeInUp) {
  0% {
    @include transparent;
    @include translateY(20px); }
  100% {
    @include opaque;
    @include transform(none); } }

// Animation: fadeInLeft
@include keyframes(fadeInLeft) {
  0% {
    @include transparent;
    @include translateX(-20px); }
  100% {
    @include opaque;
    @include transform(none); } }

// Animation: fadeInRight
@include keyframes(fadeInRight) {
  0% {
    @include transparent;
    @include translateX(20px); }
  100% {
    @include opaque;
    @include transform(none); } }

// Animation: fadeOutUp
@include keyframes(fadeOutUp) {
  0% {
    @include opaque;
    @include translateY(0px); }
  100% {
    @include transparent;
    @include translateY(-20px); } }

// Animation: fadeOutRight
@include keyframes(fadeOutRight) {
  0% {
    @include opaque;
    @include translateX(0px); }
  100% {
    @include transparent;
    @include translateX(20px); } }

@include keyframes(fadeOutLeft) {
  0% {
    @include opaque;
    @include translateX(0px); }
  100% {
    @include transparent;
    @include translateX(-20px); } }

//flipcard effect
@include keyframes(flipIn) {
  0% {
    @include rotateX(45deg); }
  100% {
    @include rotate(0); } }
@include keyframes(flipOut) {
  0% {
    @include rotateX(-45deg); }
  100% {
    @include rotate(0); } }

// Animation: wrapperFadeIn
@include keyframes(wrapperFadeIn) {
  0% {
    @include transparent;
    @include transform(translateY(30px)); }
  100% {
    @include opaque;
    @include transform(none); } }


// Animation: rotate
@include keyframes(rotate) {
  0% {
    @include rotate(-360deg); }
  100% {
    @include rotate(0deg); } }

// Animation: accordion
@include keyframes(accordion) {
  0% {
    max-height: 0;
    overflow: hidden; }
  100% {
    max-height: 50vh;
    overflow: visible; } }
