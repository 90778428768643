.time-range {
	width: 200px;
}

.time-range-select {
	vertical-align: top;
	display: inline-block;
	margin-top: 6px;
	margin-left: 12px;
}

.filter-by-date {
	display: inline-block;
	margin-top: 6px;
	margin-left: 20px;
	width: 55%;
}
