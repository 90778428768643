.Login {
	$background-color: $neutral-lowest;
	$text-color: $neutral-lowest;
	$error-color: $red;
	$font-size: 16px;

	align-items: flex-start;
	background: $background-color;
	color: $text-color;
	display: flex;
	flex-direction: column;
	font-size: $font-size;
	left: 0;
	min-height: 100vh;
	padding-top: 0;
	position: absolute;
	top: 0;
	transition: $transition-speed $transition-ease padding-top;
	width: 100%;
	will-change: margin;

	@media #{$medium-up} {
		padding-top: rem-calc(150);

		&.has-to-confirm {
			padding-top: rem-calc(100);
		}
	}
	
	@media #{$medium-landscape-up} {
		padding-top: rem-calc(80);

		&.has-to-confirm {
			padding-top: rem-calc(30);
		}
	}

	@media #{$large-up} {
		padding-top: rem-calc(120);

		&.has-to-confirm {
			padding-top: rem-calc(70);
		}
	}

  &--nopaddingtop{
    padding-top: 0px;
  }

	&__container {
		flex-grow: 1;
		margin: 0 auto;
		padding: em-calc(30 0 20);
		position: relative;

    @media #{$medium-up} {
			width: em-calc(400);
		}
	}

	&__mainForm {
		button {
			font-family: $primary-font;
		}
	}

  &__elementContainer{
    width: 100%;
    max-width: rem-calc(400);
    margin: 0 auto;
  }

	&__logo {
		@include sprite-2x($az-login-logo, $az-login-logo-2x);
		margin: 0 auto ($column-gutter * 2);
	}

	#{&} {
		&__input {
      background-color: $white !important;
      box-shadow: none !important;
      flex: 1 0 100% !important;
      width: 100% !important;
      padding: ($column-gutter / 2) 0 !important;
      margin-bottom: ($column-gutter) !important;
      color: $primary-color !important;
      border: 1px solid $neutral-medium-low !important;
      border-top: 0px !important;
      border-left: 0px !important;
      border-right: 0px !important;

      &:focus {
        border: 1px solid $mood-good !important;
        border-top: 0px !important;
        border-left: 0px !important;
        border-right: 0px !important;
      }

      &::placeholder{
        color: $neutral-medium-high !important;
      }

      &.error {
        //margin-bottom: rem-calc(5);
        color: $mood-bad !important;
        border: 1px solid $mood-bad !important;
        border-top: 0px !important;
        border-left: 0px !important;
        border-right: 0px !important;
      }
		}
	}

  &__inconErr{
    margin-right: $gutter;
  }

	&__error {
		color: $error-color;
		font-size: rem-calc(14);
		will-change: transform;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: -15px;

		&.ng-enter {
			animation: fadeInDownLong $transition-speed $transition-ease;
		}
	}

  &__buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 60px;
  }

	&__cta {
    background-color: $primary-color !important;
    font-family: $primary-font;
    font-size: rem-calc(14);
    font-weight: 300;
    min-width: 140px;
    padding: ($gutter) ($gutter * 2.1);

    &:active {
      background-color: $primary-color;
      //box-shadow: $inner-shadow;
      box-shadow: none;
      background-image: none;
    }

    &:hover{
      background-color: $primary-color !important;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2)
    }

    &:disabled{
      background-color: $neutral-medium-high !important;
      color: $neutral-higher;
      opacity: 0.3;

      &:hover{
        color: $neutral-higher;
        box-shadow: none;
      }
    }
		&.is-disabled,
		&.is-disabled:hover {
			//background: $neutral-medium-high;
		}

		&.has-error,
		&.has-error:hover {
			background: $red;
		}

		&:focus {
			outline: 0;
		}
	}

  &__logout {
    background-color: transparent !important;
    color: $primary-color !important;
    background-image: none !important;
    font-family: $primary-font;
    font-size: rem-calc(14);
    font-weight: 300;
    min-width: 140px;
    padding: ($gutter) ($gutter * 2);
    margin-right: ($gutter * 2);

    &:active {
      background-color: transparent !important;
      box-shadow: none;
      background-image: none;
    }

    &:hover{
      background-color: rgba(0, 0, 0, 0.07) !important;
    }
  }

  &__extra {
		font-size: 0.9em;
    color: $neutral-higher;
	}

	&__content {
		padding: 0 rem-calc(20);
		width: 100%;

		@media #{$medium-up} {
			will-change: transform;

			&.ng-animate {
				position: absolute;
			}

			&.ng-leave {
				animation: fadeOutLeft $transition-speed $transition-ease;
			}

			&.ng-enter {
				animation: fadeInRight $transition-speed $transition-ease ($transition-speed * 1.5);
				opacity: 0;
			}
		}
	}

	&__title {
    max-width: 40rem;
    margin: ($gutter * 3) auto ($gutter * 5);
    text-align: center;
    color: $neutral-higher;

		&--step {
			//text-transform: uppercase;
		}
	}

  &__goBack{
    max-width: 40rem;
    margin: 0 auto;
  }

	&__msg {
		//color: mix($neutral-lowest, $background-color, 60%);
		color: $neutral-higher;
		margin-bottom: ($column-gutter / 2);

		&.ng-enter {
			animation: fadeInDownLong $transition-speed $transition-ease;
		}
	}

	&__disclaimerText {
		padding: ($column-gutter / 2);
		color: $neutral-higher;
    border: 1px solid $neutral-medium-low;
    border-radius: 4px;
    width: 100%;
    height: 325px;
    overflow: auto;
    font-size: rem-calc(14);
    font-family: $primary-font;
    margin-bottom: $column-gutter;

		@media #{$medium-up} {
			//width: 90vw;
			//max-width: rem-calc(900);
			//margin-left: 50%;
			//transform: translate(-50%);
		}

		@media #{$medium-landscape-up} {
			//background: $neutral-lowest;
			//color: $neutral-higher;
			//max-height: 50vh;
			overflow: auto;
			margin-bottom: $column-gutter;
		}
	}

  &__disclaimerTextTitle {
    font-size: rem-calc(16);
    font-weight: 500;

    &--sub {
      font-size: rem-calc(15);
      text-transform: uppercase;
    }
  }

	&__disclaimerTextTitleWrapper {
		display: flex;
		font-size: 1.8em;
		font-weight: 500;
		text-decoration: underline;
	}

	&__disclaimerTextTitleContentTitle {
		text-align: center;
		margin-bottom: 6px;
	}

	&__disclaimerTextTitleDescription {
		line-height: 30px;
	}

  &__disclaimerAccept {
    //padding: ($column-gutter / 2);
    color: $neutral-higher;
    //width: 90vw;
    //max-width: rem-calc(900);
    //margin-left: 50%;
    //transform: translate(-50%);
    width: 100%;
    //font-size: 0.7rem;
    font-size: rem-calc(14);
    margin-bottom: $gutter;

    input {
      margin-bottom: 0;
    }
  }

  &__promotionAcctept {
    //font-size: 0.9rem;
  }

  &__radioContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

	input[type='checkbox'] {
		margin-right: ($column-gutter / 2);
	}

	&__disclaimerRadio {
		/*color: $neutral-higher;
    font-size: rem-calc(16);
    font-weight: normal;

		input {
			margin-right: 1rem;
      border: 3px solid $primary-color;
		}*/
    display: block;
    position: relative;
    padding-left: $gutter * 3.5;
    margin-top: $gutter * 2;
    cursor: pointer;
    line-height: 19px;
    user-select: none;
    color: $neutral-higher;
    font-size: rem-calc(16);
    font-weight: normal;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;

      ~ span {
        border-color: $neutral-medium-high;

        &:hover {
          -webkit-box-shadow: 0px 0px $gutter*2 0px rgba(3, 58, 104, .1);
          -moz-box-shadow: 0px 0px $gutter*2 0px rgba(3, 58, 104, .1);
          box-shadow: 0px 0px $gutter*2 0px rgba(3, 58, 104, .1);
          background-color: rgba(3, 58, 104, .1);
        }
      }

      &:checked ~ span {
        background-color: $white;
        border-color: $primary-color;

        &:after {
          display: block;
        }
      }
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: $gutter * 2;
      width: $gutter * 2;
      background-color: $white;
      border: 2px solid;
      border-radius: 50%;
      box-sizing: border-box;

      &:after {
        content: "";
        position: absolute;
        display: none;
        width: $gutter;
        height: $gutter;
        border-radius: 50%;
        background: $primary-color;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &--disabled {
      pointer-events: none;
      opacity: 0.3;
      color: $neutral-medium-high;
    }
	}
}

.StepNav {
	width: 100%;

	&.ng-enter {
		animation: fadeInUp $transition-speed $transition-ease;
	}

	&__container {
		background: $neutral-high;
	}

	&__items {
		display: flex;
		justify-content: center;
	}

	&__item {
		background: $neutral-higher;
		border-radius: em-calc(10);
		cursor: pointer;
		display: block;
		height: em-calc(10);
		margin: ($column-gutter / 2);
		width: em-calc(10);

		&.is-active {
			background: $neutral-medium-high;
		}
	}
}

.widget-login {
	display: none;

	&:only-child {
		display: block;
	}
}
