$text-input-selectors: '[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea';
$text-input-selectors-focus: '[type="text"]:focus, input[type="password"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="month"]:focus, input[type="week"]:focus, input[type="email"]:focus, input[type="number"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="color"]:focus, textarea:focus';

// Typography
$base-sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$futura: join('Futura', $base-sans-serif);

/***** LEGACY *****/
$roboto: $futura;

// AZIMUT COLORs
// $primary: #385696;
$primary: #033a68;

// Theming
// $style-a: #385696
$style-a: #033a68;
$style-z: #ec8400;
$style-y: #533f33;

// Grayscale
$black: #000000;
$gray-darker: #3d3e44; 	// $neutral-higher
$gray-dark: #606972;		// $neutral-high
$gray: #888888; 			// $neutral-medium-high
$gray-light: #c3c3c3; 	// $neutral-medium-low
$gray-lighter: #dadada;	// $neutral-low
$almost-white: #f9f9f9; // $neutral-lower
$white: #ffffff;

// UI Color Palette
$green-light: #99dd8e;
$green: #21ce98;
$green-dark: #1c890b;
$azure: #57baec;
$blue: #146eb4;
$blue-dark: #0a416e;
$midnight-blue: #0a3250;
$orange: #ee9800;
$yellow: #ffd52b;
$red: #f00a6b;
$red-dark: #961300;
$purple: #6f6183;

// Shadows
$shadow-blue: #8793a2;
$shadow-brown: #a8a88c;
$shadow-red: #a88c8c;
$shadow-small: 0 1px 3px rgba($black, 0.2);
$shadow-medium: 0 2px 6px rgba($black, 0.2);
$shadow-large: 0 4px 8px rgba($black, 0.2);
$shadow-xlarge: 0 12px 17px rgba($black, 0.2);

// UI Colors
$primary-color: $primary;
$secondary-color: $gray-lighter;
$accent-color: $green;
$text-color: $gray-darker;
$divider-color: $secondary-color;
$divider-color-light: lighten($secondary-color, 5%);

// Alert boxes
$alert-info-color: $azure;
$alert-error-color: $red;
$alert-warning-color: $orange;
$alert-success-color: $green;

// FORMS
$form-element-height: rem-calc(40);

// SHADOW
$default-box-shadow: 1px 1px 5px rgba(black, 0.15);

// Media query

//SONO NEL FOUNDATION SETTINGS

// $medium-landscape-up: "only screen and (min-width:60.063em)"
// $retina: "only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)"

// CSS transitions
$transition-speed: 300ms;
$transition-ease: ease-out;
$default-transition-duration: $transition-speed;

/*  - - - - - - - -  */
/*                   */
/*      LEGACY       */
/*                   */
/*  - - - - - - - -  */

// TreeTop Color palette
$tt-blue: #47b2fc;
$tt-green-light: #99d02e;
$tt-green-medium: #418200;
$tt-green: $tt-green-medium;
$tt-green-dark: #2d6300;
$tt-brown-light: #4d3735;
$tt-brown: $tt-brown-light;
$tt-brown-dark: #3d2a28;
$tt-gray-light: #f8f7f4;
$tt-gray-medium: #f3f1e8;
$tt-gray: $tt-gray-medium;
$tt-gray-dark: #e8e6df;
$tt-gray-darker: #a39998;
$tt-blue-dark: #3172b1;
$tt-yellow: #ffd52b;
$tt-kaki: #695f40;
$tt-mustard: #ccb019;
$brown: $tt-brown;
$brown-dark: $tt-brown-dark;
$brown-light: #a8a28c;
$brown-almost-white: $tt-gray-light;
$kaki: $tt-kaki;
$mustard: $tt-mustard;
$fund-colors: ($tt-green-medium, $tt-brown, $tt-mustard, $tt-blue-dark);
$model-composition-colors: (#278ab2, #28b73a, #8bbd29, #bfc22b, #c7ac2c, #cd902d);
$chart-colors: ($tt-green-light, $tt-yellow, $tt-mustard, $tt-blue, $tt-blue-dark, $tt-kaki, $tt-green-dark, $tt-brown-dark, $tt-gray-darker, $tt-gray-dark);
$tt-tabs-bg: $tt-gray-dark;
$tt-tabs-bg-hover: darken($tt-gray-dark, 5%);
