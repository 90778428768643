/// Grid mixin with EQ
/// If you chose to use eqjs to handle your mediaqueries you can use this mixin instead of @media
@mixin mediaeq($query: small, $only: false) {
	//@if $include-element-query-classes or $include-legacy-query-classes
	//  @if &
	//    @if $only
	//      [data-eq-state="#{$query}"] &
	//        @content
	//    @else
	//      [data-eq-state$="#{$query}"] &
	//        @content
	//      @each $current-query-name in $dwx-breakpoint-name
	//        @if index($dwx-breakpoint-name, $query) < index($dwx-breakpoint-name, $current-query-name)
	//          @at-root
	//            [data-eq-state$="#{$current-query-name}"]
	//              @extend [data-eq-state$="#{$query}"]
	//@else
	$current-query: nth($dwx-breakpoint-queries, index($dwx-breakpoint-name, $query));

	@media #{$current-query} {
		@content;
	}
}

@mixin eq-pts($states) {
	&:before {
		display: none;
		content: '#{str-slice(inspect($states), 1, -1)}';
	}
}

/// Clearfix
/// Uses the micro clearfix hack popularized by Nicolas Gallagher. Include this mixin on a container if its children are all floated, to give the container a proper height.
///
/// @see http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix {

	&:before,
	&:after {
		content: ' ';
		display: table;
	}

	&:after {
		clear: both;
	}
}

/// Invisible Element
/// Makes an element visually hidden, but accessible.
///
/// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}

/// Invisible Element Off
/// Reverses the CSS output by the `element-invisible()` mixin.
@mixin element-invisible-off {
	position: static !important;
	height: auto;
	width: auto;
	overflow: visible;
	clip: auto;
}

@mixin font-smoothing($antialiased: true) {
	@if $antialiased {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	@else if $antialiased==false {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}

@mixin square($size: 100%) {
	width: $size;
	height: $size;
}

@mixin rounded($size: false) {
	@if $size {
		@include square($size);
	}

	border-radius: $global-rounded;
}

@mixin no-bullet {
	list-style-type: none;
	margin-left: 0;
}

@mixin show-for-medium-up($display: block) {
	display: none;

	@media #{$medium-up} {
		display: $display;
	}
}

@mixin show-for-large-up($display: block) {
	display: none;

	@media #{$large-up} {
		display: $display;
	}
}

@mixin hide-for-medium-up($display: block) {
	display: $display;

	@media #{$medium-up} {
		display: none;
	}
}

@mixin hide-for-large-up($display: block) {
	display: $display;

	@media #{$large-up} {
		display: none;
	}
}

@mixin panel($bg: white, $radius: true, $margin-bottom: $column-gutter, $padding: $column-gutter, $shadow: false) {
	display: block;
	margin-bottom: $margin-bottom;
	padding: $padding;
	border-width: 1px;
	border-style: solid;
	@include panel-color($bg);

	@if $radius==true {
		border-radius: $global-radius;
	}

	@else if $radius {
		border-radius: $radius;
	}

	@if $shadow==true {
		@include panel-shadow;
	}

	@else if $shadow {
		@include panel-shadow($shadow);
	}
}

@mixin panel-shadow($shadow: $default-box-shadow) {
	border: none;
	box-shadow: $shadow;
}

@mixin panel-color($bg: white, $border: true) {
	background-color: $bg;

	@if $border==false {
		border: none;
	}

	@else if $border==true {
		border-color: darken($bg, 10%);
	}

	@else if $border {
		border-color: $border;
	}
}

@mixin tabs {
	@include no-bullet;
	@include clearfix;
	overflow: hidden;
	margin: 0;
}

@mixin tab-title($bg: rgba($black, 0.05),
	$bg-hover: rgba($black, 0.15),
	$bg-active: $neutral-lowest,
	$color: $black,
	$radius: $global-radius) {

	float: left;
	margin-right: $column-gutter / 2;

	&:last-child {
		margin-right: 0;
	}

	a {
		display: block;
		background-color: $bg;
		color: $color;
		font-size: rem-calc(13);
		padding: rem-calc(15 20);
		text-transform: uppercase;
		border-top-right-radius: $radius;
		border-top-left-radius: $radius;
		transition: background-color $transition-ease $transition-speed;

		&:hover {
			background-color: $bg-hover;
		}
	}

	&.active {
		a {
			background-color: $bg-active;
		}
	}
}

@mixin tabs-content {
	padding: $column-gutter;
	background-color: white;
	border-bottom-radius: $global-radius;
	border-top-right-radius: $global-radius;
}