// UTILITY CLASSES
.touch {
	a,
	button,
	.button,
	.f-dropdown li {
		transition: none;
	}
}

.no-touch {
	a {
		@include single-transition(color);
	}

	button,
	.button {
		transition: color $transition-speed $transition-ease, background-color $transition-speed $transition-ease;
	}

	.f-dropdown li {
		@include single-transition(background-color);
	}
}

.small-link,
.small-text {
	font-size: rem-calc(12);

	@media #{$medium-up} {
		font-size: rem-calc(14);
	}
}

p {
	font-size: rem-calc(14);
}

#{$text-input-selectors-focus} {
	background-color: $neutral-lowest;
	box-shadow: none;
}

.uppercase {
	text-transform: uppercase;
}

.text-bold {
	font-weight: bold;
}

.text-medium {
	font-weight: 500;
}

.normal-weight {
	font-weight: normal;
}

$properties: margin padding;
$directions: top right bottom left;

@each $property in $properties {
	.no-#{$property} {
		#{$property}: 0;
	}

	.#{$property}-5 {
		#{$property}: rem-calc(5);
	}

	@each $direction in $directions {
		.no-#{$property}-#{$direction} {
			#{$property}-#{$direction}: 0;
		}

		.#{$property}-#{$direction}-5 {
			#{$property}-#{$direction}: rem-calc(5);
		}
	}

	@for $i from 1 through 10 {
		$value: $i * 10;

		.#{$property}-#{$value} {
			#{$property}: rem-calc($value);
		}
		
		@each $direction in $directions {
			.#{$property}-#{$direction}-#{$value} {
				#{$property}-#{$direction}: rem-calc($value);
			}
		}
	}
}

.no-border {
	border: none;
}
